import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

interface ReportInterface {
  rowsCount: number;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    padding: 0,
    margin: 0,
    fontFamily: 'Inter',
  },
  description: {
    height: '100%',
    width: '40%',
    textAlign: 'left',
    paddingLeft: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  std: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ext: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function TableBlankSpec({ rowsCount }: ReportInterface) {
  const blankRows = Array(rowsCount).fill(0);
  return (
    <Fragment>
      {blankRows.map((item, index) => (
        <View
          style={styles.row}
          key={`br${index}`}>
          <View style={styles.description} />
          <View style={styles.std} />
          <View style={styles.ext} />
          <View style={styles.amount} />
        </View>
      ))}
    </Fragment>
  );
}
