import { Card, Row, Col, Form, Space, Button, Badge, Typography, Input, Radio, Pagination, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { PromotionService } from '../../services/promotionService';
import { HttpStatus } from '../../constant/responseStatus';
import { useEffect, useState } from 'react';
import { RouteNames } from '../../components/Route/routeName';
import { PromotionListModel, PromotionSearchModel } from '../../models/promotionModel';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { PromotionType } from '../../constant/typeConstants';

const { Text } = Typography;

export default function Promotion() {
    const [form] = Form.useForm();
    const [promotions, setPromotions] = useState<PromotionListModel[]>([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);

    const options = [
        { label: 'All', value: '' },
        { label: 'Effective', value: 'true' },
        { label: 'Ineffective', value: 'false' },
    ];

    const typeOptions = [
        { label: 'All', value: '' },
        { label: 'Percentage', value: 'PercentagePromotion' },
        { label: 'Fixed', value: 'FixedPromotion' },
    ];

    const columns: ColumnsType<PromotionListModel> = [
        {
            title: 'Discount Code',
            dataIndex: 'discountCode',
            align: 'center',
            render: (text) => <Text code>{text}</Text>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: 'center',
            width: 200,
            render: (text) => <p className='text-left'>{text}</p>,
        },
        {
            title: 'Discount Type',
            dataIndex: 'discountTarget',
            align: 'center',
            render: (value, record, index) =>
                record.discountTarget === 'PercentagePromotion' ? PromotionType.Percentage : PromotionType.Fixed,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            align: 'center',
            render: (value, record, index) => <>{moment(record.startDate).format(DateFormat.DateTime)}</>,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            align: 'center',
            render: (value, record, index) => (
                <>{record.endDate ? (moment(record.endDate).format(DateFormat.DateTime)) : (<>-</>)}</>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'center',
            render: (text) => <p className='text-right'>{text}</p>,
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            align: 'center',
            render: (value, record, index) =>
                record.isActive ? (
                    <Badge status='processing' text='Active' />
                ) : (
                    <Badge status='default' text='Inactive' />
                ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (value, record, index) => <Link to={`${RouteNames.promotionDetail}?id=${record.id}`}>Detail</Link>,
        },
    ];

    useEffect(() => {
        onGetListPromotion(page, size);
    }, []);

    const onGetListPromotion = async (page: number, size: number) => {
        const request: PromotionSearchModel = {
            discountCode: form.getFieldValue('discountCode'),
            promotionType: form.getFieldValue('promotionType') !== '' ? form.getFieldValue('promotionType') : undefined,
            IsEffectiveDate: form.getFieldValue('effective') !== '' ? form.getFieldValue('effective') : undefined,
        };

        const { data, status } = await new PromotionService().getList(page, size, request);

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.totalRows)
        setPromotions(data.list);
    };

    const onFinish = () => {
        onGetListPromotion(page, size);
    };

    const onReset = () => {
        form.resetFields();
        onGetListPromotion(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListPromotion(page, size);
    };

    return (
        <>
            <Card title='Promotion' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row>
                            <Col span={6} offset={5}>
                                <Form.Item
                                    name='discountCode'
                                    className=' text-light w-100'
                                    label='Discount Code'
                                    rules={[{ required: false }]}
                                >
                                    <Input size='large' placeholder='Discount Code' />
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={2}>
                                <Form.Item
                                    name='promotionType'
                                    className=' text-light w-100'
                                    label='Promotion Type'
                                    rules={[{ required: false }]}
                                    initialValue={''}
                                >
                                    <Radio.Group
                                        options={typeOptions}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} offset={5}>
                                <Form.Item name='effective' label='Effective Status' className='text-light'
                                initialValue={''}>
                                    <Radio.Group
                                        options={options}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row className='mt-3'>
                    <Col>
                        <Button type='primary' onClick={() => navigate(RouteNames.promotionDetail)}>
                            New Promotion
                        </Button>
                    </Col>
                </Row>
                <Table
                    scroll={{x: true}}
                    pagination={false}
                    className='mt-4'
                    columns={columns}
                    dataSource={promotions} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
