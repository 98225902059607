
import { Switch, Form } from 'antd';
import { useState } from 'react';

interface Props {
  name?: string;
  label?: string;
}

function SwitchForm({ name, label }: Props) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Form.Item label={label} name={name}>
        <Switch checked={isChecked} onChange={setIsChecked} />
      </Form.Item>
    </>
  );
}

export default SwitchForm;