import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { 
  PromotionFixedCreatingModel, 
  PromotionPercentageCreatingModel,
  PromotionSearchModel } from '../models/promotionModel';

export class PromotionService {
  private api = 'admin/promotion';

  getList(page: number, size: number, request: PromotionSearchModel): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'DiscountCode': request.discountCode,
        'PromotionType': request.promotionType,
        'EffectiveDate': request.IsEffectiveDate,
      }
    return Axios.get(`${this.api}`, { params });
  }

  getId(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/${id}`);
  }

  createPromotionPercentage(request: PromotionPercentageCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}/percentage`, JSON.stringify(request));
  }

  createPromotionFix(request: PromotionFixedCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}/fixed`, JSON.stringify(request));
  }

  updatePromotionPercentage(id: string, request: PromotionPercentageCreatingModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/percentage/${id}`, JSON.stringify(request));
  }

  updatePromotionFix(id: string, request: PromotionFixedCreatingModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/fixed/${id}`, JSON.stringify(request));
  }
}