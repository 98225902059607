
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';

interface Props {
  name?: string;
  label?: string;
  options: Array<DefaultOptionType>;
  mode?: 'multiple' | 'tags';
  rules?: Rule[];
}

function SelectForm({ name, label, options, mode, rules }: Props) {

  const getValue = useMemo(() => {
    if (options.length) {
      return mode ? null : (options[0] as DefaultOptionType).value;
    }

    return null;
  }, []);

  return (
    <>
      <Form.Item label={label} initialValue={getValue} name={name} rules={rules}>
        <Select options={options} mode={mode} />
      </Form.Item>
    </>
  );
};
export default SelectForm;