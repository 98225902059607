import {
  Card,
  Typography,
  Space,
  Row,
  Col,
  Image,
  Form,
  Divider,
  Drawer,
  Empty,
  notification,
  Checkbox,
  Modal,
} from 'antd';
import { Status } from '../../components/DataDisplay/Table';
import { VscCircleFilled } from 'react-icons/vsc';
import Button from '../../components/General/Button';
import RadioEvent from '../../components/Forms/RadioEvent';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import Select from '../../components/Forms/Select';
import Switch from '../../components/Forms/Switch';
import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AssetDetailModel, RejectAssetModel } from '../../models/assetModel';
import { AssetService } from '../../services/assetService';
import { HttpStatus } from '../../constant/responseStatus';
import { AssetComposition, AssetOrientation, AssetStatus, AssetType, AssetUsage } from '../../constant/assetConstants';
import { MasterDataType } from '../../constant/masterDataConstants';
import { MasterDataService } from '../../services/masterDataService';
import IMasterDataModel from '../../models/request/masterDataModel';
import ReleaseList from './ReleaseList';
import { NofificationPlacement, NotificationType } from '../../constant/notificationConstants';
import { Constants } from '../../constant/constants';
import HoverVideoPlayer from 'react-hover-video-player';

const { Paragraph } = Typography;

const usageOptions = [
  { label: AssetUsage.COMERCIAL, value: AssetUsage.COMERCIAL },
  { label: AssetUsage.EDITORIAL, value: AssetUsage.EDITORIAL },
  { label: 'AI Generated', value: AssetUsage.AIGENERATED },
];

const orientationOptions = [
  { label: AssetOrientation.HORIZONTAL, value: AssetOrientation.HORIZONTAL },
  { label: AssetOrientation.VERTICAL, value: AssetOrientation.VERTICAL },
];

const compositionOptions = [
  { label: AssetComposition.INDOOR, value: AssetComposition.INDOOR },
  { label: AssetComposition.OUTDOOR, value: AssetComposition.OUTDOOR },
];

const enum RejectFormFileds {
  PROPERTY = 'property',
  APPROPRIATENESS = 'appropriateness',
  QUALITY = 'quality',
  MESSAGE = 'message',
}

function AssetReview() {
  const [searchParams] = useSearchParams();
  const [assetDetail, setassetDetail] = useState<AssetDetailModel>();
  const [keywordOptions, setKeywordOptions] = useState<{ label: string; value: string }[]>([]);
  const [cameraTypeOptions, setCameraTypeOptions] = useState<{ label: string; value: string }[]>([]);
  const [cameraBrandOptions, setCameraBrandOptions] = useState<{ label: string; value: string }[]>([]);
  const [rejectReasonPropertyOptions, setRejectReasonPropertyOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [rejectReasonAppropriatenessOptions, setRejectReasonAppropriatenessOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [rejectReasonQualityOptions, setRejectReasonQualityOptions] = useState<{ label: string; value: string }[]>([]);
  const [form] = Form.useForm();
  const [rejectForm] = Form.useForm();
  const [openRejectDrawer, setOpenRejectDrawer] = useState(false);
  const [onGenerateKeywords, setOnGenerateKeywords] = useState(false);

  const enum FormFileds {
    USAGE = 'usage',
    ORIENTATION = 'orientation',
    COMPOSITION = 'composition',
    ASSET_NAME = 'assetName',
    DESCRIPTION = 'description',
    KEYWORDS = 'keywords',
    CAMERA_TYPE = 'cameraType',
    CAMERA_BRAND = 'cameraBrand',
    IS_MATURE_CONTENT = 'isMatureContent',
  }

  const { confirm } = Modal;

  useEffect(() => {
    const assetId = searchParams.get('id');

    if (assetId) {
      getAssetDetailAsync(assetId);
    }
  }, [searchParams]);

  useEffect(() => {
    if (keywordOptions.length > 0) {
      return;
    }

    getAssetKeywordAsync();
  }, [keywordOptions]);

  useEffect(() => {
    if (cameraTypeOptions.length > 0) {
      return;
    }

    getCameraTypeAsync();
  }, [cameraTypeOptions]);

  useEffect(() => {
    if (cameraBrandOptions.length > 0) {
      return;
    }

    getCameraBrandAsync();
  }, [cameraBrandOptions]);

  useEffect(() => {
    if (rejectReasonPropertyOptions.length > 0) {
      return;
    }

    getRejectReasonPropertyAsync();
  }, [rejectReasonPropertyOptions]);

  useEffect(() => {
    if (rejectReasonAppropriatenessOptions.length > 0) {
      return;
    }

    getRejectReasonAppropriatenessAsync();
  }, [rejectReasonAppropriatenessOptions]);

  useEffect(() => {
    if (rejectReasonQualityOptions.length > 0) {
      return;
    }

    getRejectReasonQualityAsync();
  }, [rejectReasonQualityOptions]);

  const getMasterDataAsync = async (type: MasterDataType) => {
    const { data, status } = await new MasterDataService().get(type);

    if (status !== HttpStatus.OK) {
      return;
    }

    const result = data.map((md: IMasterDataModel) => ({
      label: md.name,
      value: md.name,
    }));

    return result;
  };

  const getAssetKeywordAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.KEYWORD);

    if (values) {
      setKeywordOptions(values);
    }
  };

  const getCameraTypeAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.CAMERA_TYPE);

    if (values) {
      setCameraTypeOptions(values);
    }
  };

  const getCameraBrandAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.CAMERA_BRAND);

    if (values) {
      setCameraBrandOptions(values);
    }
  };

  const getRejectReasonPropertyAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.AssetRejectProperty);

    if (values) {
      setRejectReasonPropertyOptions(values);
    }
  };

  const getRejectReasonAppropriatenessAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.AssetRejectAppropriateness);

    if (values) {
      setRejectReasonAppropriatenessOptions(values);
    }
  };

  const getRejectReasonQualityAsync = async () => {
    const values = await getMasterDataAsync(MasterDataType.AssetRejectQuality);

    if (values) {
      setRejectReasonQualityOptions(values);
    }
  };

  useEffect(() => {
    form.setFieldValue(FormFileds.USAGE, assetDetail?.usage ?? AssetUsage.COMERCIAL);
    form.setFieldValue(FormFileds.ORIENTATION, assetDetail?.photoInfo.orientation);
    form.setFieldValue(FormFileds.COMPOSITION, assetDetail?.photoInfo.composition ?? AssetComposition.INDOOR);
    form.setFieldValue(FormFileds.ASSET_NAME, assetDetail?.name);
    form.setFieldValue(FormFileds.DESCRIPTION, assetDetail?.description);
    form.setFieldValue(
      FormFileds.KEYWORDS,
      assetDetail?.keywords ?? assetDetail?.keywords.map((k) => ({ label: k, value: k }))
    );

    form.setFieldValue(FormFileds.CAMERA_TYPE, assetDetail?.cameraInfo?.type);
    form.setFieldValue(FormFileds.CAMERA_BRAND, assetDetail?.cameraInfo?.brand);
    form.setFieldValue(FormFileds.IS_MATURE_CONTENT, assetDetail?.isMatureContent);
  }, [assetDetail]);

  const initRejectFormValue = () => {
    setOpenRejectDrawer(true);

    rejectForm.setFieldValue(RejectFormFileds.MESSAGE, assetDetail?.rejectReason?.message);
    rejectForm.setFieldValue(
      RejectFormFileds.PROPERTY,
      assetDetail?.rejectReason?.items.filter((item) =>
        rejectReasonPropertyOptions.find((option) => option.label == item)
      )
    );
    rejectForm.setFieldValue(
      RejectFormFileds.APPROPRIATENESS,
      assetDetail?.rejectReason?.items.filter((item) =>
        rejectReasonAppropriatenessOptions.find((option) => option.label == item)
      )
    );
    rejectForm.setFieldValue(
      RejectFormFileds.QUALITY,
      assetDetail?.rejectReason?.items.filter((item) =>
        rejectReasonQualityOptions.find((option) => option.label == item)
      )
    );
  };

  const getAssetDetailAsync = async (id: string) => {
    const { data, status } = await new AssetService().getAssetDetailAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setassetDetail(data);
  };

  const handleDownload = async () => {
    const url = assetDetail?.originalFileUrl!;
    const filename = url.replace(/^.*[\\\/]/, '');

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
  };

  const validateMessages = {
    required: '${label} is required!',
  };

  const alertError = (message: string = 'Save fail!') => {
    notification.open({
      message: message,
      type: NotificationType.ERROR,
      placement: NofificationPlacement.TOP,
    });
  };

  const alertSuccess = (message: string = 'Save sucessfully!') => {
    notification.open({
      message: message,
      type: NotificationType.SUCCESS,
      placement: NofificationPlacement.TOP,
    });
  };

  const onSave = () => {
    form.submit();

    form
      .validateFields()
      .then(async (value) => {
        let updateModel = assetDetail!;

        updateModel.usage = value[FormFileds.USAGE];
        updateModel.photoInfo.orientation = value[FormFileds.ORIENTATION];
        updateModel.photoInfo.composition = value[FormFileds.COMPOSITION];
        updateModel.name = value[FormFileds.ASSET_NAME];
        updateModel.description = value[FormFileds.DESCRIPTION];
        updateModel.keywords = value[FormFileds.KEYWORDS];
        updateModel.cameraInfo.type = value[FormFileds.CAMERA_TYPE];
        updateModel.cameraInfo.brand = value[FormFileds.CAMERA_BRAND];
        updateModel.isMatureContent = value[FormFileds.IS_MATURE_CONTENT];

        const { status } = await new AssetService().update(updateModel);

        if (status !== HttpStatus.OK) {
          alertError();
          return;
        }

        alertSuccess();
        getAssetDetailAsync(assetDetail?.id!);
      })
      .catch((e) => {});
  };

  const onApprove = () => {
    confirm({
      title: `Do you want to approve?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        return new Promise((resolve) => {
          form.submit();

          form
            .validateFields()
            .then(async (value) => {
              let updateModel = assetDetail!;

              updateModel.usage = value[FormFileds.USAGE];
              updateModel.photoInfo.orientation = value[FormFileds.ORIENTATION];
              updateModel.photoInfo.composition = value[FormFileds.COMPOSITION];
              updateModel.name = value[FormFileds.ASSET_NAME];
              updateModel.description = value[FormFileds.DESCRIPTION];
              updateModel.keywords = value[FormFileds.KEYWORDS];
              updateModel.cameraInfo.type = value[FormFileds.CAMERA_TYPE];
              updateModel.cameraInfo.brand = value[FormFileds.CAMERA_BRAND];
              updateModel.isMatureContent = value[FormFileds.IS_MATURE_CONTENT];

              const { status } = await new AssetService().approve(updateModel);

              if (status !== HttpStatus.OK) {
                setTimeout(resolve, 100);
                alertError();
                return;
              }

              alertSuccess();
              getAssetDetailAsync(assetDetail?.id!);
              setTimeout(resolve, 100);
            })
            .catch((e) => {
              setTimeout(resolve, 100);
            });
        });
      },
    });
  };

  const onReject = async () => {
    confirm({
      title: `Do you want to reject?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        return new Promise((resolve) => {
          rejectForm
            .validateFields()
            .then(async (value) => {
              const rejectModel: RejectAssetModel = {
                assetId: assetDetail?.id!,
                rejectMessage: value[RejectFormFileds.MESSAGE],
                rejectItems: [].concat(
                  value[RejectFormFileds.PROPERTY],
                  value[RejectFormFileds.APPROPRIATENESS],
                  value[RejectFormFileds.QUALITY]
                ),
              };

              const { status } = await new AssetService().reject(rejectModel);

              if (status !== HttpStatus.OK) {
                setTimeout(resolve, 100);
                alertError();
                return;
              }

              alertSuccess();
              setOpenRejectDrawer(false);
              getAssetDetailAsync(assetDetail?.id!);
              setTimeout(resolve, 100);
            })
            .catch((e) => {
              setTimeout(resolve, 100);
            });
        });
      },
    });
  };

  const onDisable = async () => {
    confirm({
      title: `Do you want to disable?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new AssetService().disable(assetDetail?.id!);

        if (status !== HttpStatus.OK) {
          alertError();
          return;
        }

        alertSuccess();
        getAssetDetailAsync(assetDetail?.id!);
      },
    });
  };

  const onEnable = async () => {
    confirm({
      title: `Do you want to enable?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new AssetService().enable(assetDetail?.id!);

        if (status !== HttpStatus.OK) {
          alertError();
          return;
        }

        alertSuccess();
        getAssetDetailAsync(assetDetail?.id!);
      },
    });
  };

  const onAbandon = async () => {
    confirm({
      title: `Do you want to abandon?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new AssetService().abandon(assetDetail?.id!);

        if (status !== HttpStatus.OK) {
          alertError();
          return;
        }

        alertSuccess();
        getAssetDetailAsync(assetDetail?.id!);
      },
    });
  };

  const onChangeReason = (event: any) => {
    const checked = event.target.checked;
    const checkedValue = event.target.value;
    const rejectMessage = rejectForm.getFieldValue(RejectFormFileds.MESSAGE)
      ? rejectForm.getFieldValue(RejectFormFileds.MESSAGE).trim()
      : Constants.stringEmpty;

    let messageValue = checked
      ? rejectMessage === Constants.stringEmpty
        ? checkedValue
        : rejectMessage + ', ' + checkedValue
      : rejectMessage.startsWith(',')
      ? rejectMessage.replace(', ' + checkedValue, Constants.stringEmpty).replace(checkedValue, Constants.stringEmpty)
      : rejectMessage.replace(checkedValue, Constants.stringEmpty);

    messageValue =
      messageValue === ',' ? messageValue.replace(',', Constants.stringEmpty) : messageValue.replace(', ,', ',').trim();

    rejectForm.setFieldValue(RejectFormFileds.MESSAGE, messageValue);
  };

  const generateImageKeywords = async () => {
    setOnGenerateKeywords(true);

    let imageUrl =
      assetDetail?.assetType == AssetType.VIDEO
        ? assetDetail?.thumbnailImageInfo.vdoCoverUrl
        : assetDetail?.thumbnailImageInfo.imageUrl;

    const { data, status } = await new AssetService().generateImageKeywords(imageUrl!);

    if (status !== HttpStatus.OK) {
      setOnGenerateKeywords(false);
      alertError('Generate keywords fail.');

      return;
    }

    const oldKeywordValue = [...form.getFieldValue(FormFileds.KEYWORDS).map((k: string) => k)];
    const keywordResult = data.map((k) => k);
    const mergeKeywords = oldKeywordValue.concat(keywordResult.filter((k) => !oldKeywordValue.find((f) => f == k)));

    if (mergeKeywords?.length > 0) {
      form.setFieldValue(FormFileds.KEYWORDS, mergeKeywords);
      form.setFields([
        {
          name: FormFileds.KEYWORDS,
          errors: undefined,
        },
      ]);
    }

    alertSuccess('Generate keywords success.');
    setOnGenerateKeywords(false);
  };

  const submitedDate = new Date(assetDetail?.submitDate!).toLocaleString('th-TH');

  return (
    <>
      <Card
        className='card-customize'
        title={
          <Space align='center'>
            <Typography.Title
              level={5}
              className='mb-0'>
              {' '}
              {assetDetail?.code}{' '}
            </Typography.Title>
            <Paragraph className='mb-0 text-dark-50'>
              by {assetDetail?.artistName} on {submitedDate}
            </Paragraph>
          </Space>
        }
        extra={
          <Space align='center'>
            <Paragraph className='mb-0 me-2'>Status :</Paragraph>
            <VscCircleFilled className={`${Status(assetDetail?.status!)}`} />
            <Paragraph className='mb-0 ms-1 me-3'>{assetDetail?.status!}</Paragraph>
            {assetDetail?.status == AssetStatus.PENDING && (
              <>
                <Button
                  text='Reject'
                  type='primary'
                  danger={true}
                  onClick={initRejectFormValue}
                />
                <Button
                  text='Save'
                  onClick={onSave}
                />
                <Button
                  text='Approve'
                  type='primary'
                  onClick={onApprove}
                />
              </>
            )}

            {assetDetail?.status == AssetStatus.COMPLETED && (
              <>
                <Button
                  text='Abandon'
                  type='primary'
                  danger={true}
                  onClick={onAbandon}
                />
                {!assetDetail.isDisabled ? (
                  <Button
                    text='Disable'
                    type='default'
                    danger={true}
                    onClick={onDisable}
                  />
                ) : (
                  <Button
                    text='Enable'
                    type='default'
                    danger={true}
                    onClick={onEnable}
                  />
                )}
                <Button
                  text='Save'
                  type='primary'
                  onClick={onSave}
                />
              </>
            )}
            {assetDetail?.status == AssetStatus.REJECTED && (
              <>
                <Button
                  type='primary'
                  text='Reject Reason'
                  danger={true}
                  onClick={initRejectFormValue}
                />
              </>
            )}
          </Space>
        }>
        <Drawer
          title='Reject Reason'
          placement='right'
          onClose={() => setOpenRejectDrawer(false)}
          open={openRejectDrawer}
          extra={
            assetDetail?.status != AssetStatus.REJECTED && (
              <Space>
                <Button
                  type='primary'
                  text='Submit Reject'
                  onClick={onReject}
                  danger={true}
                />
              </Space>
            )
          }>
          <Form
            form={rejectForm}
            disabled={assetDetail?.status == AssetStatus.COMPLETED || assetDetail?.status == AssetStatus.REJECTED}
            onFinish={onReject}
            layout='vertical'
            autoComplete='off'>
            <Form.Item name={RejectFormFileds.PROPERTY}>
              <Checkbox.Group>
                <Row
                  gutter={[10, 10]}
                  className='mb-3'>
                  {rejectReasonPropertyOptions.map((item) => (
                    <Col
                      key={`col-reject-property-${item.value}`}
                      span={24}>
                      <Checkbox
                        key={`reject-property-${item.value}`}
                        onChange={onChangeReason}
                        value={item.label}>
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Divider
              orientation='left'
              plain>
              Appropriateness
            </Divider>
            <Form.Item name={RejectFormFileds.APPROPRIATENESS}>
              <Checkbox.Group>
                <Row
                  gutter={[10, 10]}
                  className='mb-3'>
                  {rejectReasonAppropriatenessOptions.map((item) => (
                    <Col
                      key={`colreject-appropriateness-${item.value}`}
                      span={24}>
                      <Checkbox
                        key={`reject-appropriateness-${item.value}`}
                        onChange={onChangeReason}
                        value={item.label}>
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Divider
              orientation='left'
              plain>
              Asset Quality
            </Divider>
            <Form.Item name={RejectFormFileds.QUALITY}>
              <Checkbox.Group>
                <Row
                  gutter={[10, 10]}
                  className='mb-3'>
                  {rejectReasonQualityOptions.map((item) => (
                    <Col
                      key={`col-reject-quality-${item.value}`}
                      span={24}>
                      <Checkbox
                        key={`reject-quality-${item.value}`}
                        onChange={onChangeReason}
                        value={item.label}>
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Row
              gutter={[10, 10]}
              className='mb-5'>
              <Col span={24}>
                <TextArea
                  placeholder='Autosize height based on content lines'
                  name={RejectFormFileds.MESSAGE}
                  label='Reject Reason Message'
                  rules={[{ required: true, message: 'Please input your message!' }]}
                />
              </Col>
            </Row>
          </Form>
        </Drawer>

        <div className='p-4'>
          <Row>
            <Col
              flex='0 1 300px'
              className='px-2'>
              <div className='box-img-auto-height'>
                {assetDetail?.assetType == AssetType.VIDEO ? (
                  <HoverVideoPlayer
                    videoSrc={assetDetail?.thumbnailImageInfo.vdoUrl}
                    restartOnPaused
                    overlayTransitionDuration={500}
                    videoStyle={{
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                    pausedOverlay={
                      <img
                        src={assetDetail?.thumbnailImageInfo.vdoCoverUrl}
                        style={{ objectPosition: 'center' }}
                        className='gallery-img img-hover w-100'
                        alt={assetDetail?.fileName}
                      />
                    }
                    loadingOverlay={<div className='loading-overlay'>Loading...</div>}
                  />
                ) : (
                  <Image
                    className='w-100'
                    src={assetDetail?.originalFileUrl}
                  />
                )}
              </div>
              <div className='text-center'>
                <Button
                  onClick={() => window.open(assetDetail?.originalFileUrl, '_blank', 'noreferrer')}
                  className='mt-2'
                  text='Download Original File'
                />
              </div>
            </Col>
            <Col
              flex='1 1 200px'
              className='px-3'>
              <Form
                form={form}
                disabled={assetDetail?.status == AssetStatus.REJECTED}
                layout='vertical'
                autoComplete='off'
                validateMessages={validateMessages}>
                <RadioEvent
                  name={FormFileds.USAGE}
                  label='Usage'
                  options={usageOptions}
                />
                <RadioEvent
                  name={FormFileds.ORIENTATION}
                  label='Orientation'
                  options={orientationOptions}
                />
                <RadioEvent
                  name={FormFileds.COMPOSITION}
                  label='Composition'
                  options={compositionOptions}
                />
                <Row>
                  <Col span={8}>
                    <Input
                      placeholder='Asset Name'
                      name={FormFileds.ASSET_NAME}
                      label='Asset Name'
                      rules={[{ required: false }]}
                    />
                  </Col>
                  <Col span={16} />
                  <Col span={8}>
                    <TextArea
                      placeholder='Description'
                      name={FormFileds.DESCRIPTION}
                      label='Description'
                      rules={[{ required: true, message: 'Please input your message!' }]}
                    />
                  </Col>
                </Row>

                <Row className='flex-center'>
                  <Col span={8}>
                    <Select
                      name={FormFileds.KEYWORDS}
                      label='Keywords'
                      mode='tags'
                      rules={[{ required: true, message: 'Please input your keywords!' }]}
                      options={keywordOptions}
                    />
                  </Col>
                  <Col span={1}>
                    <Paragraph className='mb-0 text-center'> or </Paragraph>
                  </Col>
                  <Col span={10}>
                    <Form.Item label='Generate keywords with Google vision'>
                      <Button
                        onClick={generateImageKeywords}
                        htmlType='button'
                        loading={onGenerateKeywords}
                        text='Generate Keywords'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Select
                      name={FormFileds.CAMERA_TYPE}
                      label='Camera Type'
                      options={cameraTypeOptions}
                    />
                  </Col>
                  <Col span={16} />
                  <Col span={8}>
                    <Select
                      name={FormFileds.CAMERA_BRAND}
                      label='Camera Brand'
                      options={cameraBrandOptions}
                    />
                  </Col>
                  <Col span={16} />
                  <Col span={8}>
                    <Switch
                      name={FormFileds.IS_MATURE_CONTENT}
                      label='Mature Content'
                    />
                  </Col>
                </Row>
                <Divider
                  orientation='left'
                  plain>
                  Releases
                </Divider>
                {assetDetail?.releaseItems && assetDetail?.releaseItems.length > 0 ? (
                  <Row>
                    <Col span={16}>
                      <ReleaseList listData={assetDetail.releaseItems} />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={8}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

export default AssetReview;
