import {
    Card,
    Row,
    Col,
    Form,
    Divider,
    TabsProps,
    Tabs,
    Space,
    Input,
    Table,
    Radio,
    Switch,
    DatePicker,
    Button,
    InputNumber,
    RadioChangeEvent,
    message,
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { DiscountTargetType, PromotionType } from '../../constant/typeConstants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { PromotionFixedCreatingModel, PromotionPercentageCreatingModel } from '../../models/promotionModel';
import { PromotionService } from '../../services/promotionService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { AccountService } from '../../services/accountService';
import dayjs from 'dayjs';
import { CustomerTarget } from '../../models/accountModel';

const options = [
    { label: DiscountTargetType.All, value: DiscountTargetType.All },
    { label: DiscountTargetType.Vip, value: DiscountTargetType.Vip },
    { label: DiscountTargetType.NewCustomer, value: DiscountTargetType.NewCustomer },
    { label: DiscountTargetType.SpecificCustomer, value: DiscountTargetType.SpecificCustomer },
];

export default function PromotionDetail() {
    const [form] = Form.useForm();
    const [promotionType, setPromotionType] = useState('');
    const [promotionId, setPromotionId] = useState('');
    const [discountTargetType, setDiscountTargetType] = useState<DiscountTargetType>(DiscountTargetType.All);
    const [customerTarget, setCustomerTargets] = useState<CustomerTarget[]>([]);
    const [searchParams,] = useSearchParams();
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableEdit, setDisableEdit] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setPromotionType(PromotionType.Percentage);
        setDiscountTargetType(DiscountTargetType.All);
        form.setFieldValue('isActive', true);
    }, []);

    useEffect(() => {
        const id = searchParams.get('id');
        if (id) {
            onGetPromotion(id);
            setPromotionId(id);
        }
    }, [searchParams]);

    const items: TabsProps['items'] = [
        {
            key: PromotionType.Percentage,
            label: `Percentage`,
            disabled: isDisabled,
            children: (
                <>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item
                                name='percentage'
                                className='text-light w-100'
                                label='Percentage'
                                rules={[
                                    {
                                        required: promotionType === PromotionType.Percentage,
                                        message: 'Please input your Discount Code!',
                                    },
                                ]}
                            >
                                <InputNumber
                                    size='large'
                                    min={0}
                                    max={100}
                                    className='w-100'
                                    addonAfter='%'
                                    disabled={isDisabled}
                                    placeholder='Percentage'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item
                                name='minimumBillAmount'
                                className='text-light w-100'
                                label='Minimum Bill Amount'
                                rules={[{ required: false, message: 'Please input your Discount Code!' }]}
                            >
                                <InputNumber
                                    size='large'
                                    min={0}
                                    className='w-100'
                                    addonBefore='>='
                                    disabled={isDisabled}
                                    placeholder='Minimum Bill Amount'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item
                                name='maximumDiscountAmount'
                                className='text-light w-100'
                                label='Maximum Discount Amount'
                                rules={[{ required: false, message: 'Please input your Discount Code!' }]}
                            >
                                <InputNumber
                                    size='large'
                                    min={0}
                                    className='w-100'
                                    addonAfter='Baht'
                                    disabled={isDisabled}
                                    placeholder='Maximum Discount Amount'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: PromotionType.Fixed,
            label: `Fixed`,
            disabled: isDisabled,
            children: (
                <>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item
                                name='discountAmount'
                                className='text-light w-100'
                                label='Discount Amount'
                                rules={[
                                    {
                                        required: promotionType === PromotionType.Fixed,
                                        message: 'Please input your Discount Code!',
                                    },
                                ]}
                            >
                                <InputNumber
                                    size='large'
                                    min={0}
                                    className='w-100'
                                    addonAfter='Baht'
                                    disabled={isDisabled}
                                    placeholder='Discount Amount'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={5}>
                            <Form.Item
                                name='minimumBillAmount'
                                className='text-light w-100'
                                label='Minimum Bill Amount'
                                rules={[{ required: false, message: 'Please input your Discount Code!' }]}
                            >
                                <InputNumber
                                    size='large'
                                    min={0}
                                    className='w-100'
                                    addonBefore='>='
                                    disabled={isDisabled}
                                    placeholder='Minimum Bill Amount'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    const customerTargetColumns: ColumnsType<CustomerTarget> = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (value, record, index) => <p>{index + 1}</p>,
        }, 
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        }, 
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (value, record, index) => 
                <Button
                    danger
                    onClick={() => onRemoveCustomerTarget(record.userId)}
                    disabled={isDisabled}>
                    Remove
                </Button>,
        },
    ];

    const onFinish = (value: any) => {
        const discountCode = form.getFieldValue('discountCode');
        const description = form.getFieldValue('description');
        const quantity = form.getFieldValue('quantity');
        const isActive = form.getFieldValue('isActive');
        let startDate: Date = new Date();
        let endDate;

        const customerIds =
            discountTargetType === DiscountTargetType.SpecificCustomer
                ? customerTarget.map(ct => ct.userId)
                : [];

        if (discountTargetType === DiscountTargetType.SpecificCustomer
            && (!customerIds || customerIds.length <= 0)) 
        {
            message.error('Please select specify customer at least 1.');

            return;
        }

        if (value.startDate) {
            startDate = new Date(value.startDate);
        }

        if (value.endDate) {
            endDate = new Date(value.endDate);
        }

        if (promotionType === PromotionType.Percentage) {
            const request: PromotionPercentageCreatingModel = {
                discountCode: discountCode,
                description: description,
                quantity: quantity,
                startDate: startDate,
                endDate: endDate ? endDate : undefined,
                isActive: isActive,
                minimumBillAmount: value.minimumBillAmount,
                count: value.percentage,
                maximumDiscountAmount: value.maximumDiscountAmount,
                customerTarget: {
                    discountTarget: discountTargetType,
                    customerIds: customerIds,
                },
            };

            if (promotionId) {
                onUpdatePercentage(request);
            } else {
                onCreatePercentage(request);
            }
        } else {
            const request: PromotionFixedCreatingModel = {
                discountCode: discountCode,
                description: description,
                quantity: quantity,
                startDate: startDate,
                endDate: endDate ? endDate : undefined,
                isActive: isActive,
                minimumBillAmount: value.minimumBillAmount,
                discountAmount: value.discountAmount,
                customerTarget: {
                    discountTarget: discountTargetType,
                    customerIds: customerIds,
                },
            };

            if (promotionId) {
                onUpdateFixed(request);
            } else {
                onCreateFixed(request);
            }
        }
    };

    const reloadPageWithSuccess = (promotionId: string) => {
        message.success('Save promotion success.');

        setTimeout(() => {
            window.location.href = `${RouteNames.promotionDetail}?id=${promotionId}`;
        }, 1000);
    }

    const onCreatePercentage = async (request: PromotionPercentageCreatingModel) => {
        const { data, status } = await new PromotionService().createPromotionPercentage(request);

        if (status === HttpStatus.CONFLICT) {
            message.error('Discount Code is conflict.');
            return;
        }

        if (status !== HttpStatus.OK) {
            message.error('Save promotion failed.');
            return;
        }

        reloadPageWithSuccess(data.id);
    };

    const onUpdatePercentage = async (request: PromotionPercentageCreatingModel) => {
        const { data, status } = await new PromotionService().updatePromotionPercentage(promotionId, request);

        if (status === HttpStatus.CONFLICT) {
            message.error('Discount Code is conflict.');
            return;
        }

        if (status !== HttpStatus.OK) {
            message.error('Save promotion failed.');
            return;
        }

        reloadPageWithSuccess(data.id);
    };

    const onCreateFixed = async (request: PromotionFixedCreatingModel) => {
        const { data, status } = await new PromotionService().createPromotionFix(request);

        if (status === HttpStatus.CONFLICT) {
            message.error('Discount Code is conflict.');
            return;
        }
        
        if (status !== HttpStatus.OK) {
            message.error('Save promotion failed.');
            return;
        }

        reloadPageWithSuccess(data.id);
    };

    const onUpdateFixed = async (request: PromotionFixedCreatingModel) => {
        const { data, status } = await new PromotionService().updatePromotionFix(promotionId, request);

        if (status === HttpStatus.CONFLICT) {
            message.error('Discount Code is conflict.');
            return;
        }

        if (status !== HttpStatus.OK) {
            message.error('Save promotion failed.');
            return;
        }

        reloadPageWithSuccess(data.id);
    };

    const onChangeTab = (key: string) => {
        setPromotionType(key);
    };

    const onChangeDescountTargetType = ({ target: { value } }: RadioChangeEvent) => {
        setDiscountTargetType(value);
    };

    const onAddCustomerTarget = async () => {
        const customerEmail: string = form.getFieldValue('customerEmail');

        if (!customerEmail) {
            return;
        }

        if (customerTarget.filter((ct) => ct.email.trim() === customerEmail.trim()).length > 0) {
            message.error('User duplicate');

            return;
        }

        const { data, status } = await new AccountService().getSpecifyCustomerByEmailAsync(customerEmail);

        if (status !== HttpStatus.OK) {
            message.error('User not found');

            return;
        }

        setCustomerTargets([...customerTarget, data]);

        form.resetFields(['customerEmail']);
    };

    const onRemoveCustomerTarget = async (customerId: string) => {
        setCustomerTargets(customerTarget.filter(ct => ct.userId !== customerId));
    }

    const onGetPromotion = async (id: string) => {
        const { data, status } = await new PromotionService().getId(id);

        if (status !== HttpStatus.OK) {
            return;
        }

        const dateNow = new Date();
        const dateStart = new Date(data.startDate);
        if (data.endDate) {
            const dateEnd = new Date(data.endDate);
            if (dateEnd <= dateNow) {
                setDisableEdit(true);
            } else {
                setDisableEdit(false);
            }
        } else {
            setDisableEdit(false);
        }

        const dateEnd = new Date(data.endDate);

        if (dateStart <= dateNow || dateEnd <= dateNow) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }

        if (data.endDate) {
            form.setFieldValue('endDate', dayjs(data.endDate));
        }

        form.setFieldValue('discountCode', data.discountCode);
        form.setFieldValue('description', data.description);
        form.setFieldValue('quantity', data.quantity);
        form.setFieldValue('startDate', dayjs(data.startDate));
        form.setFieldValue('isActive', data.isActive);
        form.setFieldValue('percentage', data.count);
        form.setFieldValue('minimumBillAmount', data.minimumBillAmount);
        form.setFieldValue('maximumDiscountAmount', data.maximumDiscountAmount);
        form.setFieldValue('discountAmount', data.discountAmount);

        setIsActive(data.isActive);
        setCustomerTargets(data.customerTargetDetail);

        if (data.promotionType === 'PercentagePromotion') {
            setPromotionType(PromotionType.Percentage);
        } else {
            setPromotionType(PromotionType.Fixed);
        }

        if (data.customerTarget) {
            switch (data.customerTarget.discriminator) {
                case 'AllCustomerTarget':
                    setDiscountTargetType(DiscountTargetType.All);
                    form.setFieldValue('discountTarget', DiscountTargetType.All);
                    break;
                case 'VipCustomerTarget':
                    setDiscountTargetType(DiscountTargetType.Vip);
                    form.setFieldValue('discountTarget', DiscountTargetType.Vip);
                    break;
                case 'NewCustomerTarget':
                    setDiscountTargetType(DiscountTargetType.NewCustomer);
                    form.setFieldValue('discountTarget', DiscountTargetType.NewCustomer);
                    break;
                case 'SpecificCustomerTarget':
                    setDiscountTargetType(DiscountTargetType.SpecificCustomer);
                    form.setFieldValue('discountTarget', DiscountTargetType.SpecificCustomer);
                    break;
            }
        }
    };

    const onChangeActive = (checked: boolean) => {
        setIsActive(checked);
    };

    return (
        <>
            <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                <Card
                    title='Promotion Detail'
                    extra={
                        <Space align='center'>
                            {!disableEdit && (
                                <Button type='primary' htmlType='submit' size='large'>
                                    Save
                                </Button>
                            )}
                        </Space>
                    }
                    bordered={false}
                >
                    <div className='p-4'>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    name='discountCode'
                                    className='text-light w-100'
                                    label='Discount Code'
                                    rules={[
                                        { required: true, message: 'Please input your Discount Code!' },
                                        { pattern: /^[\w-_.]*$/, message: 'Please input charactor and number only' },
                                    ]}
                                >
                                    <Input size='large' disabled={isDisabled} placeholder='Discount Code' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    name='description'
                                    className='text-light w-100'
                                    label='Description'
                                    rules={[{ required: true, message: 'Please input your Description!' }]}
                                >
                                    <TextArea size='large' disabled={isDisabled} placeholder='Description' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='quantity'
                                    className='text-light'
                                    label='Quantity'
                                    rules={[{ required: true, message: 'Please input your Discount Code!' }]}
                                >
                                    <InputNumber size='large' min={0} disabled={isDisabled} placeholder='Quantity' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    name='startDate'
                                    className='text-light w-100'
                                    label='Start Date'
                                    rules={[{ required: true, message: 'Please input your Description!' }]}
                                >
                                    <DatePicker size='large' showTime disabled={isDisabled} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='endDate' className='text-light' label='End Date'>
                                    <DatePicker size='large' showTime disabled={isDisabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item name='isActive' className='text-light w-100' label='Active'>
                                    <Switch
                                        checked={isActive}
                                        onChange={onChangeActive}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation='left' plain>
                            Discount Type
                        </Divider>
                        {
                            items &&
                            promotionType && 
                            <Tabs activeKey={promotionType} items={items} onChange={onChangeTab} />
                        }
                        <Divider orientation='left' plain>
                            Specify Customer
                        </Divider>
                        <Row>
                            <Col>
                                <Form.Item name='discountTarget' className='text-light'>
                                    <Radio.Group
                                        options={options}
                                        onChange={onChangeDescountTargetType}
                                        optionType='button'
                                        buttonStyle='solid'
                                        disabled={isDisabled}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            discountTargetType === DiscountTargetType.SpecificCustomer && 
                            <>
                                <Divider orientation='left' plain>
                                    Customer
                                </Divider>
                                <Row justify='start'>
                                    <Col span={6}>
                                        <Form.Item
                                            name='customerEmail'
                                            className='text-light w-100'
                                            label='Customer Email'>
                                            <Space.Compact style={{ width: '100%' }}>
                                                <Input
                                                    className='w-100'
                                                    size='large'
                                                    disabled={isDisabled} />
                                                <Button
                                                    type='primary'
                                                    onClick={() => onAddCustomerTarget()}
                                                    disabled={isDisabled}>
                                                    Add
                                                </Button>
                                            </Space.Compact>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            scroll={{ x: true }}
                                            size='small'
                                            pagination={false}
                                            className="mt-4"
                                            columns={customerTargetColumns}
                                            dataSource={customerTarget} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Card>
            </Form>
        </>
    );
}
