import { Card, Row, Col, Form, Space, Pagination, Table, Input, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { BannerModel } from '../../models/bannerModel';
import { useState, useEffect } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { BannerService } from '../../services/bannerService';
import { RouteNames } from '../../components/Route/routeName';
import { DateFormat } from '../../constant/constants';
import moment from 'moment';

export default function Assets() {
    const [form] = Form.useForm();
    const [banners, setBanners] = useState<BannerModel[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const navigate = useNavigate();

    const onFinish = () => {
        onGetListBanners(page, size);
    };

    useEffect(() => {
        onGetListBanners(page, size);
    }, []);

    const onReset = () => {
        form.resetFields();
        onGetListBanners(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListBanners(page, size);
    };

    const onGetListBanners = async (page: number, size: number) => {
        const { data, status } = await new BannerService().getBanners(page, size, form.getFieldValue('name'));

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setBanners(data.list);
    };

    const columns: ColumnsType<BannerModel> = [
        {
            title: 'Banner Name',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            width: 200,
            align: 'center',
            render: (value, record, index) => <>{moment(record.startDate).format(DateFormat.DateTime)}</>,
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            width: 200,
            align: 'center',
            render: (value, record, index) => <>{record.endDate && (moment(record.endDate).format(DateFormat.DateTime))}</>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            width: 200,
            render: (value, record, index) => <Link to={`${RouteNames.bannerDetail}?id=${record.id}`}>Detail</Link>,
        },
    ];

    return (
        <>
            <Card title='Banner' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row justify='center'>
                            <Col span={8}>
                                <Form.Item
                                    name='name'
                                    className=' text-light w-100'
                                    label='Banner Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Banner Name' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row className='mt-3'>
                    <Col>
                        <Button type='primary' onClick={() => navigate(RouteNames.bannerDetail)}>
                            New Banner
                        </Button>
                    </Col>
                </Row>
                <Table scroll={{x: true}} className='mt-4' columns={columns} dataSource={banners} pagination={false} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}