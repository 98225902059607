import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';

export class ReportAssetInfoService {
  private api = 'ReportAssetInfo';

  getList(page: number, size: number, assetCode: string): Promise<AxiosResponse> {
    const params = {
        'page': page,
        'size': size,
        'assetCode': assetCode,
      }
    return Axios.get(`${this.api}`, { params });
  }
}