import { Card, Row, Col, Form, Space, Typography, Divider, Button, Input, DatePicker, Modal, message } from 'antd';
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import dayjs from 'dayjs';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { StringConstants } from '../../constant/stringConstants';
import { RecommendCollectionCreatingModel, RecommendCollectionItemModel, RecommendCollectionModel } from '../../models/recommendCollectionModel';
import { RecommendCollectionService } from '../../services/recommendCollectionService';
import { CollectionService } from '../../services/collectionService';
import CollectionItem from '../../components/collection/CollectionItem';
import Title from 'antd/es/typography/Title';

export default function RecommendCollectionDetail() {
  const [form] = Form.useForm();
  const [recommendCollectionItems, setRecommendCollectionItems] = useState<RecommendCollectionItemModel[]>([]);
  const { confirm, error } = Modal;
  const [searchParams] = useSearchParams();
  const [recommentCollectionId, setRecommentCollectionId] = useState("");
  const [, setRecommentCollection] = useState<RecommendCollectionModel>({} as RecommendCollectionModel);

    useEffect(() => {
    const id = searchParams.get('id');
    if(id){
        setRecommentCollectionId(id);
        onGetRecommentCollection(id);
    }
    }, [searchParams]);

    const onGetRecommentCollection = async (id: string) => {
        const { data, status } = await new RecommendCollectionService().getId(id);

        if (status !== HttpStatus.OK) {
            return;
        }

        setRecommentCollection(data);
        setRecommendCollectionItems(data.recommendCollectionItems);
        form.setFieldValue('name', data.name);
        form.setFieldValue('startDate', dayjs(data.startDate));
        if(data.endDate){
            form.setFieldValue('endDate', dayjs(data.endDate));
        }
        setRecommentCollectionId(data.id);
    };

  const onAddItem = async () => {
    const collectionId: string = form.getFieldValue('collectionId');

    if(collectionId){
        if(recommendCollectionItems.filter(x => x.collectionId === collectionId).length > 0){
            return;
        }
        const { data, status } = await new CollectionService().getIdAsync(collectionId);

        if (status !== HttpStatus.OK) {
            error({
                title: 'Collection is not found!',
            });
            return;
        }
        
        message.success('Add item success.');
        form.setFieldValue('collectionId', StringConstants.EMPTY);
        setRecommendCollectionItems([...recommendCollectionItems, { id: undefined, collectionId: collectionId, collection: data }]);
    }
  };

  const onFinish = (value: any) => {
    let startDate;
    let endDate;
    if(value.startDate){
        startDate = new Date(value.startDate);
    }

    if(value.endDate){
        endDate = new Date(value.endDate);
    }

    const request: RecommendCollectionCreatingModel = {
        name: value.name,
        startDate: new Date(value.startDate),
        endDate: endDate,
        recommendCollectionItems: recommendCollectionItems
    }

    if(recommentCollectionId){
        onUpdate(request);
    }
    else{
        onCreate(request);
    }
  };

  const onCreate = async (request: RecommendCollectionCreatingModel) => {
    const { data, status } = await new RecommendCollectionService().createRecommendCollection(request);

    if (status !== HttpStatus.OK) {
        message.error('Save recommend collection failed.');
        return;
    }

    message.success('Save recommend collection success.');
    window.location.href = `${RouteNames.recommendCollectionDetail}?id=${data.id}`;
};

const onUpdate = async (request: RecommendCollectionCreatingModel) => {
    const { data, status } = await new RecommendCollectionService().updateRecommendCollection(recommentCollectionId, request);

    if (status !== HttpStatus.OK) {
        message.error('Save recommend collection failed.');
        return;
    }

    message.success('Save recommend collection success.');
    window.location.href = `${RouteNames.recommendCollectionDetail}?id=${data.id}`;
};

const Delete = (id: string) => {
    confirm({
        title: `Do you want to delete ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
            onDeleteRecommentCollection(id);
        },
    });
}

const onDeleteRecommentCollection = async (id: string) => {
    const { data, status } = await new RecommendCollectionService().deleteRecommendCollection(id);

    if (status !== HttpStatus.NO_CONTENT) {
        message.error('Delete recommend collection failed.');
        return;
    }

    message.success('Delete recommend collection success.');
    window.location.href = `${RouteNames.recommendCollection}`;
};

const onDeleteRecommentCollectionItem = async (itemId: string) => {
    const { data, status } = await new RecommendCollectionService().deleteRecommendCollectionItem(recommentCollectionId, itemId);

    if (status !== HttpStatus.NO_CONTENT) {
        message.error('Delete item failed.');
        return;
    }

    message.success('Delete item success.');
    window.location.href = `${RouteNames.recommendCollectionDetail}?id=${recommentCollectionId}`;
};

const onClickItem = (collectionId: string) => {
    window.location.href = RouteNames.assetDetail.replace(':id', collectionId);
};

const onDeleteItem = (collectionId: string) => {
    confirm({
        title: `Do you want to delete ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
            const itemData = recommendCollectionItems.filter(x => x.collectionId == collectionId)[0];
            const newData = recommendCollectionItems.filter(x => x.collectionId !== collectionId);
            setRecommendCollectionItems(newData);
            if(itemData.id){
                onDeleteRecommentCollectionItem(itemData.id);
            }
        },
    });
  };


  return (
    <>
      <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
    <Card className='card-customize' title={
        <Space align="center">
          <Typography.Title level={5} className='mb-0'>Recommend Collection Detail</Typography.Title>
        </Space>
      }
        extra={
            <Space align='center'>
            <Button type='primary' danger size='large' onClick={() => Delete(recommentCollectionId)}>
                Delete
            </Button>
            <Button type='primary' htmlType='submit' size='large'>
                Save
            </Button>
        </Space>
        }
      >
        <div className='p-4'>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                    name='name'
                    className='text-light w-100'
                    label='Recommend Name'
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input className='w-100' size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item
                    name='startDate'
                    className='text-light w-100'
                    label='Start Date'
                    rules={[{ required: true, message: 'Please input your startdate!' }]}
                >
                    <DatePicker showTime className='w-100' size='large' />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item
                    name='endDate'
                    className='text-light w-100'
                    label='End Date'
                    rules={[{ required: false, message: 'Please input your enddate!' }]}
                >
                    <DatePicker showTime className='w-100' size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">List Collection</Divider>
            <Row>
                <Col style={{paddingTop: 5, paddingRight: 5}}>
                    <Title level={5}>Collection Id : </Title>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='collectionId'
                        className='text-light w-100'
                        rules={[{ required: false }]}
                    >
                        <Input className='w-100' size='large' />
                    </Form.Item>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => onAddItem()} size='large'>Add</Button>
                </Col>
            </Row>
            <div className="collection">
            <Row gutter={16}>
            {
                        recommendCollectionItems && (
                            recommendCollectionItems.map((data, index) => 
                            <Col>
                            {
                                data.collection && (
                                    <CollectionItem collectionIndex={index} collectionId={data.collection.id} collectionName={data.collection.name} collectionItems={data.collection.collectionItems} width={300} height={200} nameColor="#333" onClickItem={onClickItem} onDelete={onDeleteItem} />
                                )
                            }
                            </Col>
                            )
                        )
                    }
            </Row>
            </div>
        </div>
      </Card>
    </Form>
    </>
  );
};