import CustomerFormDetail from '../../components/CustomerDetail/CustomerDetail';
import { CustomerTab } from '../../constant/constants';

const ArtistDetail: React.FC = () => {

  return (
    <>
      <CustomerFormDetail defaultTab={CustomerTab.Artist} />
    </>
  );
};

export default ArtistDetail;