import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { ReleaseSearchModel } from '../models/releaseModel';

export class ReleaseService {
  private api = 'Release';
  private apiAdmin = 'Admin/release';

  getListAsync(page: number, size: number, request: ReleaseSearchModel): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'ReleaseName': request.releaesName,
        'ReleaseType': request.releaesType,
        'Email': request.email,
        'ArtistName': request.artistName,
      }
    return Axios.get(`${this.api}/list`, { params });
  }

  getIdAsync(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.apiAdmin}/${id}`);
  }
}