import type { CheckboxOptionType } from 'antd';
import { Radio, Form } from 'antd';
import { useMemo } from 'react';
interface Props {
  name?: string;
  label?: string;
  options: Array<CheckboxOptionType | string | number>;
}


function RadioEvent({ name, label, options }: Props) {
  const getValue = useMemo(() => {
    if (options.length) {
      return (options[0] as CheckboxOptionType).value;
    }

    return null;
  }, []);

  return (
    <>
      <Form.Item name={name} label={label} initialValue={getValue}>
        <Radio.Group
          options={options}
          optionType="button"
          size='large'
          buttonStyle="solid"
        />
      </Form.Item>
    </>
  );
};

export default RadioEvent;