import { Row, Col, Form, Divider, Radio, Select, Input } from 'antd';
import { useEffect, useState } from 'react';
import { ArtistTaxInfoProperty, ArtistType, NationalityType } from '../../constant/artistTaxInfoConstants';
import { ArtistTaxInfoFormModel } from '../../models/TaxCenterModel';
import IndividualTaxCenter from './ArtistTaxInfo/IndividualTaxCenter';
import { ArtistTaxInfoService } from '../../services/artistTaxInfoService';
import { HttpStatus } from '../../constant/responseStatus';
import dayjs from 'dayjs';
import { TaxAddressInfo } from '../../models/addressModel';
import countryList from '../../constant/country.json';
import CompanyTaxCenter from './ArtistTaxInfo/CompanyTaxCenter';

const taxTypeOptions = [
  {
    value: ArtistType.Individual,
    label: ArtistType.Individual
  },
  {
    value: ArtistType.Company,
    label: ArtistType.Company
  },
];

const nationTypeOptions = [
  {
    value: NationalityType.Thai,
    label: NationalityType.Thai
  },
  {
    value: NationalityType.Foreigner,
    label: NationalityType.Foreigner
  },
];

interface IProps { userId: string}

export default function ArtistTaxCenterDetail({userId}: IProps) {
  const [form] = Form.useForm();
  const artistTaxInfoService = new ArtistTaxInfoService();

  const [residenceAddress, setResidenceAddress] = useState<TaxAddressInfo>({} as TaxAddressInfo);
  const [mailingAddress, setMailingAddress] = useState<TaxAddressInfo>({} as TaxAddressInfo);

  const [taxCenter, setTaxCenter] = useState<ArtistTaxInfoFormModel>({
    artistType: ArtistType.Individual,
    nationalityType: NationalityType.Thai,
  } as ArtistTaxInfoFormModel);

  useEffect(() => {
    if (userId &&
        taxCenter.nationalityType &&
        taxCenter.artistType) {
      getArtistTaxInfo();
    }
  }, [taxCenter]);

  const getArtistTaxInfo = async () => {
    form.resetFields();

    const { data, status} = await artistTaxInfoService.getTaxInfoByAllTypeAsync(userId, taxCenter.artistType, taxCenter.nationalityType);

    if (status !== HttpStatus.OK) {
      return;
    }

    var isIndividualThai = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Thai;
    var isIndividualForeigner = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Foreigner;
    var isCompanyThai = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Thai;
    var isCompanyForeigner = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Foreigner;

    setResidenceAddress({ 
      address1: data.residenceAddress?.address1,
      address2: data.residenceAddress?.address2,
      city: data.residenceAddress?.city,
      country: data.residenceAddress?.country,
      postcode: data.residenceAddress?.postcode,
    });

    setMailingAddress({ 
      address1: data.mailingAddress?.address1,
      address2: data.mailingAddress?.address2,
      city: data.mailingAddress?.city,
      country: data.mailingAddress?.country,
      postcode: data.mailingAddress?.postcode,
    });

    form.setFieldValue(ArtistTaxInfoProperty.fullName, data.fullName);

    if (isIndividualThai) {
      form.setFieldValue(ArtistTaxInfoProperty.idCardNumber, data.idCardNumber);
      form.setFieldValue(ArtistTaxInfoProperty.phone, data.phone);
      form.setFieldValue(ArtistTaxInfoProperty.email, data.email);
      form.setFieldValue(ArtistTaxInfoProperty.address1, data.taxAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.address2, data.taxAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.city, data.taxAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcode, data.taxAddress?.postcode);

      if (data.idCardAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentUrl, data.idCardAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileName, data.idCardAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileNameOriginal, data.idCardAttachment.fileNameOriginal);
      }
    }

    if (isIndividualForeigner || isCompanyForeigner) {
      form.setFieldValue(ArtistTaxInfoProperty.countryOfCitizenship, data.countryOfCitizenship);
      form.setFieldValue(ArtistTaxInfoProperty.poBoxType, data.poBoxType);
      form.setFieldValue(ArtistTaxInfoProperty.referenceNumber, data.referenceNumber);
      form.setFieldValue(ArtistTaxInfoProperty.taxIdNumber, data.taxIdNumber);
      form.setFieldValue(ArtistTaxInfoProperty.claimOfTax, data.claimOfTax);
      form.setFieldValue(ArtistTaxInfoProperty.usTaxPayerType, data.usTaxPayer?.type);
      form.setFieldValue(ArtistTaxInfoProperty.usTaxPayerNumber, data.usTaxPayer?.number);
      form.setFieldValue(ArtistTaxInfoProperty.address1, data.taxAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.address2, data.taxAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.city, data.taxAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcode, data.taxAddress?.postcode);
      form.setFieldValue(ArtistTaxInfoProperty.mailingAddress1, data.taxMailingAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.mailingAddress2, data.taxMailingAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.mailingCity, data.taxMailingAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.mailingPostcode, data.taxMailingAddress?.postcode);

      if (data.certificationAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentUrl, data.certificationAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileName, data.certificationAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileNameOriginal, data.certificationAttachment.fileNameOriginal);
      }

      if (data.dateOfBirth) {
        form.setFieldValue(ArtistTaxInfoProperty.dateOfBirth, dayjs(data.dateOfBirth));
      } 
    }

    if (isCompanyThai) {
      form.setFieldValue(ArtistTaxInfoProperty.juristicPersonNameTh, data.juristicPersonNameTh);
      form.setFieldValue(ArtistTaxInfoProperty.juristicPersonNameEn, data.juristicPersonNameEn);
      form.setFieldValue(ArtistTaxInfoProperty.taxIdNumber, data.taxIdNumber);
      form.setFieldValue(ArtistTaxInfoProperty.companyContactEmail, data.companyContactEmail);
      form.setFieldValue(ArtistTaxInfoProperty.companyContactPhone, data.companyContactPhone);

      form.setFieldValue(ArtistTaxInfoProperty.companyAddress1Th, data.juristicPersonAddressTh?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress2Th, data.juristicPersonAddressTh?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.cityTh, data.juristicPersonAddressTh?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcodeTh, data.juristicPersonAddressTh?.postcode);
      
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress1En, data.juristicPersonAddressEn?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress2En, data.juristicPersonAddressEn?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.cityEn, data.juristicPersonAddressEn?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcodeEn, data.juristicPersonAddressEn?.postcode);

      if (data.compVatRegisterCertificateAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl, data.compVatRegisterCertificateAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileName, data.compVatRegisterCertificateAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileNameOriginal, data.compVatRegisterCertificateAttachment.fileNameOriginal);
      }

      if (data.compCerRegIssueAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl, data.compCerRegIssueAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileName, data.compCerRegIssueAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileNameOriginal, data.compCerRegIssueAttachment.fileNameOriginal);
      }
      
      if (data.compCommercialRegAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentUrl, data.compCommercialRegAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileName, data.compCommercialRegAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileNameOriginal, data.compCommercialRegAttachment.fileNameOriginal);
      }
      
      if (data.compListOfShareholdersAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl, data.compListOfShareholdersAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileName, data.compListOfShareholdersAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileNameOriginal, data.compListOfShareholdersAttachment.fileNameOriginal);
      }
      
      if (data.compIdCardAuthorizedPersonAttachment) {
        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl, data.compIdCardAuthorizedPersonAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileName, data.compIdCardAuthorizedPersonAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileNameOriginal, data.compIdCardAuthorizedPersonAttachment.fileNameOriginal);
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Form.Item>
                  <Radio.Group
                    options={taxTypeOptions}
                    value={taxCenter.artistType}
                    onChange={(val) => {
                      setTaxCenter({...taxCenter, artistType: val.target.value});
                    }}
                    optionType="button"
                    buttonStyle="solid"
                    size='large' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                  <Radio.Group
                    options={nationTypeOptions}
                    value={taxCenter.nationalityType}
                    onChange={(val) => {
                      setTaxCenter({...taxCenter, nationalityType: val.target.value});
                    }}
                    optionType="button"
                    buttonStyle="solid"
                    size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form
        className='form-readonly-white'
        disabled={true}
        form={form}
        layout='vertical'>
          <div className='p-4'>
            <Row>
              <Col span={24}>
                {
                  taxCenter.artistType === ArtistType.Individual
                  ? <IndividualTaxCenter
                      nationalityType={taxCenter.nationalityType}
                      useFormTaxInfo={form} />
                  : 
                  (
                    taxCenter.nationalityType === NationalityType.Thai
                    ? <CompanyTaxCenter useFormTaxInfo={form} />
                    : <IndividualTaxCenter
                        nationalityType={taxCenter.nationalityType}
                        useFormTaxInfo={form} />
                  )
                }
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col md={12} sm={24}>
                <Row>
                  <Col span={24}>
                    <Divider orientation="left" plain>
                      Residential Address
                    </Divider>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Address 01">
                        <Input
                          name="address01"
                          value={residenceAddress.address1}
                          onChange={(a) => setResidenceAddress({...residenceAddress, address1: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Address 02">
                        <Input
                          value={residenceAddress.address2}
                          onChange={(a) => setResidenceAddress({...residenceAddress, address2: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="City">
                        <Input
                          value={residenceAddress.city}
                          onChange={(a) => setResidenceAddress({...residenceAddress, city: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Country">
                        <Select
                        value={residenceAddress.country ?? ''}
                        onChange={(val) => setResidenceAddress({...residenceAddress, country: val})}
                        size='large'
                        className='w-100'
                        >
                          <Select.Option value={''}>
                            Select Country
                          </Select.Option>
                          {
                            countryList.map((data, index) => (
                              <Select.Option value={data.name} key={data.code}>
                                {data.name}
                              </Select.Option>
                            ))
                          }
                        </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Zipcode">
                        <Input
                          value={residenceAddress.postcode}
                          onChange={(a) => setResidenceAddress({...residenceAddress, postcode: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={12} sm={24}>
                <Row>
                  <Col span={24}>
                    <Divider orientation="left" plain>
                      Mailing Address
                    </Divider>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Address 01">
                        <Input
                          value={mailingAddress.address1}
                          onChange={(a) => setMailingAddress({...mailingAddress, address1: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Address 02">
                        <Input
                          value={mailingAddress.address2}
                          onChange={(a) => setMailingAddress({...mailingAddress, address2: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="City">
                        <Input
                          value={mailingAddress.city}
                          onChange={(a) => setMailingAddress({...mailingAddress, city: a.target.value})}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Country">
                        <Select
                        value={mailingAddress.country ?? ''}
                        onChange={(val) => setMailingAddress({...mailingAddress, country: val})}
                        size='large'
                        className='w-100'
                        >
                          <Select.Option value={''}>
                            Select Country
                          </Select.Option>
                          {
                            countryList.map((data, index) => (
                              <Select.Option value={data.name} key={data.code}>
                                {data.name}
                              </Select.Option>
                            ))
                          }
                        </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Zipcode">
                        <Input
                          value={mailingAddress.postcode}
                          size='large'
                          className='w-100'
                        />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
      </Form>
    </>
  );
};