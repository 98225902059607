import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';

export class ArtistService {
  private api = 'admin/artist';

  approve(artistId: string): Promise<AxiosResponse> {
    return Axios.post(`${this.api}/approve/${artistId}`);
  }

  reject(artistId: string): Promise<AxiosResponse> {
    return Axios.post(`${this.api}/reject/${artistId}`);
  }
}