import { Layout } from 'antd';
import stringFormat from '../../helper/stringFormat';
import { useEffect, useState } from 'react';
import MenuComponent from './MenuComponent';
interface Props {
  colorBgContainer: string;
  currentUrl: string;
}

const { Sider } = Layout;

function SiderMenu({ colorBgContainer, currentUrl }: Props) {
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const setDefaultSelectMenu = (currentUrl: string) => {
    const pathNameWithOutGuid = stringFormat.regexGuidInPath(currentUrl);

    const pathNames = pathNameWithOutGuid.split('/').filter((x) => x);

    setSelectedMenu(pathNames[0]);
  }

  useEffect(() => {
    if (currentUrl) {
      setDefaultSelectMenu(currentUrl);
    }
  }, [currentUrl]);

  return (
    <Sider width={200} style={{ background: colorBgContainer }}>
      <div className="logo" />
      <MenuComponent selectedMenu={selectedMenu} />
    </Sider>
  );
};

export default SiderMenu;