import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { EventlogSearchModel } from '../models/eventlogModel';

export class EventlogsService {
  private api = 'logs';

  getListAsync(page: number, size: number, request: EventlogSearchModel): Promise<AxiosResponse> {
    const params = {
        'Page': page,
        'Size': size,
        'Query': request.query,
        'From': request.from,
        'To': request.to,
      }
    return Axios.get(`${this.api}`, { params });
  }
}