import { Card, Row, Col, Typography, Table, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { dashboardService } from '../../services/dashboardService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { MostSellingModel } from '../../models/dashboardModel';
import { ColumnsType } from 'antd/es/table';
import EarningChart from '../../components/Chart/EarningChart';
import { OrderStatus } from '../../constant/statusConstants';

const { Title, Text } = Typography;

export default function Dashboard() {
  const [totalPublishAssets, setTotalPublishAssets] = useState(0);
  const [totalAssetRequests, setTotalAssetRequests] = useState(0);
  const [totalAssetRequestNew, setTotalAssetRequestNew] = useState(0);
  const [totalPayoutRequests, setTotalPayoutRequests] = useState(0);
  const [, setTotalPayoutTotal] = useState(0);
  const [totalPayoutRequestNew, setTotalPayoutRequestNew] = useState(0);
  const [totalNewArtist, setTotalNewArtist] = useState(0);
  const [totalNewArtistNew, setTotalNewArtistNew] = useState(0);
  const [totalReportAssets, setTotalReportAssets] = useState(0);
  const [totalReportAssetNew, setTotalReportAssetNew] = useState(0);
  const [vipOrderTotal, setVipOrderTotal] = useState(0);
  const [vipOrderTotalNew, setVipOrderTotalNew] = useState(0);
  const [contactTotal, setContactTotal] = useState(0);
  const [contactTotalNew, setContactTotalNew] = useState(0);
  const [mostSellingDatas, setMostSellingDatas] = useState<MostSellingModel[]>([]);
  const [earnChartData1, setEarnChartData1] = useState<number[]>([]);
  const [earnChartData2, setEarnChartData2] = useState<number[]>([]);

  const columns: ColumnsType<MostSellingModel> = [
    {
      title: 'Image',
      dataIndex: 'image',
      width: 60,
      align: 'center',
      render: (value, record, index) => (
        <img
          key={'i'+index}
          src={record.imageUrl}
          style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
        />
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: '70%',
      align: 'center',
      render: (value, record, index) => 
      <Link
      to={`${RouteNames.assetDetail}?id=${record.id}`}>{record.code}</Link>,
    },
    {
      title: 'Order',
      dataIndex: 'countOrder',
      align: 'center',
      width: 50,
    },
  ];

  useEffect(() => {
    onGetCountData();
    onGetMostSelling();
    onGetEarningChart();
  }, []);

  const onGetCountData = async () => {
    const { data, status } = await new dashboardService().getCountData();

    if (status !== HttpStatus.OK) {
      return;
    }

    setTotalPublishAssets(data[0].publishAsset);

    setTotalAssetRequests(data[0].assetPending);
    setTotalAssetRequestNew(data[0].assetPendingNew);

    setTotalPayoutTotal(data[0].payoutTotal);

    setTotalPayoutRequests(data[0].payoutRequest);
    setTotalPayoutRequestNew(data[0].payoutRequestNew);

    setTotalNewArtist(data[0].artistPending);
    setTotalNewArtistNew(data[0].artistPendingNew);

    setTotalReportAssets(data[0].reportAssetInfo);
    setTotalReportAssetNew(data[0].reportAssetInfoNew);

    setVipOrderTotal(data[0].vipOrderTotal);
    setVipOrderTotalNew(data[0].vipOrderTotalNew);

    setContactTotal(data[0].contactTotal);
    setContactTotalNew(data[0].contactTotalNew);
  };

  const onGetMostSelling = async () => {
    const { data, status } = await new dashboardService().getMostOrderSell();

    if (status !== HttpStatus.OK) {
      return;
    }

    setMostSellingDatas(data);
  };

  const onGetEarningChart = async () => {
    const { data, status } = await new dashboardService().getEarningChart(new Date().getFullYear());

    if (status !== HttpStatus.OK) {
      return;
    }

    let data1: number[] = [];
    let data2: number[] = [];
    data.forEach((d) => {
      data1.push(d.orderCount * 1000);
      data2.push(d.netTotal);
    });

    setEarnChartData1(data1);
    setEarnChartData2(data2);
  };

  return (
    <div className='dashboard'>
      <Row
        className='mb-3'
        justify={'space-between'}
        gutter={16}>
          <Col md={8}>
            <Card className='card-total w-100'>
              <Text className='text-dark-50'>All Publish Asset</Text>
              <div className='detail'>
                <Title
                  level={4}
                  className='mb-0'>
                  {totalPublishAssets.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                </Title>
                <Link to={RouteNames.asset}>view</Link>
              </div>
            </Card>
          </Col>
        </Row>
      <Row
        className='mb-3'
        gutter={16}>
        <Col md={4}>
          <Card className='card-total w-100'>
            <Text className='text-dark-50'>Asset Request </Text>
            {
              (totalAssetRequestNew > 0) &&
              <Badge
                color='#f5222d'
                count= {totalAssetRequestNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
            <div className='detail'>
              <Title
                level={4}
                className='mb-0'>
                {totalAssetRequests.toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </Title>
              <Link to={RouteNames.assetRequests}>view</Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='card-total w-100'>
            <Text className='text-dark-50'>Payment Term Orders </Text>
            {
              vipOrderTotalNew > 0 &&
              <Badge
                color='#f5222d'
                count= {vipOrderTotalNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
            <div className='detail'>
              <Title
                level={4}
                className='mb-0'>
                {vipOrderTotal.toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </Title>
              <Link to={`${RouteNames.orders}?status=${OrderStatus.Pending}&paymentMethod=PaymentTerm`}>view</Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='card-total w-100'>
            <Text className='text-dark-50'>Payout Request </Text>
            {
              totalPayoutRequestNew > 0 &&
              <Badge
                color='#f5222d'
                count= {totalPayoutRequestNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
            <div className='detail'>
              <Title
                level={4}
                className='mb-0'>
                {totalPayoutRequests.toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </Title>
              <Link to={RouteNames.payoutRequest}>view</Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='card-total w-100'>
            <Text className='text-dark-50'>New Artist </Text>
            {
              (totalNewArtistNew > 0) &&
              <Badge
                color='#f5222d'
                count= {totalNewArtistNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
            <div className='detail'>
              <Title
                level={4}
                className='mb-0'>
                {totalNewArtist.toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </Title>
              <Link to={RouteNames.artist}>view</Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className='card-total w-100'>
            <Text className='text-dark-50'>Report Asset </Text>
            {
              (totalReportAssetNew > 0) &&
              <Badge
                color='#f5222d'
                count= {totalReportAssetNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
            <div className='detail'>
              <Title
                level={4}
                className='mb-0'>
                {totalReportAssets.toLocaleString(undefined, { minimumFractionDigits: 0 })}
              </Title>
              <Link to={RouteNames.reportAsset}>view</Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
            <Card className='card-total w-100'>
              <Text className='text-dark-50'>Contact Us </Text>
              {
              (contactTotalNew > 0) &&
              <Badge
                color='#f5222d'
                count= {contactTotalNew.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                />
            }
              <div className='detail'>
                <Title
                  level={4}
                  className='mb-0'>
                  {contactTotal.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                </Title>
                <Link to={RouteNames.contactUs}>view</Link>
              </div>
            </Card>
          </Col>
      </Row>
      <Row
        className='mb-3'
        gutter={16}>
        <Col span={12}>
          <div className='w-100'>
            <Card
              title='Earning Chart'
              bordered={false}>
              <EarningChart
                series={[
                  {
                    name: 'Orders',
                    data: earnChartData1,
                  },
                  {
                    name: 'Total',
                    data: earnChartData2,
                  },
                ]}
              />
            </Card>
          </div>
        </Col>
        <Col span={12}>
          <Table
            scroll={{x: true}}
            dataSource={mostSellingDatas}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  );
}