import { DndProvider } from 'react-dnd';
import AppRoutes from './components/Route/';
import SessionComponent from './components/Session/sessionComponent';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <SessionComponent>
        <DndProvider backend={HTML5Backend}>
            <AppRoutes />
        </DndProvider>
    </SessionComponent>
  );
}

export default App;
