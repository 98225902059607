import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { SaveArtistTaxInfoModel } from '../models/TaxCenterModel';

export class ArtistTaxInfoService {
    private apiController = '/ArtistTaxInfo';

    getTaxInfoByAllTypeAsync(
      artistId: string,
      artistType: string,
      nationalityType: string)
      : Promise<AxiosResponse<SaveArtistTaxInfoModel>> {
      return Axios.get<SaveArtistTaxInfoModel>(`${this.apiController}/artistId/${artistId}/artistType/${artistType}/nationalityType/${nationalityType}`);
    }
}