import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import MasterDataModel from '../models/request/masterDataModel';
import { MasterDataResponse } from '../models/response/MasterDataResponse';

const api = '/MasterData';

export class MasterDataService {
    get(type: number): Promise<AxiosResponse<MasterDataModel[]>> {
        return Axios.get<MasterDataModel[]>(`${api}?masterDataType=${type}`);
    }

    getGeneticAsync<T>(type: number): Promise<AxiosResponse<T>> {
        return Axios.get(`${api}?masterDataType=${type}`);
    }

    getMasterData(type: number): Promise<AxiosResponse<MasterDataResponse[]>> {
        return Axios.get<MasterDataResponse[]>(`${api}?masterDataType=${type}`);
    }

    createMasterData(type: number, name: string): Promise<AxiosResponse<MasterDataResponse>> {
        return Axios.post<MasterDataResponse>(`${api}?type=${type}&name=${name}`);
    }

    deleteMasterData(id: string): Promise<AxiosResponse> {
        return Axios.delete(`${api}/${id}`);
    }
}