import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { OrderModel } from '../../../../models/OrderModel';
import { DateFormat } from '../../../../constant/constants';

interface ReportInterface {
  order: OrderModel;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 'auto',
    padding: 0,
    margin: 0,
    borderBottom: 1,
  },
  descriptionStart: {
    height: '100%',
    width: '30%',
    textAlign: 'left',
    borderRightWidth: 1,
    paddingLeft: 0,
    margin: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  std: {
    height: '100%',
    width: '50%',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionStart2: {
    height: '100%',
    width: '30%',
    textAlign: 'left',
    paddingLeft: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  std2: {
    height: '100%',
    width: '50%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount2: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    paddingRight: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLayer1: {
    marginTop: 5,
    marginBottom: 1,
    fontSize: 12
  },
  textLayer2: {
    marginTop: 1,
    marginBottom: 5,
    fontWeight: 'extrabold',
  },
});

export default function TableHeader({order}: ReportInterface) {
  return (
    <>
    <View style={styles.container}>
      <View style={styles.descriptionStart}>
          <Text style={styles.textLayer1}>Invoice Date :</Text>
          <Text style={styles.textLayer2}>{moment(order.orderDate).format(
                  DateFormat.DateTimeDash
                )}</Text>
      </View>
      <View style={styles.std}>
          <Text style={styles.textLayer1}>Payment Method</Text>
          <Text style={styles.textLayer2}>{order.paymentMethod}</Text>
      </View>
      <View style={styles.amount}>
          <Text style={styles.textLayer1}>Status</Text>
          <Text style={styles.textLayer2}>{order.status}</Text>
      </View>
    </View>
    <View style={styles.container}>
      <View style={styles.descriptionStart2}>
        <Text style={styles.textLayer1}>Asset No.</Text>
      </View>
      <View style={styles.std2}>
        <Text style={styles.textLayer1}>Item Descriptions</Text>
      </View>
      <View style={styles.amount2}>
        <Text style={styles.textLayer1}>Amount</Text>
      </View>
    </View>
    </>
  );
}
