import { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Space, Typography, Button, Input, Modal } from 'antd';
import { ExclamationCircleFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from '../../constant/uploadFileConstants';
import { StringConstants } from '../../constant/stringConstants';
import { S3Constants } from '../../constant/s3Constants';
import AWS from 'aws-sdk';
import TextArea from 'antd/es/input/TextArea';
import { NewsContentService } from '../../services/newsContentService';
import { HttpStatus } from '../../constant/responseStatus';
import { NewsContentModel } from '../../models/newsContentModel';
import { useSearchParams } from 'react-router-dom';
import { RouteNames } from '../../components/Route/routeName';

AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
});

const S3_BUCKET = S3Constants.BUCKET;
const REGION = S3Constants.REGION;
const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export default function RecommendContentsDetail() {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [loading,] = useState(false);
    const [uploadFilePath, setUploadFilePath] = useState<string>(StringConstants.EMPTY);
    const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
    const [, setNewsContent] = useState<NewsContentModel>({} as NewsContentModel);
    const [contentId, setContentId] = useState("");
    const { confirm } = Modal;

    useEffect(() => {
        const id = searchParams.get('id');
        if(id){
            setContentId(id);
            onGetNewsContent(id);
        }
    }, [searchParams]);

    const uploadProps: UploadProps = {
        multiple: false,
        listType: UploadListType.PICTURE_CARD,
        accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
        maxCount: 1,
        showUploadList: false,
        async onRemove() {
            deleteFileFromS3(uploadFileName);
            setUploadFilePath('');
        },
        async customRequest({ file, onError, onSuccess, onProgress }) {
            const originalFile = file as File;

            let errorMessage = await validateUploadFile(originalFile, onError);

            if (errorMessage !== StringConstants.EMPTY) {
                message.error(errorMessage, 10);

                return;
            }

            await addFileToS3(originalFile, onError, onSuccess, onProgress);
        },
    };

    const validateUploadFile = async (file: File, onError: any) => {
        const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
        const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

        if (unknowFileType || fileTypeNotSupport) {
            const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

            onError(errorMessage);

            return errorMessage;
        }

        return StringConstants.EMPTY.toString();
    };

    const deleteFileFromS3 = async (fileName: string) => {
        const params = {
            Bucket: S3Constants.BUCKET,
            Key: fileName,
        };

        await s3Bucket.deleteObject(params, (err) => {
            if (err) {
                console.log(err, err.stack);
                message.error(`${fileName} file remove failed.`);
            }
        });
    };

    const addFileToS3 = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
        const fileName = getFileName(originalFile.name);
        const filePath = getS3FileUrl(fileName);
        setUploadFileName(fileName);

        const params = {
            ACL: S3Constants.ACL_TERM,
            Body: originalFile,
            Bucket: S3_BUCKET,
            Key: fileName,
            ContentType: originalFile.type,
        };

        s3Bucket
            .putObject(params)
            .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
                const progressPercent = Math.round((evt.loaded / evt.total) * 100);
                onProgress!({ percent: progressPercent });
            })
            .promise()
            .then(
                async () => {
                    onSuccess!(fileName);
                    setUploadFilePath(filePath);
                },
                (err) => {
                    onError!(err);
                    throw err;
                }
            );
    };

    const getS3FileUrl = (fileName: string) => {
        return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
    };

    const getFileName = (originalFileName: string) => {
        const now = new Date();

        return (
            UploadFileConstants.FILE_NAME_PREFIX +
            StringConstants.UNDERSCORE +
            'newsContent' +
            StringConstants.UNDERSCORE +
            now.getUTCFullYear() +
            (now.getUTCMonth() + 1) +
            now.getUTCDate() +
            now.getUTCHours() +
            now.getUTCMinutes() +
            now.getUTCSeconds() +
            now.getUTCMilliseconds() +
            StringConstants.UNDERSCORE +
            originalFileName
        );
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onGetNewsContent = async (id: string) => {
        const { data, status } = await new NewsContentService().getId(id);

        if (status !== HttpStatus.OK) {
            return;
        }

        setNewsContent(data);
        form.setFieldValue('subject', data.subject);
        form.setFieldValue('description', data.description);
        form.setFieldValue('redirectTo', data.redirectTo);
        setContentId(data.id);
        setUploadFilePath(data.coverUrl);
    };

    const onCreateNewsContent = async () => {
        const request: NewsContentModel = {
            id: "",
            subject: form.getFieldValue('subject'),
            description: form.getFieldValue('description'),
            redirectTo: form.getFieldValue('redirectTo'),
            coverUrl: uploadFilePath,
        }
        const { data, status } = await new NewsContentService().createNewsContent(request);

        if (status !== HttpStatus.CREATED) {
            message.error('Save content failed.');
            return;
        }

        message.success('Save content success.');
        onGetNewsContent(data.id);
    };

    const onUpdateNewsContent = async () => {
        const request: NewsContentModel = {
            id: contentId,
            subject: form.getFieldValue('subject'),
            description: form.getFieldValue('description'),
            redirectTo: form.getFieldValue('redirectTo'),
            coverUrl: uploadFilePath,
        }
        const { data, status } = await new NewsContentService().updateNewsContent(request);

        if (status !== HttpStatus.OK) {
            message.error('Save content failed.');
            return;
        }

        message.success('Save content success.');
        onGetNewsContent(data.id);
    };

    const Delete = (id: string) => {
        confirm({
            title: `Do you want to delete ?`,
            icon: <ExclamationCircleFilled />,
            onOk() {
                onDelete(id);
            },
        });
    }

    const onDelete = async (id: string) => {
        const { status } = await new NewsContentService().deleteNewsContent(id);

        if (status !== HttpStatus.NO_CONTENT) {
            message.error('Delete content failed.');
            return;
        }

        message.success('Delete content success.');
        window.location.href = `${RouteNames.newsContent}`;
    };

    const onFinish = (value: any) => {
        if(contentId){
            onUpdateNewsContent();
        }
        else{
            onCreateNewsContent();
        }
    };

    return (
        <>
            <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                <Card
                    className='card-customize'
                    title={
                        <Space align='center'>
                            <Typography.Title level={5} className='mb-0'>
                                News Content Detail
                            </Typography.Title>
                        </Space>
                    }
                    extra={
                        <Space align='center'>
                            <Button type='primary' onClick={() => Delete(contentId)} danger>
                                Delete
                            </Button>
                            <Button type='primary' htmlType='submit'>
                                Save
                            </Button>
                        </Space>
                    }
                >
                    <div className='p-4'>
                        <Row>
                            <Col span={4}>
                                <Upload {...uploadProps}>
                                    {uploadFilePath ? (
                                        <img src={uploadFilePath} alt='content cover' style={{ width: '100%' }} />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Col>
                        </Row>

                        <Row gutter={16} className='mt-4'>
                            <Col className='gutter-row' span={12}>
                                <Form.Item
                                    name='subject'
                                    className='text-light w-100'
                                    label='Subject'
                                    rules={[{ required: true, message: 'Please input your subject!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className='gutter-row' span={12}>
                                <Form.Item
                                    name='description'
                                    className='text-light w-100'
                                    label='Description'
                                    rules={[{ required: true, message: 'Please input your description!' }]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className='gutter-row' span={12}>
                                <Form.Item
                                    name='redirectTo'
                                    className='text-light w-100'
                                    label='Redirect Link'
                                    rules={[{ required: true, message: 'Please input your link!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Form>
        </>
    );
}
