
import { Input, Form } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ChangeEvent } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import * as React from 'react';

interface Props {
  readOnly?: boolean;
  placeholder?: string;
  prefix?: string;
  name?: string;
  label?: string;
  size?: SizeType;
  className?: string;
  suffix?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  rules?: Rule[];
  style?: React.CSSProperties;
}

function InputPasswordForm({ placeholder, prefix, name, label, size = 'large', className, suffix, onChange = () => '' , readOnly = false, rules = [{ required: false }], style}: Props) {
  return (
    <>
      <Form.Item label={label} name={name} rules={rules}>
        <Input.Password
          readOnly={readOnly}
          size={size}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          className={className}
          onChange={onChange}
          style={style}
        />
      </Form.Item>
    </>
  );
};
export default InputPasswordForm;