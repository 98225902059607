import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { NewsContentModel } from '../models/newsContentModel';

export class NewsContentService {
  private api = 'NewsContent';

  getList(page: number, size: number, name: string): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'Name': name,
      }
    return Axios.get(`${this.api}`, { params });
  }

  getId(id: string): Promise<AxiosResponse<NewsContentModel>> {
    return Axios.get<NewsContentModel>(`${this.api}/${id}`);
  }

  createNewsContent(request: NewsContentModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}`, JSON.stringify(request));
  }

  updateNewsContent(request: NewsContentModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/${request.id}`, JSON.stringify(request));
  }

  deleteNewsContent(id: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}`);
  }
}