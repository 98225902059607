import { Card, Row, Col, Form, Space, Button, Table, Pagination, Input, Switch, message, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { ContactUsService } from '../../services/contactUsService';
import { ContactUsModel, ContactUsSearchModel } from '../../models/contactUsModel';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import RadioEvent from '../../components/Forms/RadioEvent';


const options = [
    {label: 'ALL', value: ''},
    {label: 'Closed', value: true},
    {label: 'Opened', value: false},
  ];
export default function ContactUs() {
    const [form] = Form.useForm();
    const [contactUs, setContactUs] = useState<ContactUsModel[]>([]);
    const [contactUsDetail, setContactUsDetail] = useState<ContactUsModel>();
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    
    const onFinish = () => {
        onGetListContactUs(page, size);
    };

    useEffect(() => {
        onGetListContactUs(page, size);
    }, []);

    const onReset = () => {
        form.resetFields();
        onGetListContactUs(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListContactUs(page, size);
    };

    const onGetListContactUs = async (page: number, size: number) => {
        const cDate = form.getFieldValue('date');
        const isClosed = form.getFieldValue('isClosed');

        const request: ContactUsSearchModel = {
            name: form.getFieldValue('name'),
            email: form.getFieldValue('email'),
            isClosed: isClosed,
            date: cDate ? new Date(cDate) : undefined,
        };

        const { data, status } = await new ContactUsService().getList(
            page,
            size,
            request,
        );

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setContactUs(data.list);
    };

    const onClickClosed = async (id: string, isClosed: boolean) => {
      setIsLoading(true);
      const { status } = await new ContactUsService().updateContactClosed(id,isClosed);

      if (status !== HttpStatus.OK) {
        message.error('Close failed.');
        setIsLoading(false);
        return;
      }

      onGetListContactUs(page, size);
      message.success('Closed Success.');
      setIsLoading(false);
    };

    const showContactUsDetail = (data: ContactUsModel) => {
        setIsDetailModalOpen(true);
        setContactUsDetail(data);
    }

    const columns: ColumnsType<ContactUsModel> = [
        {
            title: 'Date',
            dataIndex: 'createAt',
            width: 150,
            align: 'center',
            render: (value, record, index) => (
                <>{record.contactAt && moment(record.contactAt).format(DateFormat.DateTime)}</>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'firstname',
            width: 200,
            render: (value, record, index) => (
                record.userId
                ? <Link to={`Customer?id=${record.userId}`}>{record.firstname} {record.lastname}</Link>
                : <>{record.firstname} {record.lastname}</>
            ),
            align: 'center',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            width: 500,
            align: 'center',
            render: (value, record, index) => (
              <div className='text-left'>{record.message}</div>
            ),
        },
        {
          title: 'Status',
          dataIndex: 'isClosed',
          width: 100,
          align: 'center',
          render: (value, record, index) => (
            <>
            {record.isClosed ? 'Closed' : 'Open'}
            </>
          ),
        },
        
        {
          title: 'Action',
          width: 120,
          align: 'center',
          render: (value, record, index) => (
            <>
            <Space>
            <Switch
                disabled = {record.isClosed}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={record.isClosed}
                onClick={() => onClickClosed(record.id, record.isClosed)}
                loading={isLoading}
                />
                <Button key={'contactusdetailbtn_' + index} 
                    type='link'
                    className='px-5'
                    onClick={() => showContactUsDetail(record)}>
                    Detail
                </Button>
            </Space>
            </>
          ),
        },
    ];

    return (
        <>
            <Card title='Contact Us' bordered={false}>
                <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                    <Row gutter={16}>
                        <Col span={6} offset={6}>
                            <Form.Item
                                name='name'
                                className=' text-light w-100'
                                label='Name'
                                rules={[{ required: false }]}
                            >
                                <Input placeholder='Name'/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name='email'
                                className=' text-light w-100'
                                label='Email'
                                rules={[{ required: false }]}
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={3} />
                        <Col span={6}>
                            <RadioEvent
                            name="isClosed"
                            label="Customer Type"
                            options={options}
                            />
                        </Col>
                        <Col span={15} />
                    </Row>
                    {/* <Row gutter={16}>
                          <Col span={6} offset={6}>
                            <Form.Item
                                name='isClosed'
                                className=' text-light w-100'
                                label='Closed'
                                rules={[{ required: false }]}
                            >
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}/>
                    </Row> */}
                    <Row justify='end'>
                        <Col span={24} className='text-right'>
                            <Space>
                                <Button type='primary' className='px-5' htmlType='submit'>
                                    Search
                                </Button>
                                <Button type='default' className='px-5' onClick={() => onReset()}>
                                    Clear
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    className='mt-4'
                    columns={columns}
                    dataSource={contactUs}
                    scroll={{ x: true }}
                    pagination={false} size="small" />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>

            <Modal closable
                open={isDetailModalOpen === true}
                title="Contact Us detail"
                centered
                onCancel={() => setIsDetailModalOpen(false)}
                destroyOnClose={true}
                footer={[]}
                width={500}
            >
                <Row
                    gutter={16}
                    className='mt-4'>
                    <Col span={6}>Date</Col>
                    <Col span={18}>: {moment(contactUsDetail?.contactAt).format(DateFormat.DateTime)}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Name</Col>
                    <Col span={18}>: {contactUsDetail?.firstname} {contactUsDetail?.lastname}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Message</Col>
                    <Col span={18}>: {contactUsDetail?.message}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Email</Col>
                    <Col span={18}>: {contactUsDetail?.email}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Phone number</Col>
                    <Col span={18}>: {contactUsDetail?.phone}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Job Title</Col>
                    <Col span={18}>: {contactUsDetail?.jobTitle ?? '-'}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Company</Col>
                    <Col span={18}>: {contactUsDetail?.company ?? '-'}</Col>
                </Row>
                <Row
                    gutter={16}
                    className='mt-2'>
                    <Col span={6}>Country</Col>
                    <Col span={18}>: {contactUsDetail?.country ?? '-'}</Col>
                </Row>
            </Modal>
        </>
    );
}
