import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { ContactUsSearchModel } from '../models/contactUsModel';

export class ContactUsService {
  private api = 'Contact';

  getList(page: number, size: number, request: ContactUsSearchModel): Promise<AxiosResponse> {
    const params = {
        'page': page,
        'size': size,
        'isClosed': request.isClosed,
        'name': request.name,
        'email': request.email,
        'date': request.date,
      }
    return Axios.get(`${this.api}`, { params });
  }

  updateContactClosed(id: string, isClosed: boolean): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/${id}/${isClosed ? `open` : `close`}`);
  }
}