import React from 'react';
import Sider from './Sider';
import Header from './Header';
import { Layout, theme } from 'antd';
import { Outlet } from 'react-router-dom';
import GuardedRoute from '../Route/GuardRoute';
import { useLocation } from 'react-router';
import BreadcrumbLayOut from './Breadcrumb';

const { Content } = Layout;

interface Props {
  forceAuthentication?: boolean;
}

const LayoutContent: React.FC<Props> = ({forceAuthentication = false}: Props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const location = useLocation();

  return (
    <GuardedRoute forceAuthentication={forceAuthentication}>
      <Layout className="layout-container">
        <Header />
        <Layout className="site-layout">
          <Sider colorBgContainer={colorBgContainer} currentUrl={location.pathname} />
          <Content className="px-4">
            <BreadcrumbLayOut  currentUrl={location.pathname} />
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </GuardedRoute>
  );
};

export default LayoutContent;