import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { PayoutRequestSearchModel } from '../models/payoutRequestModel';

export class PayoutRequestService {
    private api = 'admin/artists/payout';

    getListAsync(page: number, size: number, request: PayoutRequestSearchModel): Promise<AxiosResponse> {
        const param = {
            'Paging.Page': page,
            'Paging.Size': size,
            Status: request.status,
            RequestNo: request.requestNo,
            ArtistCode: request.artistCode,
            Email: request.email,
            ArtistName: request.artistName,
            'RequestDate.Start': request.requestStartDate,
            'RequestDate.End': request.requestEndDate,
        };

        return Axios.get(`${this.api}`, { params: param });
    }

    getId(id: string): Promise<AxiosResponse> {
        return Axios.get(`${this.api}/${id}`);
    }

    onApprove(id: string, amount: number): Promise<AxiosResponse> {
        const request = {
            amount: amount,
            payoutInfoId: id,
        };
        return Axios.post(`${this.api}/${id}/approved`, JSON.stringify(request));
    }

    onCompletedManual(id: string, amount: number, attachmentUrl: string): Promise<AxiosResponse> {
        const request = {
            amount: amount,
            attachmentUrl: attachmentUrl,
            payoutInfoId: id,
        };
        return Axios.post(`${this.api}/${id}/completed-manual`, JSON.stringify(request));
    }

    onRejected(id: string): Promise<AxiosResponse> {
        return Axios.post(`${this.api}/${id}/rejected`);
    }

    onSaveTaxFile(id: string, attachmentUrl: string): Promise<AxiosResponse> {
        return Axios.put(`${this.api}/${id}/tax-attachment?taxAttachementUrl=${attachmentUrl}`);
    }

    onDeleteTaxFile(id: string): Promise<AxiosResponse> {
        return Axios.delete(`${this.api}/${id}/tax-attachment-delete`);
    }

    onSaveAttachmentUrlFile(id: string, attachmentUrl: string): Promise<AxiosResponse> {
        return Axios.put(`${this.api}/${id}/attachment?attachmentUrl=${attachmentUrl}`);
    }

    onDeleteAttachmentUrlFile(id: string): Promise<AxiosResponse> {
        return Axios.delete(`${this.api}/${id}/attachment-delete`);
    }

    ExportCsv(request: PayoutRequestSearchModel): Promise<AxiosResponse> {
        const params = {
            RequestNo: request.requestNo,
            ArtistCode: request.artistCode,
            Email: request.email,
            ArtistName: request.artistName,
            'RequestDate.Start': request.requestStartDate,
            'RequestDate.End': request.requestEndDate,
        };

        return Axios.get(`${this.api}/export`,
        {
          params,
          responseType: 'blob'
        });
    }
}
