import { Card, Row, Col, Form, Space, message } from 'antd';
import Button from '../../components/General/Button';
import { HttpStatus } from '../../constant/responseStatus';
import { AccountService } from '../../services/accountService';
import InputPassword from '../../components/Forms/InputPassword';
import IChangePasswordRequest from '../../models/request/changePasswordRequestModel';
import { useSessionContext } from '../../context/session-context';

const Orders: React.FC = () => {
  const accountService = new AccountService();
  const [ form ] = Form.useForm();
  const { signOut } = useSessionContext();

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = async (data: any) => {
    const changePasswordModel: IChangePasswordRequest = data as IChangePasswordRequest;

    if (changePasswordModel.newPassword !== changePasswordModel.confirmNewPassword) {
      message.error('Password is incorrect.');

      return;
    }

    const responseFacebook = await accountService.updatePasswordAsync(data);

    if (responseFacebook.status !== HttpStatus.OK) {
      message.error('Change password failed.');

      return;
    }

    message.success('Change password success.');
    form.resetFields();
    signOut();
  };

  return (
    <>
      <Card title="Change Password" bordered={false}
            extra={
              <Space align="center">
                <Button
                  type="primary"
                  text="Submit"
                  onClick={() => form.submit()}
                />
              </Space>
            }>
        <Card className="card-form">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <InputPassword
                  placeholder="Current Password"
                  name="CurrentPassword"
                  label="Current Password"
                  rules={[ {required: true, message: 'Please input your message!'} ]}
                />
              </Col>
              <Col span={9} />
            </Row>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <InputPassword
                  placeholder="New Password"
                  name="NewPassword"
                  label="New Password"
                  rules={[ {required: true, message: 'Please input your message!'} ]}
                />
              </Col>
              <Col span={3} />
              <Col span={6}>
                <InputPassword
                  placeholder="Confirm New Password"
                  name="ConfirmNewPassword"
                  label="Confirm New Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('NewPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </>
  );
};

export default Orders;