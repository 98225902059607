import CustomerFormDetail from '../../components/CustomerDetail/CustomerDetail';
import { CustomerTab } from '../../constant/constants';

const CustomerDetail: React.FC = () => {
  return (
    <>
      <CustomerFormDetail defaultTab={CustomerTab.Individual} />
    </>
  );
};

export default CustomerDetail;