import { Table } from 'antd';

export interface TableDataType { }

interface Props {
  columns: any;
  dataSource: TableDataType[];
  className?: string;
}

export const Status = (e: string) => {
  switch (e) {
    case 'Completed':
      return 'text-success';
    case 'Pending':
      return 'text-muted';
    case 'Approved':
      return 'text-primary';
    case 'Rejected':
      return 'text-danger';
    case 'Active':
      return 'text-success';
    case 'Inactive':
      return 'text-muted';
    default:
      return 'text-danger';
  }
}

function Tables({ columns, dataSource, className }: Props) {
  return (
    <Table columns={columns} dataSource={dataSource} className={className} scroll={{x: true}} pagination={false} />
  )
};

export default Tables;