import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import {
  CollectionAssetCreatingModel,
  CollectionAssetItemCreatingModel,
  CollectionModel,
  CollectionSearchModel,
} from '../models/CollectionModel';

export class CollectionService {
  private api = 'assetCollection';

  getIdAsync(id: string): Promise<AxiosResponse<CollectionModel>> {
    return Axios.get<CollectionModel>(`${this.api}/${id}`);
  }

  getListAsync(page: number, size: number, request: CollectionSearchModel): Promise<AxiosResponse> {
    const params = {
      'Paging.Page': page,
      'Paging.Size': size,
      Name: request.name,
      Email: request.email,
      ArtistName: request.artistName,
    };
    return Axios.get(`${this.api}`, { params });
  }

  CreateCollection(collection: CollectionAssetCreatingModel): Promise<AxiosResponse<CollectionModel>> {
    return Axios.post<CollectionModel>(`${this.api}`, JSON.stringify(collection));
  }

  addCollectionItem(
    collectionId: string,
    data: CollectionAssetItemCreatingModel
  ): Promise<AxiosResponse<CollectionModel>> {
    return Axios.post<CollectionModel>(`${this.api}/${collectionId}/item`, JSON.stringify(data));
  }

  DeleteCollectionItem(collectionId: string, itemId: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${collectionId}/CollectionAssetItem/${itemId}`);
  }

  UpdateCollection(collection: CollectionModel): Promise<AxiosResponse<CollectionModel>> {
    return Axios.put<CollectionModel>(`${this.api}/${collection.id}`, JSON.stringify(collection));
  }

  DeleteCollection(collectionId: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${collectionId}`);
  }
}
