import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import stringFormat from '../../helper/stringFormat';

const BreadcrumbLayOut = (props: { currentUrl: string }) => {
  const pathNameWithOutGuid = stringFormat.regexGuidInPath(props.currentUrl);

  const pathNames = pathNameWithOutGuid.split('/').filter((x) => x);

  const breadcrumbs = pathNames.map((pathname, index) => {
    const url = `/${pathNames.slice(0, index + 1).join('/')}`;
    const isLast = index === pathNames.length - 1;

    return (
      <Breadcrumb.Item key={index}>
        {isLast ? (
          <span>{stringFormat.convertPathName(pathname)}</span>
        ) : (
          <Link to={url}>{stringFormat.convertPathName(pathname)}</Link>
        )}
      </Breadcrumb.Item>
    );
  });

  return (
    <>
      <Breadcrumb style={{ margin: '16px 0' }}>
        {/* <Breadcrumb.Item>
          <Link to={`/dashboard`}>Home</Link>
        </Breadcrumb.Item> */}
        {breadcrumbs}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbLayOut;