import { HttpStatus } from '../../constant/responseStatus';
import { ResponseSignIn, SignInModel } from '../../models/accountModel';
import { useCallback, useEffect, useState } from 'react';
import auth from '../../helper/Authentication';
import { alertType } from '../../helper/alert';
import { SessionContext } from '../../context/session-context';
import { SessionStatus } from '../../constant/sessionStatus';
import sessionStore from '../../store/sessionStore';
import { Session } from '../../constant/sesstion';
import { Constants } from '../../constant/constants';
import { UserProfile } from '../../models/response/userProfile';
import { AccountService } from '../../services/accountService';
import { HttpStatusCode } from 'axios';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export default function SessionComponent({ children }: IProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(SessionStatus.LOADING);
  const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);

  const validateTokenExpire = useCallback(() => {
    const dateNow = new Date();
    const expireToken = sessionStore.get(Session.EXPIRE_SESSION);
    const accessToken = sessionStore.get(Session.SESSION_ACCESS_TOKEN);

    if (!accessToken) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    if (!expireToken) {
      auth.signOut();
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    const expireDate = new Date(Number(expireToken));

    if (dateNow.getTime() >= expireDate.getTime()) {
      auth.signOut();
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    setIsAuthenticated(SessionStatus.AUTHENTICATED);
  }, []);

  const signOut = useCallback(() => {
    auth.signOut();
  }, []);

  const signInAsync = useCallback(async ({ username, password, deviceCode }: SignInModel) => {
    const data: SignInModel = {
      username, password, deviceCode,
    };

    const response = await auth.signInAsync(data);

    if (response.status !== HttpStatus.OK) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return response;
    }

    setIsAuthenticated(SessionStatus.AUTHENTICATED);
    return { type: alertType.SUCCESS, msg: Constants.stringEmpty, status: HttpStatus.OK } as ResponseSignIn;
  }, []);

  const getUserProfileAsync = useCallback(async () => {
    const { data, status } = await new AccountService().getUserProfileAsync();

    if (status === HttpStatusCode.Ok) {
      setUserProfile(data);
      sessionStore.set(Session.SESSION_USER, data);
    }
  }, []);

  useEffect(() => {
    validateTokenExpire();
  }, [validateTokenExpire]);

  return (
    <SessionContext.Provider value={{
      signInAsync,
      validateTokenExpire,
      isAuthenticated,
      signOut,
      getUserProfileAsync,
      userProfile,
    }}>
      {children}
    </SessionContext.Provider>
  );
}