import { Select } from 'antd';
import { useEffect, useState } from 'react';
import SubDistrict from '../../constant/SubDistrict.json';

const { Option } = Select;

interface SubDistrictProps {
  postCode: string;
  districtID: number;
  provinceID: number;
  subDistrictID: number;
  sendDistrictID: (id: number, subId: number) => void;
  sendPostCode: (value: string) => void;
  sendSubDistrictID: (id: number) => void;
  className: string;
}

export default function SelectSubDistrict({ postCode, districtID, provinceID, subDistrictID, sendDistrictID, sendPostCode, sendSubDistrictID, className }: SubDistrictProps) {
  const [subDistrict, setSubDistrict] = useState(0);
  const [zipCode, setZipCode] = useState(postCode);

  useEffect(() => {setZipCode('')}, [districtID])

  useEffect(() => {
    if (postCode) {
      SubDistrict.every((data) => {
        if (data.postCode === postCode.toString() && data.districtID === districtID && !subDistrictID) {
          setSubDistrict(data.id);
          setZipCode(postCode);

          return false;
        }

        if(subDistrictID){
            setSubDistrict(subDistrictID);
        }

        return true;
      });
    }
  }, [postCode, districtID, SubDistrict]);

  const handleChange = (value: number) => {
    setSubDistrict(value);
    if (zipCode === '0' || zipCode === '') {
      SubDistrict.forEach((data) => {
        if (data.id === value) {
          sendPostCode(data.postCode);

          return;
        }
      });
    }

    sendSubDistrictID(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      size="large"
      className={className}//"input-radius-dark non-radius"
      value={subDistrict}
      onSelect={(value: number) => handleChange(value)}
    >
      <Option value={0}>
        Select SubDistrict
      </Option>
      {
        SubDistrict.map((data, index) => data.districtID === districtID && (
          <Option value={data.id} key={`${data.id}-${index}`}>
            {data.name}
          </Option>
        ))
      }
    </Select>
  );
}
