import { DatePicker, Form } from 'antd';
const { RangePicker } = DatePicker;

interface Props {
  Ipicker?: "week" | "month" | "quarter" | "year";
  Iname?: string;
  Ilabel?: string;
}

function InputRangePicker({ Ipicker, Iname, Ilabel }: Props) {
  return (
    <Form.Item name={Iname ?? ''} label={Ilabel}>
      <RangePicker size='large' className='w-100' />
    </Form.Item >
  )
};

export default InputRangePicker;