export const enum MasterDataType {
  CAMERA_TYPE = 0,
  CAMERA_BRAND = 1,
  ETHNIC = 2,
  KEYWORD = 3,
  STYLE_OF_WORK = 4,
  PLATFORM = 5,
  AssetRejectProperty = 6,
  AssetRejectAppropriateness = 7,
  AssetRejectQuality = 8,
  ReportAsset = 9
};