import { Card, Typography, Space } from 'antd';
import Table, { TableDataType, Status } from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { VscCircleFilled } from "react-icons/vsc";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { AssetRequestModel } from '../../models/assetRequestModel';
import { AssetRequestService } from '../../services/assetRequestService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';

const { Paragraph } = Typography;

interface TableData extends TableDataType {
  key: React.Key;
  imges: string;
  asset: string;
  status: string;
  action: any;
}

const columns: ColumnsType<TableData> = [
  {
    title: '',
    dataIndex: 'imges',
    align: 'center',
    render: (text) => <img src={text} width={50} height={50} style={{objectFit: "cover", pointerEvents: "none"}} />,
  },
  {
    title: 'Asset',
    dataIndex: 'asset',
    align: 'center',
    render: (text) => <p className='text-left'>{text}</p>,
    width: '70%'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'left',
    render: (text) => <p className='text-left'><VscCircleFilled className={`${Status(text)} me-2`} />{text}</p>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text) => <Link key={text} to={text}>Review</Link>,
    align: 'center',
  },
];

function AssetDetail() {
  const [searchParams] = useSearchParams();
  const [assetRequest, setAssetRequest] = useState<AssetRequestModel>();
  const [dataTable, setDataTable] = useState<TableData[]>([]);

  useEffect(() => {
    const assetRequestId = searchParams.get('id');

    if (assetRequestId) {
      getAssetRequestItemsByIdAsync(assetRequestId);
    }
  }, [searchParams]);

  const getAssetRequestItemsByIdAsync = async (id: string) => {
    const { data, status } = await new AssetRequestService().getAsync(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    const dataTable: TableData[] = data.items.map(ari => (
      {
        key: ari.id,
        imges: ari.imageThumbnailUrl,
        asset: ari.name,
        status: ari. status,
        action: `${RouteNames.assetRequests}/review?id=${ari.id}`,
      }
    ));

    setAssetRequest(data);
    setDataTable(dataTable);
  };

  const requestDate = new Date(assetRequest?.requestDate!).toLocaleString("th-TH");

  return (
    <>
      <Card className='card-customize'>
        <div className='card-head space-align-block p-4'>
          <Space align="center">
            <Typography.Title level={5} className='mb-0'> {assetRequest?.requestNo} </Typography.Title>
            <Paragraph className='mb-0 text-dark-50'>by {assetRequest?.artistName} on {requestDate}</Paragraph>
          </Space>
          <div className='flex-center'>
            <VscCircleFilled className={`${Status(assetRequest?.status!)}`} />
            <Paragraph className='mb-0 ms-1'>{assetRequest?.status}</Paragraph>
          </div>
        </div>
        <div className='hr' />
        <Table className="p-4" columns={columns} dataSource={dataTable} />
      </Card>
    </>
  );
}

export default AssetDetail;