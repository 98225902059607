import { Card, Row, Col, Form, Space, Input, Button, DatePicker, Pagination } from 'antd';
import Table from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { EventlogModel, EventlogSearchModel } from '../../models/eventlogModel';
import { EventlogsService } from '../../services/eventlogsService';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';

const { RangePicker } = DatePicker;

const columns: ColumnsType<EventlogModel> = [
    {
        title: 'Event Date',
        dataIndex: 'createdAt',
        align: 'center',
        render: (value, record, index) => (
            <>{record.createdAt && moment(record.createdAt).format(DateFormat.DateTime)}</>
        ),
    },
    {
        title: 'Admin',
        dataIndex: 'admin',
        align: 'center',
        width: 300,
    },
    {
        title: 'Program Name',
        dataIndex: 'programName',
        align: 'center',
        width: 300,
        render: (text) => <p className='text-left'>{text}</p>,
    },
    {
        title: 'Message',
        dataIndex: 'message',
        align: 'center',
        render: (text) => <p className='text-left'>{text}</p>,
    },
];

export default function Eventlogs() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const [eventlogs, setEventlogs] = useState<EventlogModel[]>([]);

    useEffect(() => {
        onGetListEventlogs(page, size);
    }, []);

    const onFinish = () => {
        onGetListEventlogs(page, size);
    };

    const onReset = () => {
        form.resetFields();
        onGetListEventlogs(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListEventlogs(page, size);
    };


    const onGetListEventlogs = async (page: number, size: number) => {
        const cDate = form.getFieldValue('eventDate');

        const request: EventlogSearchModel = {
            query: form.getFieldValue('query'),
            from: cDate ? new Date(cDate[0]) : undefined,
            to: cDate ? new Date(cDate[1]) : undefined,
        };

        const { data, status } = await new EventlogsService().getListAsync(page, size, request);

        if (status !== HttpStatus.OK) {
            return;
        }

        setEventlogs(data.data);
        setTotalRow(data.totalRecords);
    };

    return (
        <>
            <Card title='Event Log' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row gutter={16}>
                            <Col span={6} offset={6}>
                                <Form.Item
                                    name='query'
                                    className=' text-light w-100'
                                    label='Keyword'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Keyword' size='large' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='eventDate'
                                    className=' text-light w-100'
                                    label='Event Date'
                                    rules={[{ required: false }]}
                                >
                                    <RangePicker size='large' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Table className='mt-4' columns={columns} dataSource={eventlogs} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
