import {
  Card,
  Row,
  Col,
  Form,
  Space,
  Typography,
  Divider,
  Upload,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
} from 'antd';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from '../../constant/uploadFileConstants';
import { StringConstants } from '../../constant/stringConstants';
import { S3Constants } from '../../constant/s3Constants';
import AWS from 'aws-sdk';
import {
  BannerCreatingModel,
  BannerItemCreatingModel,
  BannerItemModel,
  BannerModel,
  BannerlistCropModel,
} from '../../models/bannerModel';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

AWS.config.update({
  accessKeyId: S3Constants.ACCESS_KEY_ID,
  secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
});

const S3_BUCKET = S3Constants.BUCKET;
const S3_FileName = S3Constants.FILE_NAME_FORMAT;
const REGION = S3Constants.REGION;
const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function BannerDetail() {
  const [form] = Form.useForm();
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileVideoList, setFileVideoList] = useState<UploadFile[]>([]);
  const [itemCrops, setItemCrops] = useState<BannerlistCropModel[]>([]);
  const [bannerItems, setBannerItems] = useState<BannerItemCreatingModel[]>([]);
  const [searchParams] = useSearchParams();
  const [bannerId, setBannerId] = useState('');
  const [banner, setBanner] = useState<BannerModel>({} as BannerModel);
  const { confirm } = Modal;

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setBannerId(id);
      onGetBanner(id);
    }
  }, [searchParams]);

  const onGetBanner = async (id: string) => {
    const { data, status } = await new BannerService().getId(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setBanner(data);
    form.setFieldValue('name', data.name);
    form.setFieldValue('startDate', dayjs(data.startDate));
    if (data.endDate) {
      form.setFieldValue('endDate', dayjs(data.endDate));
    }
    setBannerId(data.id);

    if (data.bannerItems && data.bannerItems.length > 0) {
      let fileDatas: UploadFile[] = [];

      var bannerItems: BannerItemModel[] = data.bannerItems;

      bannerItems.forEach((data: BannerItemModel) => {
        const fileData: UploadFile = {
          uid: data.id!,
          name: data.mediaUrl,
          status: 'done',
          url: data.mediaUrl,
        };

        fileDatas.push(fileData);
      });

      setFileList(fileDatas);
    }
  };

  const onDeleteItem = async (id: string) => {
    const { status } = await new BannerService().deleteBannerItem(bannerId, id);

    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete item failed.');
      return;
    }

    message.success('Delete item success.');
    window.location.reload();
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onChangeVideo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileVideoList(newFileList);
  };

  const beforeUpload = async (file: UploadFile) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG or PNG file!');
      return false;
    } else {
      return true;
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadVideoProps: UploadProps = {
    listType: UploadListType.TEXT,
    accept: 'video/mp4',
    onChange: onChangeVideo,
    fileList: fileVideoList,
    async onRemove(fileInfo) {
      const filename = S3_FileName + fileInfo.response;
      const newData = bannerItems.filter((x) => x.MediaUrl !== filename);
      setBannerItems(newData);
      deleteFileFromS3(uploadFileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadProps: UploadProps = {
    listType: UploadListType.PICTURE_CARD,
    accept: UploadFileConstants.MIME_ACCEPT.toString(),
    fileList: fileList,
    async onRemove(fileInfo) {
      if (itemCrops && itemCrops.filter((i) => i.originalName === fileInfo.name).length > 0) {
        const delData = itemCrops.filter((i) => i.originalName === fileInfo.name)[0];
        const newData = bannerItems.filter((x) => x.MediaUrl !== delData.MediaUrl);
        setBannerItems(newData);
        deleteFileFromS3(delData.MediaUrl);
      }

      if (bannerId && banner && banner.bannerItems) {
        if (banner.bannerItems.filter((x) => x.id === fileInfo.uid).length > 0) {
          onDeleteItem(fileInfo.uid);
          const delData = itemCrops.filter((i) => i.originalName === fileInfo.name)[0];
          const newData = bannerItems.filter((x) => x.MediaUrl !== delData.MediaUrl);
          setBannerItems(newData);
          deleteFileFromS3(delData.MediaUrl);
        }
      }
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, onError, onSuccess, onProgress);
    },
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const addFileToS3 = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setBannerItems([...bannerItems, { MediaUrl: filePath }]);
    setUploadFileName(fileName);
    setItemCrops([...itemCrops, { originalName: originalFile.name, MediaUrl: filePath }]);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      StringConstants.UNDERSCORE +
      'newsContent' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const onFinish = (value: any) => {
    let startDate;
    let endDate;
    if (value.startDate) {
      startDate = new Date(value.startDate);
    }

    if (value.endDate) {
      endDate = new Date(value.endDate);
    }

    const request: BannerCreatingModel = {
      Name: value.name,
      StartDate: new Date(value.startDate),
      EndDate: endDate,
      BannerItems: bannerItems,
    };

    if (bannerId && banner) {
      let dataItem: BannerItemModel[] = banner.bannerItems.map((data) => ({
        id: data.id,
        mediaUrl: data.mediaUrl,
      }));

      let newData: BannerItemModel[] = bannerItems.map((data) => ({
        id: undefined,
        mediaUrl: data.MediaUrl,
      }));

      dataItem = [...dataItem, ...newData];

      let updBanner: BannerModel = {
        id: bannerId,
        name: value.name,
        startDate: banner.startDate,
        endDate: endDate,
        bannerItems: dataItem,
      };

      onUpdate(updBanner);
    } else {
      onCreate(request);
    }
  };

  const onCreate = async (request: BannerCreatingModel) => {
    const { data, status } = await new BannerService().createBanner(request);

    if (status !== HttpStatus.OK) {
      message.error('Save banner failed.');
      return;
    }

    message.success('Save banner success.');
    window.location.href = `${RouteNames.bannerDetail}?id=${data.id}`;
  };

  const onUpdate = async (request: BannerModel) => {
    const { data, status } = await new BannerService().updateBanner(bannerId, request);
    
    window.location.reload();

    if (status !== HttpStatus.OK) {
      message.error('Save banner failed.');
      
      return;
    }

    message.success('Save banner success.');
    window.location.href = `${RouteNames.bannerDetail}?id=${data.id}`;
  };

  const Delete = (id: string) => {
    confirm({
      title: `Do you want to delete ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onDelete(id);
      },
    });
  };

  const onDelete = async (id: string) => {
    const { status } = await new BannerService().deleteBanner(id);

    if (status !== HttpStatus.OK && status !== HttpStatus.NO_CONTENT) {
      message.error('Delete banner failed.');
      return;
    }

    message.success('Delete banner success.');
    window.location.reload();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
      >
        <Card
          className='card-customize'
          title={
            <Space align='center'>
              <Typography.Title
                level={5}
                className='mb-0'
              >
                Banner Detail
              </Typography.Title>
            </Space>
          }
          extra={
            <Space align='center'>
              <Button
                type='primary'
                danger
                onClick={() => Delete(bannerId)}
              >
                Delete
              </Button>
              <Button
                type='primary'
                htmlType='submit'
              >
                Save
              </Button>
            </Space>
          }
        >
          <div className='p-4'>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={12}
              >
                <Form.Item
                  name='name'
                  className='text-light w-100'
                  label='Banner Name'
                  rules={[{ required: true, message: 'Please input your subject!' }]}
                >
                  <Input className='w-100' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='startDate'
                  className='text-light w-100'
                  label='Start Date'
                  rules={[{ required: true, message: 'Please input your subject!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                  />
                </Form.Item>
              </Col>
              <Col
                className='gutter-row'
                span={6}
              >
                <Form.Item
                  name='endDate'
                  className='text-light w-100'
                  label='End Date'
                  rules={[{ required: false, message: 'Please input your subject!' }]}
                >
                  <DatePicker
                    showTime
                    className='w-100'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider
              orientation='left'
              plain
            >
              New Image Upload (Ratio 10:4)
            </Divider>
            <Row gutter={20}>
              <Col
                className='gutter-row'
                span={24}
              >
                <ImgCrop
                  rotationSlider
                  showGrid={true}
                  aspect={10 / 4}
                  beforeCrop={beforeUpload}
                  quality={1}
                >
                  <Upload
                    onChange={onChange}
                    onPreview={onPreview}
                    {...uploadProps}
                  >
                    {fileList.length < 10 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </Col>
            </Row>
            <Divider
              orientation='left'
              plain
            >
              New Video Upload (Ratio 10:4)
            </Divider>
            <Row gutter={20}>
              <Col
                className='gutter-row'
                span={24}
              >
                <Upload {...uploadVideoProps}>
                  <Button icon={<UploadOutlined />}>Click to Upload Video</Button>
                </Upload>
              </Col>
            </Row>
            {bannerItems && bannerItems.map((x) => <>{x.MediaUrl}</>)}
          </div>
        </Card>
      </Form>
    </>
  );
}
