import React, { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { Button, Input, Form, Card, message } from 'antd';
import { useSessionContext } from '../context/session-context';
import { SignInModel } from '../models/accountModel';
import { useNavigate } from 'react-router-dom';
import { HttpStatus } from '../constant/responseStatus';
import { RouteNames } from '../components/Route/routeName';
import { RoleType } from '../constant/typeConstants';

export default function Login() {
  const { signInAsync, userProfile, getUserProfileAsync } = useSessionContext();
  const navigate = useNavigate();
  const imageBg = require('../asset/images/bg-header.jpg');
  const [loading, setLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinishAsync = async (values: any) => {
    setLoading(true);
    const signModel: SignInModel = {
      username: values.email,
      password: values.password,
      deviceCode: '',
    };

    const { status } = await signInAsync(signModel);

    if (status !== HttpStatus.OK) {
        messageApi.open({
            type: 'error',
            content: 'Login failed.',
          });
        setLoading(false);
        return;
    }

    messageApi.open({
        type: 'success',
        content: 'Login suucess.',
      });
    setLoading(false);
    getUserProfileAsync();
  };

  useEffect(() => {
    if(userProfile && userProfile.roles){
      if(userProfile.roles.filter(r => r === RoleType.Finance).length > 0) {
        navigate(RouteNames.payoutRequest);
      }
      else{
        navigate(RouteNames.dashboard);
      }
    }
  }, [userProfile]);

  return (
    <>
    {contextHolder}
    <Content
      className="p-4 layout-login"
      style={{ backgroundImage: imageBg, objectFit: 'cover' }}
    >
      <Card>
        <div>
          <img className="logo" src="../images/loginLogo.png" width={200} alt="alivelogo" />
        </div>
        <p className="description">{'Welcome to Admin'}</p>
        <Form
          name="basic"
          style={{ width: 300 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          className="mt-4"
          onFinish={onFinishAsync}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Email" size='large' />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" size='large' />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType="submit" size='large' style={{height: '50px'}} block loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
    </>
  );
}