import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IOrderHistoryResponse } from '../../../../models/response/orderHistoryResponse';
import { OrderModel } from '../../../../models/OrderModel';

interface ReportInterface {
  title: string;
  order: OrderModel;
}

const styles = StyleSheet.create({
  container:{
      borderTop: 1,
      marginBottom: 0,
  },
  margin: {
    marginTop: 5,
  },
  title1: {
    textAlign: 'left',
    fontWeight: 'black',
    fontSize: 16,
  },
  text: {
    textAlign: 'left',
    fontSize: 14,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
});

export default function Title({ title, order } : ReportInterface) {

  return (
    <View style={styles.container}>
        <View style={styles.margin}>
          <Text style={styles.title1}>{title}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.title1}>Order Number : </Text>
            <Text style={styles.text}>{order.code}</Text>
        </View>
    </View>
  );
}
