import { RouteNames } from "../components/Route/routeName";

declare global {
  interface String {
    toSafeString(): string;
  }
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const regexGuidInPath = (text: string) => {
  const pattern = /\b[A-Fa-f0-9]{8}(?:-[A-Fa-f0-9]{4}){3}-[A-Fa-f0-9]{12}\b/g;

  return text.replace(pattern, "");
}

function concatStringWithSpace(string1: string, string2: string) {
  if (!string1 && !string2) {
    return '';
  }

  if (!string1) {
    return string2;
  }

  if (!string2) {
    return string1;
  }

  return string1 + ' ' + string2;
}

String.prototype.toSafeString = function(): string {
  const safeString: string = this.toString();

  if (!safeString) {
    return '';
  }

  return safeString;
}

const paymentMethod = (type: string) => {
  switch (type) {
      case 'Creditcard':
          return 'Credit Card';
      case 'BankTransfer':
        return 'Bank Transfer (QR Code)';
      case 'PaymentTerm':
        return 'Payment Term';
      case 'Manual':
        return 'Manual';
      default:
        return '';
  }
}

function convertPathName(type: string):string {
  var method:string = type;

  switch (`/${type}`) {
      case RouteNames.dashboard:
      method = 'Dashboard';
      break;
      case RouteNames.assetRequests:
          method = 'Asset Request';
          break;
      case RouteNames.recommendAsset:
          method = 'Recommend Asset';
          break;
      case RouteNames.recommendCollection:
          method = 'Recommend Collection';
          break;
      case RouteNames.newsContent:
          method = 'News Content';
          break;
      case RouteNames.reportAsset:
          method = 'Report Asset';
          break;
      case RouteNames.contactUs:
          method = 'Contact Us';
          break;
      case RouteNames.payoutRequest:
          method = 'Payout Request';
          break;
      case RouteNames.eventlogs:
        method = 'Event Log';
        break;
      default:
          method = type;
          break;
  }

  return method;
}

export default {
  capitalizeFirstLetter,
  regexGuidInPath,
  concatStringWithSpace,
  paymentMethod,
  convertPathName,
}