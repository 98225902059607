import { Card, Row, Col, Form, Space, Pagination } from 'antd';
import Input from '../../components/Forms/Input';
import InputRangePicker from '../../components/Forms/RangePicker';
import RadioEvent from '../../components/Forms/RadioEvent';
import Button from '../../components/General/Button';
import Table, { Status } from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { VscCircleFilled } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { AssetStatus } from '../../constant/assetConstants';
import {
    AssetRequestModel,
    AssetRequestPagingModel,
    AssetRequestSearchModel,
    PagingSearchModel,
} from '../../models/assetRequestModel';
import { useEffect, useState } from 'react';
import { AssetRequestService } from '../../services/assetRequestService';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';

const assetRequestStatusOptions = [
    { label: 'All', value: '' },
    {
        label: AssetStatus.PENDING,
        value: AssetStatus.PENDING,
    },
    {
        label: AssetStatus.COMPLETED,
        value: AssetStatus.COMPLETED,
    },
];

const enum SearchFiled {
    REQUEST_NO = 'requestNo',
    EMAIL = 'email',
    ARTIST_NAME = 'artistName',
    REQUEST_DATE_RANGE = 'requestDateRange',
    STATUS = 'status',
}

const columns: ColumnsType<AssetRequestModel> = [
    {
        key: 'requestDate',
        title: 'Request Date',
        dataIndex: 'requestDate',
        align: 'center',
        render: (text) => <p className='text-center'>{new Date(text).toLocaleString('en-US')}</p>,
    },
    {
        key: 'requestNo',
        title: 'Request No.',
        dataIndex: 'requestNo',
        align: 'center',
    },
    {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
        render: (text) => <p className='text-left'>{text}</p>,
    },
    {
        key: 'artistName',
        title: 'Artist',
        dataIndex: 'artistName',
        align: 'center',
        render: (text) => <p className='text-left'>{text}</p>,
    },
    {
        key: 'assetQuantity',
        title: 'Asset Quantity',
        dataIndex: 'assetQuantity',
        align: 'center',
        render: (text) => <p className='text-right'>{text}</p>,
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (text) => (
            <p className='text-left'>
                <VscCircleFilled className={`${Status(text)} me-2`} />
                {text}
            </p>
        ),
    },
    {
        key: 'action',
        title: 'Action',
        dataIndex: 'action',
        render: (text) => <p>{text}</p>,
        align: 'center',
    },
];

const initPagingSize = 10;

const initPagingSearchModel: PagingSearchModel = {
    page: 1,
    size: initPagingSize,
};

const initSearchCriteria: AssetRequestSearchModel = {
    paging: initPagingSearchModel,
    status: '',
};

const initAssetRequestPaging: AssetRequestPagingModel = {
    paging: {
        totalRows: 0,
    },
    list: [],
};

function AssetRequests() {
    const [assetRequestSearch, setAssetRequestSearch] = useState<AssetRequestSearchModel>(initSearchCriteria);
    const [assetRequestPaging, setAssetRequestPaging] = useState<AssetRequestPagingModel>(initAssetRequestPaging);

    useEffect(() => {
        onGetAssetRequestDataAsync(assetRequestSearch);
    }, [assetRequestSearch]);

    const getActionLink = (assetId: string) => {
        const targetPath = `${RouteNames.assetRequests}/detail?id=${assetId}`;

        return (
            <Link key={assetId} to={targetPath}>
                Detail
            </Link>
        );
    };

    const onGetAssetRequestDataAsync = async (searchCriteria: AssetRequestSearchModel) => {
        const { data, status } = await new AssetRequestService().searchAsync(searchCriteria);

        if (status !== HttpStatus.OK) {
            return;
        }

        const dataTable: AssetRequestModel[] = data.list.map((ar) => ({
            id: ar.id,
            requestDate: ar.requestDate,
            requestNo: ar.requestNo,
            email: ar.email,
            artistName: ar.artistName,
            assetQuantity: ar.assetQuantity,
            status: ar.status,
            items: ar.items,
            action: getActionLink(ar.id),
            key: ar.requestNo,
        }));

        setAssetRequestPaging({ paging: data.paging, list: dataTable });
    };

    const [searchForm] = Form.useForm();

    const resetSearchForm = () => {
        searchForm.resetFields();
        onGetAssetRequestDataAsync(initSearchCriteria);
    };

    const onFinish = () => {
        generateSearchCriteria();
    };

    const onChangePage = (page: number, size: number) => {
        generateSearchCriteria(page, size);
    };

    const generateSearchCriteria = (page?: number, size?: number) => {
        const requestDateRange = searchForm.getFieldValue(SearchFiled.REQUEST_DATE_RANGE);

        const searchCriteria: AssetRequestSearchModel = {
            paging: {
                page: page ?? initPagingSearchModel.page,
                size: size ?? initPagingSearchModel.size,
            },
            status: searchForm.getFieldValue(SearchFiled.STATUS),
            email: searchForm.getFieldValue(SearchFiled.EMAIL),
            artistName: searchForm.getFieldValue(SearchFiled.ARTIST_NAME),
            requestNo: searchForm.getFieldValue(SearchFiled.REQUEST_NO),
            requestDate: {
                start: requestDateRange && requestDateRange[0] ? new Date(requestDateRange[0]) : undefined,
                end: requestDateRange && requestDateRange[1] ? new Date(requestDateRange[1]) : undefined,
            },
        };

        searchCriteria.requestDate?.start?.setHours(0, 0, 0);
        searchCriteria.requestDate?.end?.setHours(0, 0, 0);
        setAssetRequestSearch(searchCriteria);
    };

    return (
        <>
            <Card title='Asset Request' bordered={false}>
                <Card className='card-form'>
                    <Form form={searchForm} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <Input placeholder='Request No.' name={SearchFiled.REQUEST_NO} label='Request No.' />
                            </Col>
                            <Col span={3} />
                            <Col span={6}>
                                <InputRangePicker Iname={SearchFiled.REQUEST_DATE_RANGE} Ilabel='Request Date' />
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <Input placeholder='Email' name={SearchFiled.EMAIL} label='Email' />
                            </Col>
                            <Col span={3} />
                            <Col span={6}>
                                <Input placeholder='Artist Name' name={SearchFiled.ARTIST_NAME} label='Artist Name' />
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <RadioEvent
                                    name={SearchFiled.STATUS}
                                    label='Status'
                                    options={assetRequestStatusOptions}
                                />
                            </Col>
                            <Col span={15} />
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space wrap>
                                    <Button type='primary' text='Search' className='px-5' />
                                    <Button text='Clear' onClick={resetSearchForm} className='px-5' />
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row>
                    <Col span={24}>
                        <Table className='mt-4' columns={columns} dataSource={assetRequestPaging.list} />
                    </Col>
                    <Col span={24} className='text-right pt-3'>
                        <Pagination
                            defaultCurrent={1}
                            current={assetRequestSearch.paging.page}
                            total={assetRequestPaging.paging.totalRows}
                            pageSize={initSearchCriteria.paging.size}
                            onChange={onChangePage}
                        />
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default AssetRequests;
