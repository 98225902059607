
import { Input, Form } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ChangeEvent } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import * as React from 'react';

interface Props {
  readOnly?: boolean;
  placeholder?: string;
  prefix?: string;
  name?: string;
  label?: string;
  size?: SizeType;
  className?: string;
  suffix?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  rules?: Rule[];
  style?: React.CSSProperties;
  disabled?: boolean;
  type?: string;
}

function InputForm({ placeholder, prefix, name, label, size = 'large', disabled = false, className, suffix, onChange = () => '' ,
readOnly = false, rules = [{ required: false }], style, type}: Props) {
  return (
    <>
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          type={type}
          readOnly={readOnly}
          size={size}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          className={className}
          onChange={onChange}
          style={style}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};
export default InputForm;