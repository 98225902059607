import { Card, Row, Col, Form, Button, Table, Pagination, Input, Badge, DatePicker, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { RouteNames } from '../../components/Route/routeName';
import { AssetType } from '../../constant/assetConstants';
import { DownloadHistoryModel } from '../../models/DownloadHistoryModel';
import { RangePickerProps } from 'antd/es/date-picker';
import { AccountService } from '../../services/accountService';

const { RangePicker } = DatePicker;

export default function DownloadHistory() {
  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD';
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalRow, setTotalRow] = useState(0);
  const [assetCode, setAssetCode] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [downloadHistories, setDownloadHistory] = useState<DownloadHistoryModel[]>([]);

  const onSearchAsync = async () => {
    await getDownloadHistoryAsync(page, size, assetCode, email, startDate, endDate);
  };

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setStartDate(new Date(dateStrings[0]));
      setEndDate(new Date(dateStrings[1]));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const columns: ColumnsType<DownloadHistoryModel> = [
    {
      title: 'Download Date',
      key: 'downloadDate',
      dataIndex: 'downloadDate',
      align: 'center',
      width: 200,
      render: (value, record, index) =>
        <>{moment(record.downloadDate).format(DateFormat.DateTime)}</>,
    },
    {
      title: 'Customer',
      key: 'userId',
      dataIndex: 'userId',
      align: 'left',
      width: 200,
      render: (value, record, index) =>
        <Link to={`${RouteNames.customerDetail}/${record.userId}`}>{record.email}</Link>,
    },
    {
      title: 'Asset',
      align: 'center',
      key: 'id',
      render(value, record, index) {
        return (
          <Row
            wrap={false}
            align='middle'
            gutter={[16, 0]}>
            <Col flex='none'>
              {
                record.asset.assetType === AssetType.IMAGE
                ? 
                (
                  <div style={{ position: 'relative', height: 50, width: 50 }}>
                    <img
                      src={record.asset.thumbnailImageUrl}
                      style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                    />
                  </div>
                )
                : 
                (
                <div style={{ position: 'relative', height: 50, width: 50 }}>
                  <img
                    src={record.asset.thumbnailVideoCoverUrl}
                    style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                  />
                </div>
                )
              }
            </Col>
            <Col flex='auto'>
              <Link to={`${RouteNames.assetDetail}?id=${record.asset.id}`}>
                <Tooltip
                    title={
                      <>{record.asset.code}{record.asset.name && ('- ' + record.asset.name)}</>
                    }>
                    <span className='text-ellipsis-2r'>
                      {record.asset.code}{record.asset.name && ('- ' + record.asset.name)}
                    </span>
                </Tooltip>
              </Link>
            </Col>
          </Row>
        );
      },
      dataIndex: 'title',
    },
    {
      title: 'Standard Licenses',
      dataIndex: 'standardLicenses',
      key: 'standardLicenses',
      align: 'center',
      width: 200,
    },
    {
      title: 'Extend Licenses',
      dataIndex: 'extendLicenses',
      key: 'extendLicenses',
      align: 'center',
      width: 250,
      render: (value, record, index) => (record.extendLicenses !== 'VIP' ? record.extendLicenses : 'Comp'),
    },
    {
      title: 'DownloadType',
      dataIndex: 'downloadType',
      key: 'downloadType',
      align: 'center',
      width: 150,
      render: (value, record, index) => (
        <Badge
          color={record.extendLicenses !== 'VIP' ? '#1677ff' : '#fa8c16'}
          count={record.extendLicenses !== 'VIP' ? 'License' : 'Comp'}
        />
      ),
    },
  ];

  const getDownloadHistoryAsync = async (
    page: number,
    size: number,
    assetCode?: string,
    email?: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    var response = await new AccountService().getDownloadHistoryAsync(1, 10, assetCode, email, startDate, endDate);

    if (response.status !== HttpStatus.OK) {
      return;
    }

    var data = response.data.list as DownloadHistoryModel[];
    setTotalRow(response.data.totalRows);
    setDownloadHistory(data);
  };

  useEffect(() => {
    getDownloadHistoryAsync(page, size, assetCode, email, startDate, endDate);
  }, []);

  const onChangePage = async (page: number, size: number) => {
    setPage(page);
    setSize(size);

    await getDownloadHistoryAsync(page, size, assetCode, email, startDate, endDate);
  };

  const onReset =  () => {
    form.resetFields();
    setAssetCode('');
    setEmail('');
    setStartDate(undefined);
    setEndDate(undefined);
    getDownloadHistoryAsync(1, 10, undefined, undefined, undefined, undefined);
    setPage(1);
    setSize(10);
  };

  return (
    <>
      <Card
        title='Download History'
        bordered={false}>
        <Form form={form} layout='vertical'>
          <Row
            gutter={16}
            justify='center'>
            <Col
              xs={24}
              md={8}>
              <Form.Item
                className=' text-light w-100'
                label='Email'>
                <Input
                  className='w-100'
                  placeholder='Email'
                  size='large'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={8}>
              <Form.Item
                className=' text-light w-100'
                label='Asset Code'>
                <Input
                  className='w-100'
                  placeholder='Asset Code'
                  size='large'
                  value={assetCode}
                  onChange={(e) => setAssetCode(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            className='d-flex gap-2'
            justify='center'>
            <Col
              span={24}
              md={8}>
              <Form.Item
                name='downloadDate'
                className='text-light w-100'
                label='Download Date'
                rules={[{ required: false, message: 'Please input your name!' }]}>
                <RangePicker
                  format={dateFormat}
                  onChange={onChange}
                  className='w-100'
                  size='large'
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={8}
            />
          </Row>
          <Row
            gutter={16}
            justify={'center'}>
            <Col
              xs={12}
              md={4}>
              <Button
                shape='default'
                type='primary'
                className='w-100'
                size='large'
                onClick={onSearchAsync}>
                Search
              </Button>
            </Col>
            <Col
              xs={12}
              md={4}>
              <Button
                shape='default'
                type='default'
                className='w-100'
                size='large'
                onClick={onReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className='mt-2'>
          <Col span={24}>
            <Table
              scroll={{x : true}}
              columns={columns}
              dataSource={downloadHistories}
              pagination={false}
            />
          </Col>
          <Col
            span={24}
            className='text-right pt-3'>
            <Pagination
              defaultCurrent={1}
              current={page}
              total={totalRow}
              pageSize={size}
              onChange={onChangePage}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
