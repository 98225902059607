import { Fragment } from 'react';
import {  Text, View, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    marginTop: 10,
  },
  description: {
    height: '100%',
    width: '50%',
    textAlign: 'left',
    paddingLeft: 5,
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
  },
  textBold:{
    fontWeight: 'black'
  },
  textUnderLine: {
    textDecoration: 'underline'
  },
  rowEnd: {
    fontWeight: 'black',
    width: '50%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  }
});

export default function FooterReport() {
  return (
    <Fragment>
      <View style={styles.rowContainer}>
        <View style={styles.description}>
          <View style={styles.row}>
            <Text style={styles.textBold}>
                  Email :
              </Text>
              <Text style={styles.textUnderLine}>
                  client_support@aliveunited.com
              </Text>
          </View>
          <View style={styles.row}>
          <Text style={styles.textBold}>
                Line : 
            </Text>
            <Text style={styles.textUnderLine}>
              @ALIVEUNITED
            </Text>
            </View>
        </View>
        <View style={styles.rowEnd}>
          <Text style={styles.textBold}>
            KEEP PASSION ALIVE
          </Text>
        </View>
      </View>
    </Fragment>
  );
}
