import { Card, Row, Col, Form, Divider, Space, message, Typography } from 'antd';
import Input from '../../components/Forms/Input';
import Select from '../../components/Forms/Select';
import Button from '../../components/General/Button';
import { AccountService } from '../../services/accountService';
import { ICreateAdminRequestModel } from '../../models/request/AdminRequestModel';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';

const orientation = [
  {label: 'Admin', value: 'Admin'},
  {label: 'Finance', value: 'Finance'},
  {label: 'Reviewer', value: 'Reviewer'},
  {label: 'Marketing', value: 'Marketing'},
];

const AdminInvite: React.FC = () => {
  const accountService = new AccountService();
  const [ form ] = Form.useForm();
  const navigate = useNavigate();

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = async (value: any) => {
    const model: ICreateAdminRequestModel = {
      email: value.email,
      firstname: value.firstName,
      lastname: value.lastName,
      roleType: value.role,
    };

    const {status} = await accountService.registerAdminAsync(model);

    if (status !== HttpStatusCode.Ok && status !== HttpStatusCode.Created) {
      message.error('Save admin failed.');
      return;
    }

    message.success('Save admin success.');
    navigate('/admin');
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        validateMessages={validateMessages}>
        <Card
          title={
            <Space align='center'>
                <Typography.Title level={5} className='mb-0'>
                    Invite Admin
                </Typography.Title>
            </Space>
         }
          extra={
            <Space align="center">
              <Button
                type="primary"
                text="Save"
              />
            </Space>
          }
          bordered={false}>
          <div className="p-4">
            <Divider orientation="left" plain>
              Profile
            </Divider>
            <Row justify="start" gutter={16}>
              <Col span={6}>
                <Input
                  placeholder="Email"
                  name="email"
                  label="Email"
                  rules={[
                    {required: true, message: 'Please input your email!'},
                    {type: 'email', message: 'The input is not valid E-mail!',} ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Input
                  placeholder="Firstname"
                  name="firstName"
                  label="First Name"
                  rules={[ {required: true, message: 'Please input your firstname!'} ]}
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Lastname"
                  name="lastName"
                  label="Last Name"
                  rules={[ {required: true, message: 'Please input your lastname!'} ]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Select
                  name="role"
                  label="Role"
                  options={orientation}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default AdminInvite;