export const enum AssetStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  ABANDON = 'Abandon',
}

export const enum AssetRequestStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
}

export const enum AssetUsage {
  COMERCIAL = 'Commercial',
  EDITORIAL = 'Editorial',
  AIGENERATED = 'AiGenerated',
}

export const enum AssetOrientation {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical',
}

export const enum AssetComposition {
  INDOOR = 'Indoor',
  OUTDOOR = 'Outdoor',
}

export const enum AssetType {
  IMAGE = 'Image',
  VIDEO = 'Video',
}
