import { createContext, useContext } from 'react';
import { ResponseSignIn, SignInModel } from '../models/accountModel';
import { SessionStatus } from '../constant/sessionStatus';
import { UserProfile } from '../models/response/userProfile';

export type SessionContentType = {
  signInAsync: (model: SignInModel) => Promise<ResponseSignIn>,
  validateTokenExpire: () => void,
  isAuthenticated: SessionStatus,
  signOut: () => void,
  getUserProfileAsync: () => void,
  userProfile: UserProfile,
}

export const SessionContext = createContext<SessionContentType>({} as SessionContentType);

export const useSessionContext = () => useContext(SessionContext);