export class RouteNames {
  static readonly default: string = '/';
  static readonly login: string = '/';
  static readonly dashboard: string = '/dashboard';
  static readonly assetRequests: string = '/assetRequest';
  static readonly asset: string = '/Asset';
  static readonly assetDetail: string = '/Asset/Detail';
  static readonly banner: string = '/Banner';
  static readonly bannerDetail: string = '/Banner/Detail';
  static readonly recommendAsset: string = '/RecommendAsset';
  static readonly recommendAssetDetail: string = '/RecommendAsset/Detail';
  static readonly recommendCollection: string = '/RecommendCollection';
  static readonly recommendCollectionDetail: string = '/RecommendCollection/Detail';
  static readonly newsContent: string = '/NewsContent';
  static readonly newsContentDetail: string = '/NewsContent/Detail';
  static readonly orders: string = '/Order';
  static readonly orderDetail: string = '/Order/Detail';
  static readonly reportAsset: string = '/ReportAsset';
  static readonly contactUs: string = '/ContactUs';
  static readonly promotion: string = '/Promotion';
  static readonly promotionDetail: string = '/Promotion/Detail';
  static readonly collection: string = '/Collection';
  static readonly collectionDetail: string = '/Collection/Detail';
  static readonly artist: string = '/Artist';
  static readonly artistDetail: string = '/Artist/Detail';
  static readonly release: string = '/Releases';
  static readonly releaseDetail: string = '/Releases/Detail';
  static readonly payoutRequest: string = '/PayoutRequest';
  static readonly payoutDetail: string = '/PayoutRequest/Detail';
  static readonly customer: string = '/Customer';
  static readonly customerDetail: string = '/Customer/Detail';
  static readonly admin: string = '/Admin';
  static readonly adminDetail: string = '/Admin/Detail';
  static readonly keywords: string = '/Keyword';
  static readonly eventlogs: string = '/Eventlog';
  static readonly changePassword: string = '/change-password';
  static readonly downloadHistory: string = '/download-history';
}