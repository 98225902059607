import { Col, Divider, Form, FormInstance, Row, Switch } from 'antd';
import Input from '../Forms/Input';
import TextArea from '../Forms/TextArea';
import ICompanyResponse from '../../models/response/CompanyResponse';
import { useCallback, useEffect, useState } from 'react';
import SelectDistrict from '../Address/SelectDistrict';
import SelectProvince from '../Address/SelectProvince';
import SelectSubDistrict from '../Address/SelectSubDistrict';

interface IProps {
  useFormCompany: FormInstance<any>;
  onSubmitCompany: (data: any) => void;
  companyInfo: ICompanyResponse | undefined;
}

export default function CompanyDetail({useFormCompany, onSubmitCompany, companyInfo}: IProps) {
  const [ postcode, setPostCode ] = useState('');
  const [ districtId, setDistrictId ] = useState(0);
  const [ provinceId, setProvinceId ] = useState(0);
  const [ subDistrictId, setSubDistrictId ] = useState(0);
  const [ isVip, setIsVip ] = useState(false);
  const [ totalVipDownload, setTotalVipDownload ] = useState(0);
  const [ vipDownloadQty, setVipDownloadQty ] = useState(0);

  useEffect(() => {
    if (companyInfo) {
      if(companyInfo?.vipDownloads){
        setTotalVipDownload(companyInfo?.vipDownloads.reduce((a,v) =>  a = a + v.quantity , 0 ));
      }
      if(companyInfo?.vipDownloadQty){
        setVipDownloadQty(companyInfo?.vipDownloadQty);
      }
      setProvinceId(Number(companyInfo.addressInfo.provinceCode));
      setDistrictId(Number(companyInfo.addressInfo.districtCode));
      setSubDistrictId(Number(companyInfo.addressInfo.subDistrictCode));
      setPostCode(companyInfo.addressInfo.postcode.toString());
      setIsVip(useFormCompany.getFieldValue("isVip"))
    }
  }, [companyInfo]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      useFormCompany.setFields([
        {
          name: key,
          errors: [ `${key} is required` ],
        },
      ]);
    } else {
      useFormCompany.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [ useFormCompany ]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormCompany.setFieldValue('provinceCode', provinceId);
    validateCustomField(!provinceId, 'provinceCode');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormCompany.setFieldValue('districtCode', districtId);
    validateCustomField(!districtId, 'districtCode');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    useFormCompany.setFieldValue('subDistrictCode', subDistrictId);
    validateCustomField(!subDistrictId, 'subDistrictCode');
  };

  const onChangeVip = (checked: boolean) => {
    setIsVip(checked);
    useFormCompany.setFieldValue('isVip', checked);
  };

  return (
    <Form
      form={useFormCompany}
      onFinish={onSubmitCompany}
      layout="vertical"
      autoComplete="off"
      name="customerDetailForm">
      <Divider orientation="left" plain>
        Profile
      </Divider>

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Company Name"
            name="companyName"
            label="Company Name"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Tax ID"
            name="taxId"
            label="Tax ID"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Branch"
            name="branch"
            label="Branch"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Company Website"
            name="companyWebsite"
            label="Company Website"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>

      <Divider orientation="left" plain>
        Address
      </Divider>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Address"
            name="address"
            label="Address"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Postcode"
            name="postcode"
            label="Zip / Postcode"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <SelectProvince
            className="non-radius"
            provinceID={provinceId}
            sendProvinceID={onProvinceChange}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <SelectDistrict
            className="non-radius"
            districtID={districtId}
            provinceID={provinceId}
            sendProvinceID={(id) => setProvinceId(id)}
            sendDistrictID={onDistrictChange}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <SelectSubDistrict
            className="non-radius"
            postCode={postcode}
            districtID={districtId}
            provinceID={provinceId}
            subDistrictID={subDistrictId}
            sendPostCode={(value) => {
              useFormCompany.setFieldValue('postcode', value);
            }}
            sendSubDistrictID={onSubDistrictChange}
            sendDistrictID={(id, subId) => {
              setDistrictId(id);
            }}
          />
        </Col>
      </Row>

      <Divider orientation="left" plain>
        Contact Person Detail
      </Divider>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="First Name"
            name="contactFirstName"
            label="First Name"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Last Name"
            name="contactLastName"
            label="Last Name"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Email"
            name="contactEmail"
            label="Email"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Phone"
            name="contactPhone"
            label="Phone"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Job title"
            name="jobTitle"
            label="Job title"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Department"
            name="department"
            label="Department"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <TextArea
            placeholder="Purchasing for"
            name="purchasingFor"
            label="Purchasing for"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Divider orientation="left" plain>
        VIP Customer
      </Divider>
      <Row>
        <Col>
            <Form.Item label="VIP" name="isVip">
                <Switch checked={isVip} onChange={onChangeVip} />
            </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
            <Form.Item label="Total VIP Download">
                {`${vipDownloadQty}/${totalVipDownload}`}
            </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Free Download Quantity"
            name="quality"
            label="Add Free VIP Download"
            disabled={!isVip}
            rules={[ {required: false, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Credit Term (Day)"
            name="paymentTerm"
            label="Credit Term (Day)"
            disabled={!isVip}
            rules={[ {required: isVip, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
    </Form>
  );
}