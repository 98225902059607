import { Card, Row, Col, Form, Space, Badge, Input, Button, DatePicker, Radio, Pagination } from 'antd';
import Table from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OrderService } from '../../services/orderService';
import { HttpStatus } from '../../constant/responseStatus';
import { OrderDataModel, OrderSearchModel } from '../../models/OrderModel';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { OrderStatus } from '../../constant/statusConstants';
import { RouteNames } from '../../components/Route/routeName';
import stringFormat from '../../helper/stringFormat';
import { RangePickerProps } from 'antd/es/date-picker';


const { RangePicker } = DatePicker;

const orderStatusOptions = [
    { label: 'All', value: OrderStatus.EmpTy.toString() },
    { label: OrderStatus.Pending, value: OrderStatus.Pending.toString() },
    { label: OrderStatus.Completed, value: OrderStatus.Completed.toString() },
    { label: OrderStatus.Rejected, value: OrderStatus.Rejected.toString() },
    { label: OrderStatus.Cancelled, value: OrderStatus.Cancelled.toString() },
];

const paymentMethodOptions = [
  { label: 'All', value: '' },
  { label: 'Credit Card', value: 'Creditcard' },
  { label: 'QR Code', value: 'BankTransfer' },
  { label: 'Payment Term', value: 'PaymentTerm' },
];

const columns: ColumnsType<OrderDataModel> = [
    {
        title: 'Order Code',
        dataIndex: 'code',
        align: 'center',
        render: (value, record, index) => <>{record.order.code}</>,
    },
    {
        title: 'Order Date',
        dataIndex: 'orderDate',
        align: 'center',
        render: (value, record, index) => (
            <>{record.order.orderDate && moment(record.order.orderDate).format(DateFormat.DateTime)}</>
        ),
    },
    {
        title: 'Customer',
        dataIndex: 'individualInfo',
        align: 'center',
        render: (value, record, index) => (
            <>
                {record.order.individualInfo &&
                    record.order.individualInfo.firstname + ' ' + record.order.individualInfo.lastname}
            </>
        ),
    },
    {
        title: 'Payment Method',
        dataIndex: 'paymentMethod',
        align: 'center',
        render: (value, record, index) => <>{stringFormat.paymentMethod(record.order.paymentMethod)}</>,
    },
    {
        title: 'Payment Term Expire Date',
        dataIndex: 'orderExpireDate',
        width: 300,
        align: 'center',
        render: (value, record, index) => (
          <>
          {(record.order.orderExpireDate && record.order.creditTerm) && (
            <p>
              {moment(record.order.orderExpireDate).format(DateFormat.DateTime)}
              {` (${record.order.creditTerm} days)`}
            </p>
          )}
          </>
        ),
    },
    {
        title: 'Total Amount',
        dataIndex: 'netTotal',
        align: 'center',
        render: (value, record, index) => (
            <p className='text-right'>
                {record.order.netTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                })}
            </p>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (value, record, index) =>
            record.order.status === OrderStatus.Pending ? (
                <Badge status='default' text={record.order.status} />
            ) : record.order.status === OrderStatus.Completed ? (
                <Badge status='success' text={record.order.status} />
            ) : (
                <Badge status='error' text={record.order.status} />
            ),
    },
    {
        title: 'Detail',
        dataIndex: 'detail',
        render: (value, record, index) => <Link to={`${RouteNames.orderDetail}?id=${record.order.id}`}>Detail</Link>,
    },
];

export default function Orders() {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const [orders, setOrders] = useState<OrderDataModel[]>([]);
    const [orderCode, setOrderCode] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [startDate, setStartDate] = useState<Date|undefined>();
    const [endDate, setEndDate] = useState<Date|undefined>();
    const [paymentMethod, setPaymentMethod] = useState<string|undefined>(undefined);
    const [orderStatus, setOrderStatus] = useState<string|undefined>(undefined);

    const search = useLocation().search;

    useEffect(() => {
        const paramStatus = new URLSearchParams(search).get("status");
        const paramPaymentMethod = new URLSearchParams(search).get("paymentMethod");
        setOrderStatus(paramStatus ?? '');
        setPaymentMethod(paramPaymentMethod ?? '');
        onGetListOrders(page, size, undefined, undefined, paramPaymentMethod ?? '', paramStatus ?? '');
    }, []);

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListOrders(page, size);
    };

    const onGetListOrders = async (page: number, size: number, orderCode?: string, customerName?: string, paymentMethod?: string, orderStatus?: string, startDate?: Date, endDate?: Date) => {
        const request: OrderSearchModel = {
            orderCode: orderCode,
            customerName: customerName,
            status: orderStatus,
            startDate: startDate,
            endDate: endDate,
            paymentMethod: paymentMethod,
        };
        const { data, status } = await new OrderService().getList(page, size, request);

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.total);
        setOrders(data.list);
    };

    const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
      if (dates) {
        setStartDate(new Date(dateStrings[0]));
        setEndDate(new Date(dateStrings[1]));
      } else {
        setStartDate(undefined);
        setEndDate(undefined);
      }
    };

    const onFinish = () => {
        onGetListOrders(page, size, orderCode, customerName, paymentMethod, orderStatus, startDate, endDate);
    };

    const reset = () => {
      setOrderCode('');
      setCustomerName('');
      setOrderStatus('');
      setPaymentMethod('');
      setStartDate(undefined);
      setEndDate(undefined);
      onGetListOrders(1, 10);
      setPage(1);
      setSize(10);
  };

    const onExportCsv = async () => {
      const request: OrderSearchModel = {
        orderCode: orderCode,
        customerName: customerName,
        status: orderStatus,
        startDate: startDate,
        endDate: endDate,
        paymentMethod: paymentMethod,
    };
      const { data, status } = await new OrderService().ExportCsv(request);

        if (status !== HttpStatus.OK) {
            return;
        }

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(data);
        downloadLink.download = "Orders_Report.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            <Card title='Order' bordered={false}>
                <Card className='card-form'>
                  <Form layout='vertical'>
                    <Row justify='center' gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                className=' text-light w-100'
                                label='Order Code'
                                rules={[{ required: false }]}
                            >
                                <Input
                                size='large'
                                placeholder='Order Code'
                                value={orderCode}
                                onChange={(e) => setOrderCode(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className=' text-light w-100'
                                label='Customer Name'
                                rules={[{ required: false }]}
                            >
                                <Input
                                size='large'
                                placeholder='Customer Name'
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                className=' text-light w-100'
                                label='Order Date'
                                rules={[{ required: false }]}
                            >
                                <RangePicker
                                size='large'
                                onChange={onChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className=' text-light w-100'
                                label='Status'
                                rules={[{ required: false }]}
                            >
                                <Radio.Group
                                    options={paymentMethodOptions}
                                    optionType='button'
                                    buttonStyle='solid'
                                    size='large'
                                    value={paymentMethod}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                className=' text-light w-100'
                                label='Status'
                                rules={[{ required: false }]}
                            >
                                <Radio.Group
                                    options={orderStatusOptions}
                                    optionType='button'
                                    buttonStyle='solid'
                                    size='large'
                                    value={orderStatus}
                                    onChange={(e) => setOrderStatus(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='end'>
                        <Col span={24} className='text-right'>
                            <Space>
                                <Button type='primary' className='px-5' onClick={onFinish}>
                                    Search
                                </Button>
                                <Button type='default' className='px-5' onClick={reset}>
                                    Clear
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                  </Form>
                </Card>
                <Row className='my-2' justify='end'>
                  <Col span={24} className='text-right'>
                    <Button size='large' type='default' className='px-5' onClick={onExportCsv}>
                        Export CSV
                    </Button>
                  </Col>
                </Row>
                <Table className='mt-4' columns={columns} dataSource={orders} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
