import { Col, Divider, Form, FormInstance, Row } from 'antd';
import Input from '../Forms/Input';
import { useCallback, useEffect, useState } from 'react';
import SelectProvince from '../Address/SelectProvince';
import SelectDistrict from '../Address/SelectDistrict';
import SelectSubDistrict from '../Address/SelectSubDistrict';
import IIndividualResponse from '../../models/response/IndividualResponse';

interface IProps {
  useFormIndividual: FormInstance<any>;
  onSubmitIndividual: (data: any) => void;
  individual: IIndividualResponse | undefined;
}

export default function IndividualDetail({useFormIndividual, onSubmitIndividual, individual}: IProps) {
  const [ postcode, setPostCode ] = useState('');
  const [ districtId, setDistrictId ] = useState(0);
  const [ provinceId, setProvinceId ] = useState(0);
  const [ subDistrictId, setSubDistrictId ] = useState(0);

  const [ form ] = Form.useForm(useFormIndividual);

  useEffect(() => {
    if (individual) {
      setProvinceId(Number(individual.addressInfo.provinceCode));
      setDistrictId(Number(individual.addressInfo.districtCode));
      setDistrictId(Number(individual.addressInfo.districtCode));
      setSubDistrictId(Number(individual.addressInfo.subDistrictCode));
      setPostCode(individual.addressInfo.postcode.toString());
    }
  }, [individual]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      useFormIndividual.setFields([
        {
          name: key,
          errors: [ `${key} is required` ],
        },
      ]);
    } else {
      useFormIndividual.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [ useFormIndividual ]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormIndividual.setFieldValue('provinceCode', provinceId);
    validateCustomField(!provinceId, 'provinceCode');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormIndividual.setFieldValue('districtCode', districtId);
    validateCustomField(!districtId, 'districtCode');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    useFormIndividual.setFieldValue('subDistrictCode', subDistrictId);
    validateCustomField(!subDistrictId, 'subDistrictCode');
  };

  return (
    <Form
      form={form}
      onFinish={onSubmitIndividual}
      layout="vertical"
      autoComplete="off"
      name="customerDetailForm">

      <Divider orientation="left" plain>
        Profile
      </Divider>

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Firstname"
            name="firstname"
            label="First Name"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Lastname"
            name="lastName"
            label="Last Name"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Email"
            name="email"
            label="Email"
            readOnly
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Phone"
            name="phone"
            label="Phone"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>

      <Divider orientation="left" plain>
        Address
      </Divider>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Address"
            name="address"
            label="Address"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Input
            placeholder="Postcode"
            name="postcode"
            label="Zip / Postcode"
            rules={[ {required: true, message: 'Please input your message!'} ]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <SelectProvince
            className="non-radius"
            provinceID={provinceId}
            sendProvinceID={onProvinceChange}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <SelectDistrict
            className="non-radius"
            districtID={districtId}
            provinceID={provinceId}
            sendProvinceID={(id) => setProvinceId(id)}
            sendDistrictID={onDistrictChange}
          />
        </Col>
        <Col className="gutter-row" span={6}>
          <SelectSubDistrict
            className="non-radius"
            postCode={postcode}
            districtID={districtId}
            provinceID={provinceId}
            subDistrictID={subDistrictId}
            sendPostCode={(value) => {
              useFormIndividual.setFieldValue('postcode', value);
            }}
            sendSubDistrictID={onSubDistrictChange}
            sendDistrictID={(id, subId) => {
              setDistrictId(id);
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}