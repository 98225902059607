import { Menu, Layout, Row, Col, Space, Divider } from 'antd';
import { useSessionContext } from '../../context/session-context';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../Route/routeName';

const { Header } = Layout;

function HeaderMenu() {
    const { userProfile, getUserProfileAsync } = useSessionContext();

    useEffect(() => {
        if(!userProfile.userId){
            getUserProfileAsync();
        }
    }, [userProfile]);

  return (
    <Header className="header">
        <Space split={<Divider type="vertical" style={{borderColor: '#fff'}} />}>
            <div className="logo">
                <Link to={RouteNames.dashboard}>
                    <img src="../images/logo.png" alt="alive united plus" width={100} />
                </Link>
            </div>
        </Space>
      <Menu
        items={[
          {
            key: '2',
            label: <>{userProfile.email}</>,
          },
        ]} />
    </Header>
  );
};

export default HeaderMenu;