
import { Input, Form } from 'antd';
import { CSSProperties } from 'react';
import { Rule } from 'rc-field-form/lib/interface';

const { TextArea } = Input;

interface Props {
  placeholder?: string;
  name?: string;
  label?: string;
  className?: string;
  rows?: number;
  style?: CSSProperties;
  rules?: Rule[];
}

function TextAreaForm({ placeholder, name, label, className, rows, style, rules = [{ required: false }]}: Props) {
  return (
    <>
      <Form.Item label={label} rules={rules} name={name}>
        <TextArea
          rows={rows}
          placeholder={placeholder}
          className={className}
          showCount maxLength={200}
          style={style}
        />
      </Form.Item>
    </>
  );
};
export default TextAreaForm;