import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { AssetRequestModel, AssetRequestPagingModel, AssetRequestSearchModel } from '../models/assetRequestModel';

export class AssetRequestService {
  private api = 'assetRequest';

  searchAsync(searchModel: AssetRequestSearchModel): Promise<AxiosResponse<AssetRequestPagingModel>> {
    const param = {
      'Paging.Page': searchModel.paging.page,
      'Paging.Size': searchModel.paging.size,
      'Status': searchModel.status == null ||  searchModel.status === ''?  null : searchModel.status,
      'RequestNo': searchModel.requestNo,
      'Email': searchModel.email,
      'ArtistName': searchModel.artistName,
      'RequestDate.Start': searchModel.requestDate?.start,
      'RequestDate.End': searchModel.requestDate?.end,
    };

    return Axios.get<AssetRequestPagingModel>(`${this.api}/search`, {params: param});
  }

  getAsync(id: string): Promise<AxiosResponse<AssetRequestModel>> {
    return Axios.get<AssetRequestModel>(`${this.api}/${id}`);
  }
}