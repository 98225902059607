import { Select } from 'antd';
import { useEffect, useState } from 'react';
import District from '../../constant/District.json';

const { Option } = Select;

interface DistrictProps {
  districtID: number,
  provinceID: number,
  sendProvinceID: (id: number) => void,
  sendDistrictID: (id: number) => void,
  className: string;
}

export default function SelectDistrict(props: DistrictProps) {
  const [districtId, setDistrictId] = useState(0);

  useEffect(() => {
    setDistrictId(props.districtID);
  }, [props.districtID]);

  const handleChangeDistrict = (value: number) => {
    setDistrictId(value);

    props.sendDistrictID(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      size="large"
      className={props.className}//"input-radius-dark non-radius"
      value={districtId}
      onSelect={(value: number) => handleChangeDistrict(value)}
    >
      <Option value={0}>
        Select District
      </Option>
      {
        District.map((data, index) => data.provinceID === props.provinceID && (
          <Option value={data.id} key={`${data.id}-${index}`}>
            {data.name}
          </Option>
        ))
      }
    </Select>
  );
}
