import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../context/session-context';
import { SessionStatus } from '../../constant/sessionStatus';

interface Props {
  children: JSX.Element;
  forceAuthentication?: boolean;
}
const GuardedRoute = ({ children, forceAuthentication = false }: Props) => {
  const { isAuthenticated, validateTokenExpire } = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === SessionStatus.UNAUTHENTICATED && !forceAuthentication) {
      navigate('/login');
    }

    validateTokenExpire();
  }, [isAuthenticated, forceAuthentication]);

  if (forceAuthentication) {
    return children;
  }

  if (isAuthenticated === SessionStatus.AUTHENTICATED) {
    return children;
  }

  return null;
};

export default GuardedRoute;