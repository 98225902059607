import { Row, Col, Form, Typography, Divider, Checkbox, FormInstance } from 'antd';
import Input from '../../components/Forms/Input';
import TextArea from '../../components/Forms/TextArea';
import { useCallback, useEffect, useState } from 'react';
import SelectProvince from '../../components/Address/SelectProvince';
import SelectDistrict from '../../components/Address/SelectDistrict';
import SelectSubDistrict from '../../components/Address/SelectSubDistrict';
import { ArtistModel } from '../../models/accountModel';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { MasterDataResponse } from '../../models/response/MasterDataResponse';
import { Status } from '../DataDisplay/Table';
import { VscCircleFilled } from 'react-icons/vsc';

const {Paragraph} = Typography;

interface IProps {
  useFormArtist: FormInstance<any>;
  onSubmitArtist: (data: any) => void;
  artist: ArtistModel | undefined;
  styleOfWork: MasterDataResponse[];
  platForm: MasterDataResponse[];
}

export default function ArtistDetail({useFormArtist, onSubmitArtist, artist, styleOfWork, platForm}: IProps) {
  const [ artistForm ] = Form.useForm(useFormArtist);

  const [ postcode, setPostCode ] = useState('');
  const [ districtId, setDistrictId ] = useState(0);
  const [ provinceId, setProvinceId ] = useState(0);
  const [ subDistrictId, setSubDistrictId ] = useState(0);
  const [ isShowOtherStyleOfWork, setIsShowOtherStyleOfWork ] = useState(false);
  const [ isShowOtherPlatform, setIsShowOtherPlatform ] = useState(false);
  const [ isShowMySitePlatform, setIsShowMySitePlatform ] = useState(false);

  const onSetStyleOfWorkChecked = useCallback((checkId: string, styleOfWork: MasterDataResponse[]) => {
    const object = styleOfWork.find(x => x.value === checkId);

    if (object && object.name === 'Other') {
      setIsShowOtherStyleOfWork(true);
    }
  }, []);

  const onSetPlatformChecked = useCallback((checkId: string, platForm: MasterDataResponse[]) => {
    const object = platForm.find(x => x.value === checkId);

    if (object && object.name === 'Other') {
      setIsShowOtherPlatform(true);
    }

    if (object && object.name === 'My Site') {
      setIsShowMySitePlatform(true);
    }
  }, []);

  useEffect(() => {
    if (artist && styleOfWork.length > 0 && platForm.length > 0) {
      setDistrictId(Number(artist.addressInfo.districtCode));
      setProvinceId(Number(artist.addressInfo.provinceCode));
      setSubDistrictId(Number(artist.addressInfo.subDistrictCode));
      setPostCode(artist.addressInfo.postcode.toString());

      const listStyleOfWork = JSON.parse(artist.styleOfWork) as string[];
      const listPlatForm = JSON.parse(artist.platform) as string[];

      artistForm.setFieldValue('styleOfWork', listStyleOfWork);
      artistForm.setFieldValue('platForm', listPlatForm);

      listStyleOfWork.forEach(x => onSetStyleOfWorkChecked(x, styleOfWork));
      listPlatForm.forEach(x => onSetPlatformChecked(x, platForm));
    }
  }, [ artist, styleOfWork, platForm ]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      artistForm.setFields([
        {
          name: key,
          errors: [ `${key} is required` ],
        },
      ]);
    } else {
      artistForm.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [ artistForm ]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    artistForm.setFieldValue('ProvinceId', provinceId);
    validateCustomField(!provinceId, 'ProvinceId');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    artistForm.setFieldValue('DistrictId', districtId);
    validateCustomField(!districtId, 'DistrictId');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    artistForm.setFieldValue('SubDistrictId', subDistrictId);
    validateCustomField(!subDistrictId, 'SubDistrictId');
  };

  const onValidateSocial = () => {
    const facebook = artistForm.getFieldValue('facebook');
    const instagram = artistForm.getFieldValue('instagram');
    const tiktok = artistForm.getFieldValue('tiktok');
    const other = artistForm.getFieldValue('other');
    const validateSocial = facebook || instagram || tiktok || other;

    validateCustomField(!validateSocial, 'SocialGroup');
  };

  const onStyleOfWorkChange = useCallback((value: CheckboxChangeEvent) => {
    const targetId = value.target.value;
    const isChecked = value.target.checked;
    const object = styleOfWork.find(x => x.value === targetId);

    if (object && object.name === 'Other') {
      setIsShowOtherStyleOfWork(isChecked);
    }
  }, [ styleOfWork ]);

  const onPlatformChange = useCallback((value: CheckboxChangeEvent) => {
    const targetId = value.target.value;
    const isChecked = value.target.checked;
    const object = platForm.find(x => x.value === targetId);

    if (object && object.name === 'Other') {
      setIsShowOtherPlatform(isChecked);
    }

    if (object && object.name === 'My Site') {
      setIsShowMySitePlatform(isChecked);
    }
  }, [ platForm ]);

  const setStyleOfWorkCheckBox = (values: any) => {
    artistForm.setFieldValue('styleOfWork', values);
  };

  const setPlatformCheckBox = (values: any) => {
    artistForm.setFieldValue('platForm', values);
  };

  return (
    <>
      <div className="p-4">
        <Form
          form={artistForm}
          onFinish={onSubmitArtist}
          layout="vertical"
          autoComplete="off"
          name="artistForm">
          <Row>
            <Col span={24}>
              <>
                {
                  <p>Artist Status: <VscCircleFilled
                    className={`${Status(artistForm.getFieldValue('status'))} me-2`} />{artistForm.getFieldValue('status')}
                  </p>
                }
                <Divider orientation="left" plain>
                  Profile
                </Divider>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="xxx@xxx.xx"
                      name="email"
                      label="Email"
                      rules={[ {required: true, message: 'Please input your message!'} ]} />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="00-000-0000"
                      name="phone"
                      label="Phone"
                      rules={[ {required: true, message: 'Please input your message!'} ]} />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                    type='password'
                      name="password"
                      label="New Password"
                      rules={[ {required: false, message: 'Please input your password!'} ]} />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="firstname"
                      label="First name"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="lastname"
                      label="Last Name"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="nickname"
                      label="Nick Name"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="firstnameTh"
                      label="ชื่อ"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="lastnameTh"
                      label="สกุล"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="example"
                      name="nicknameTh"
                      label="ชื่อเล่น"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                </Row>
                <Divider orientation="left" plain>
                  Address
                </Divider>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="address"
                      name="address"
                      label="Address"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      placeholder="postcode"
                      name="Postcode"
                      label="Zip / Postcode"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <SelectProvince
                      className="non-radius"
                      provinceID={provinceId}
                      sendProvinceID={onProvinceChange}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <SelectDistrict
                      className="non-radius"
                      districtID={districtId}
                      provinceID={provinceId}
                      sendProvinceID={(id) => setProvinceId(id)}
                      sendDistrictID={onDistrictChange}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <SelectSubDistrict
                      className="non-radius"
                      postCode={postcode}
                      districtID={districtId}
                      provinceID={provinceId}
                      subDistrictID={subDistrictId}
                      sendPostCode={(value) => {
                        artistForm.setFieldValue('Postcode', value);
                      }}
                      sendSubDistrictID={onSubDistrictChange}
                      sendDistrictID={(id, subId) => {
                        setDistrictId(id);
                      }}
                    />
                  </Col>
                </Row>
                <Divider orientation="left" plain>
                  Artist Description
                </Divider>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Input
                      name="portfolio"
                      label="Portfolio"
                      placeholder="example"
                    />
                  </Col>
                  <Col className="gutter-row" span={3}>
                    <Input
                      name="facebook"
                      label="Social"
                      placeholder="facebook"
                      onChange={() => onValidateSocial()}
                    />
                  </Col>
                  <Col className="gutter-row" span={3}>
                    <Input
                      className="mt-30"
                      name="instagram"
                      label=""
                      placeholder="instagram"
                      onChange={() => onValidateSocial()}
                    />
                  </Col>
                  <Col className="gutter-row" span={3}>
                    <Input
                      className="mt-30"
                      name="tiktok"
                      label=""
                      placeholder="tiktok"
                      onChange={() => onValidateSocial()}
                    />
                  </Col>
                  <Col className="gutter-row" span={3}>
                    <Input
                      className="mt-30"
                      name="other"
                      label=""
                      placeholder="other"
                      onChange={() => onValidateSocial()}
                    />
                  </Col>
                </Row>
                <Divider orientation="left" plain>
                  Artist Description
                </Divider>
                <Row gutter={16}>
                  <Col span={12}>
                    <TextArea
                      placeholder="Autosize height based on content lines"
                      name="aboutYourSelf"
                      label="Would you like to share anything more about yourself with us?"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <TextArea
                      placeholder="Autosize height based on content lines"
                      name="selfPromotion"
                      label="Difinitions of passion essential for self-promotion?"
                      rules={[ {required: true, message: 'Please input your message!'} ]}
                    />
                  </Col>
                </Row>

                <Paragraph>Style of work</Paragraph>
                <Row gutter={16}>
                  <Col span={4}>
                    <Form.Item
                      name="styleOfWork"
                      className="mt-4"
                      label="Your work.">
                      <Checkbox.Group onChange={setStyleOfWorkCheckBox}>
                        <Row>
                          {
                            styleOfWork
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((item) =>
                                <Col key={item.sequence} span={24}>
                                  <Checkbox onChange={onStyleOfWorkChange} value={item.value}>
                                    {item.name}
                                  </Checkbox>
                                </Col>)
                          }
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    <Input
                      placeholder="Other"
                      name="styleOfWorkOther"
                      className={`small-input ${isShowOtherStyleOfWork ? '' : 'hide'}`} />
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="platForm"
                      className="mt-4"
                      label="Do you sell your somewhere else? Select each option that applies.">
                      <Checkbox.Group onChange={setPlatformCheckBox}>
                        <Row>
                          {
                            platForm
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((item) =>
                                <Col key={item.sequence} span={24}>
                                  <Checkbox onChange={onPlatformChange}
                                            value={item.value}>
                                    {item.name}
                                  </Checkbox>
                                </Col>)
                          }
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    <Input placeholder="MySite" name="platFormMySite"
                           className={`small-input ${isShowMySitePlatform ? '' : 'hide'}`} />
                    <Input placeholder="Other" name="platFormOther"
                           className={`small-input ${isShowOtherPlatform ? '' : 'hide'}`} />
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
          <Divider
                orientation='left'
                plain>
                File Attachment
              </Divider>
          <Row
            gutter={16}
            className='mt-4'>
            <Col span={4}>ID Card</Col>
            <Col span={20}>
            {
              artist?.idCardUrl ? (
                <>
                : <a href={artist.idCardUrl} target='_blank'>สำเนาบัตรประชาชน</a>
                </>
              ):(
                <>
                : <span className='text-danger'>ยังไม่ได้อัปโหลด</span>
                </>
              )
            }
            </Col>
          </Row>
          <Row
            gutter={16}
            className='mt-4'>
            <Col span={4}>Book Bank</Col>
            <Col span={20}>
            {
              artist?.bookBankUrl ? (
                <>
                : <a href={artist.bookBankUrl} target='_blank'>สำเนาบัญชีธนาคาร</a>
                </>
              ):(
                <>
                : <span className='text-danger'>ยังไม่ได้อัปโหลด</span>
                </>
              )
            }
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
;