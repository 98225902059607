import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { RecommendCollectionCreatingModel } from '../models/recommendCollectionModel';

export class RecommendCollectionService {
  private api = 'recommendCollection';

  getList(page: number, size: number, name: string): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'Name': name,
      }
    return Axios.get(`${this.api}`, { params });
  }

  createRecommendCollection(request: RecommendCollectionCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}`, JSON.stringify(request));
  }

  getId(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/${id}`);
  }

  deleteRecommendCollectionItem(id: string, itemId: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}/RecommendCollectionItem/${itemId}`);
  }

  updateRecommendCollection(id: string, request: RecommendCollectionCreatingModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/${id}`, JSON.stringify(request));
  }

  deleteRecommendCollection(id: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}`);
  }

}