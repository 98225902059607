import React from 'react';
import ReactApexChart from 'react-apexcharts';

class EarningChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        colors: ['#1069C3', '#0295CF'],
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value.toLocaleString(undefined, { minimumFractionDigits: 0 });
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { seriesIndex }) {
              if (seriesIndex === 0) {
                return val / 1000 + ' รายการ';
              }
              return val.toLocaleString(undefined, { minimumFractionDigits: 0 }) + ' บาท';
            },
            title: {
              formatter: (seriesName) => {
                return seriesName;
              },
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div
        id='chart'
        className='w-100'>
        <ReactApexChart
          options={this.state.options}
          series={this.props.series}
          type='bar'
          height={450}
          className='w-100 chart'
        />
      </div>
    );
  }
}

export default EarningChart;
