export const enum ArtistStatus {
  EmpTy = '',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export const enum OrderStatus {
    EmpTy = '',
    Pending = 'Pending',
    Completed = 'Completed',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
  }

  export const enum PayoutStatus {
    EmpTy = '',
    Pending = 'Pending',
    Processing = 'Processing',
    Paid = 'Paid',
    Cancelled = 'Cancelled',
  }

  export const enum PayoutInfoStatus {
    Pending = 'Pending',
    Verified = 'Verified',
    Rejected = 'Rejected',
  }