export const enum PromotionType {
    Percentage = 'Percentage',
    Fixed = 'Fixed',
}

export const enum DiscountTargetType {
    All = 'All',
    Vip = 'Vip',
    NewCustomer = 'NewCustomer',
    SpecificCustomer = 'SpecificCustomer',
}

export const enum RoleType {
    Admin = 'Admin',
    Finance = 'Finance',
    Reviewer = 'Reviewer',
    Marketing = 'Marketing',
}