import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { BannerCreatingModel, BannerModel } from '../models/bannerModel';

export class BannerService {
  private api = 'banner';

  getBanners(page: number, size: number, name: string): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'Name': name,
      }
    return Axios.get(`${this.api}`, { params });
  }

  createBanner(request: BannerCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}`, JSON.stringify(request));
  }

  getId(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/${id}`);
  }

  deleteBannerItem(id: string, itemId: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}/BannerItem/${itemId}`);
  }

  updateBanner(bannerId: string, request: BannerModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/${bannerId}`, JSON.stringify(request));
  }

  deleteBanner(id: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}`);
  }

}