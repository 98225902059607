import { StringConstants } from '../../../constant/stringConstants';
import { ArtistTaxInfoAttachmentModel } from '../../../models/TaxCenterModel';

interface IndividualTaxCenterProps {
  attachment: ArtistTaxInfoAttachmentModel,
}

export default function AttachmentReadOnly({attachment}: IndividualTaxCenterProps) {
  return (
    <>
      <div className='upload-form-box'>
        <div className='d-flex gap-2'>
          {
            attachment.url &&
            attachment.url !== StringConstants.EMPTY
            ?
            <a href={attachment.url} target='_blank'>
              <span style={{color: '#1677ff'}}>
                {attachment.fileNameOriginal}
              </span>
            </a>
            : <span className='text-danger'>ยังไม่ได้อัปโหลด</span>
          }
        </div>
      </div>
    </>
  );
}
