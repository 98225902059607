import { Card, Row, Col, Form, Space, Button, Table, Pagination, Input, Badge } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';
import { RouteNames } from '../../components/Route/routeName';
import { ReportAssetInfoModel } from '../../models/reportAssetInfoModel';
import { AssetStatus, AssetType } from '../../constant/assetConstants';
import { ReportAssetInfoService } from '../../services/reportAssetInfoService';

export default function ReportAsset() {
    const [form] = Form.useForm();
    const [reportAssetInfos, setReportAssetInfos] = useState<ReportAssetInfoModel[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);

    const onFinish = () => {
        onGetListNewsContents(page, size);
    };

    useEffect(() => {
        onGetListNewsContents(page, size);
    }, []);

    const onReset = () => {
        form.resetFields();
        onGetListNewsContents(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListNewsContents(page, size);
    };

    const onGetListNewsContents = async (page: number, size: number) => {
        const { data, status } = await new ReportAssetInfoService().getList(
            page,
            size,
            form.getFieldValue('assetCode')
        );

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setReportAssetInfos(data.list);
    };

    const columns: ColumnsType<ReportAssetInfoModel> = [
        {
            title: 'Date',
            dataIndex: 'createAt',
            width: 200,
            align: 'center',
            render: (value, record, index) => (
                <>{record.createdInfo && moment(record.createdInfo.at).format(DateFormat.DateTime)}</>
            ),
        },
        {
            width: 120,
            key: 'assetStatus',
            title: 'Status',
            dataIndex: 'assetStatus',
            align: 'center',
            render: (value, record, index) =>
                record.asset.assetStatus === AssetStatus.DRAFT ? (
                    <Badge status='default' text={record.asset.assetStatus} />
                ) : record.asset.assetStatus === AssetStatus.PENDING ? (
                    <Badge status='processing' text={record.asset.assetStatus} />
                ) : record.asset.assetStatus === AssetStatus.COMPLETED ? (
                    <Badge status='success' text={record.asset.assetStatus} />
                ) : (
                    <Badge status='error' text={record.asset.assetStatus} />
                ),
        },
        {
            title: '',
            dataIndex: 'asset',
            width: 80,
            align: 'center',
            render: (value, record, index) =>
                record.asset &&
                (record.asset.assetType === AssetType.IMAGE ? (
                    <div style={{ position: 'relative', height: 50, width: 50 }}>
                        <img
                            src={record.asset.thumbnailImageUrl}
                            style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                        />
                    </div>
                ) : (
                    <div style={{ position: 'relative', height: 50, width: 50 }}>
                        <img
                            src={record.asset.thumbnailVideoCoverUrl}
                            style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                        />
                    </div>
                )),
        },
        {
            title: 'Asset Code',
            dataIndex: 'assetCode',
            width: 100,
            align: 'center',
            render: (value, record, index) => (
                <Link to={`${RouteNames.assetDetail}?id=${record.assetId}`}>{record.asset.code}</Link>
            ),
        },
        {
            title: 'Topic',
            dataIndex: 'topicName',
            width: 200,
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'otherDescription',
            width: 300,
            render: (value, record, index) => (
                <>{record.otherDescription ? (record.otherDescription):('-')}</>
            ),
            align: 'center',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 200,
            align: 'center',
        },
    ];

    return (
        <>
            <Card title='Report Asset' bordered={false}>
                <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                    <Row justify='center'>
                        <Col span={8}>
                            <Form.Item
                                name='assetCode'
                                className=' text-light w-100'
                                label='Asset Code'
                                rules={[{ required: false }]}
                            >
                                <Input placeholder='Asset Code' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='end'>
                        <Col span={24} className='text-right'>
                            <Space>
                                <Button type='primary' className='px-5' htmlType='submit'>
                                    Search
                                </Button>
                                <Button type='default' className='px-5' onClick={() => onReset()}>
                                    Clear
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <Table
                    className='mt-4'
                    columns={columns}
                    dataSource={reportAssetInfos}
                    pagination={false} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
