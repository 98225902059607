import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../../page/login';
import AssetRequests from '../../page/AssetRequests';
import AssetDetail from '../../page/AssetRequests/Detail';
import AssetEdit from '../../page/AssetRequests/Edit';
import AssetReview from '../../page/AssetRequests/Review';
import LayoutContent from '../Layout/Layout';
import PayoutRequest from '../../page/PayoutRequest';
import PayoutDetail from '../../page/PayoutRequest/Detail';
import Assets from '../../page/Assets';
import AssetsDetail from '../../page/Assets/Detail';
import AssetsCollection from '../../page/Assets/Collection';
import Orders from '../../page/Orders';
import OrdersDetail from '../../page/Orders/Detail';
import Releases from '../../page/Releases';
import ReleasesDetail from '../../page/Releases/Detail';
import Promotion from '../../page/Promotion';
import PromotionDetail from '../../page/Promotion/Detail';
import Artist from '../../page/Artist';
import ArtistDetail from '../../page/Artist/Detail';
import Customer from '../../page/Customer';
import CustomerDetail from '../../page/Customer/Detail';
import Admin from '../../page/Admin';
import AdminDetail from '../../page/Admin/Detail';
import Dashboard from '../../page/Dashboard';
import Banner from '../../page/Banner';
import BannerDetail from '../../page/Banner/Detail';
import RecommendAsset from '../../page/RecommendAsset';
import RecommendDetail from '../../page/RecommendAsset/Detail';
import RecommendCollections from '../../page/RecommendCollection';
import RecommendCollectionsDetail from '../../page/RecommendCollection/Detail';
import NewsContents from '../../page/NewsContents';
import Keywords from '../../page/Keywords';
import Eventlogs from '../../page/Eventlogs';
import ChangePassword from '../../page/ChangePassword';
import { RouteNames } from './routeName';
import AdminInvite from '../../page/Admin/Create';
import RecommendContentsDetail from '../../page/NewsContents/Detail';
import ReportAsset from '../../page/ReportAssetInfo';
import ContactUs from '../../page/ContactUs';
import Collection from '../../page/Collection';
import CollectionDetail from '../../page/Collection/Detail';
import DownloadHistory from '../../page/DownloadHistory';

function AppRoutes() {
  return (
    <>
      <BrowserRouter basename='/'>
        <Routes>
          <Route path="/dashboard" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/" index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/assetRequest" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<AssetRequests />} />
            <Route path="detail" element={<AssetDetail />} />
            <Route path="review" element={<AssetEdit />} />
          </Route>
          <Route path={RouteNames.payoutRequest} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<PayoutRequest />} />
            <Route path="detail" element={<PayoutDetail />} />
          </Route>
          <Route path="/asset" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Assets />} />
            <Route path="detail" element={<AssetsDetail />} />
            <Route path="collection" element={<AssetsCollection />} />
          </Route>
          <Route path="/order" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Orders />} />
            <Route path="detail" element={<OrdersDetail />} />
          </Route>
          <Route path="/download-history" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<DownloadHistory />} />
          </Route>
          <Route path={RouteNames.collection} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Collection />} />
            <Route path="detail" element={<CollectionDetail />} />
          </Route>
          <Route path="/releases" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Releases />} />
            <Route path="detail" element={<ReleasesDetail />} />
          </Route>
          <Route path={RouteNames.promotion} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Promotion />} />
            <Route path="Detail" element={<PromotionDetail />} />
          </Route>
          <Route path="/artist" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Artist />} />
            <Route path="detail/:userId" element={<ArtistDetail />} />
          </Route>
          <Route path="/customer" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Customer />} />
            <Route path="detail/:userId" element={<CustomerDetail />} />
          </Route>
          <Route path="/admin" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Admin />} />
            <Route path="invite-Admin" element={<AdminInvite />} />
            <Route path="detail/:id" element={<AdminDetail />} />
          </Route>
          <Route path="/banner" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Banner />} />
            <Route path="detail" element={<BannerDetail />} />
          </Route>
          <Route path={RouteNames.recommendAsset} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<RecommendAsset />} />
            <Route path="Detail" element={<RecommendDetail />} />
          </Route>
          <Route path={RouteNames.recommendCollection} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<RecommendCollections />} />
            <Route path="Detail" element={<RecommendCollectionsDetail />} />
          </Route>
          <Route path={RouteNames.newsContent} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<NewsContents />} />
            <Route path="Detail" element={<RecommendContentsDetail />} />
          </Route>
          <Route path="/keyword" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Keywords />} />
          </Route>
          <Route path="/eventlog" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<Eventlogs />} />
          </Route>
          <Route path="/change-password" element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<ChangePassword />} />
          </Route>
          <Route path={RouteNames.reportAsset} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<ReportAsset />} />
          </Route>
          <Route path={RouteNames.contactUs} element={<LayoutContent forceAuthentication={false} />}>
            <Route index element={<ContactUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;