import { Card, Row, Col, Form, Space, Pagination, message, Spin } from 'antd';
import Input from '../../components/Forms/Input';
import RadioEvent from '../../components/Forms/RadioEvent';
import Button from '../../components/General/Button';
import Table from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AccountService } from '../../services/accountService';
import { ISearchListCustomer } from '../../models/request/customerRequestModel';
import { HttpStatus } from '../../constant/responseStatus';
import { applicationUserModel } from '../../models/accountModel';
import { RouteNames } from '../../components/Route/routeName';
import { LoadingOutlined } from '@ant-design/icons';

const options = [
  {label: 'ALL', value: ''},
  {label: 'VIP', value: true},
];

export default function Customer() {
  const [customers, setCustomers] = useState<applicationUserModel[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const accountService = new AccountService();
  const [loading, setLoading] = useState<boolean>(false);

  const [ form ] = Form.useForm();


    const columns: ColumnsType<applicationUserModel> = [
        {
            title: 'Email',
            dataIndex: 'email',
            render: (value, record, index) => (
                <>
                  <Row justify='start'>
                    {record.emailInfo && record.emailInfo.emailAddress}
                  </Row>
                </>
            ),
            align: 'center',

        },
        {
            title: 'Customer Name',
            dataIndex: 'firstname',
            render: (value, record, index) => (
                <>{record.firstname && (record.firstname == null ? '' : record.firstname + ' ' + (record.lastname == null ? '' : record.lastname))}</>
            ),
            align: 'center'
        },
        {
            title: 'Company Name',
            dataIndex: 'company',
            render: (value, record, index) => (
                <>{record.companyInfo && (record.companyInfo.companyName)}</>
            ),
            align: 'center'
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            render: (value, record, index) => (
                <>{record.companyInfo && (record.companyInfo.branch)}</>
            ),
        },
        {
            title: 'Tax ID',
            dataIndex: 'taxId',
            render: (value, record, index) => (
                <>{record.companyInfo && (record.companyInfo.taxId)}</>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (value, record, index) => (
                <>
                    <Link to={`${RouteNames.customerDetail}/${record.id}`}>Detail</Link>
                </>
            ),
        },
    ];

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = (value: any) => {
    const model: ISearchListCustomer = {
      email: value.email,
      name: value.Company,
      isVip: value.status === '' ? undefined : value.status,
    }

    getListCustomerData(1,10, model);
  };

  const getListCustomerData = async (page: number, size: number, search?: ISearchListCustomer) => {
    setLoading(true);
    const { data, status } = await accountService.getCustomerListAsync(page, size, search);

    if (status !== HttpStatus.OK) {
        message.error('get customer failed.');
        setLoading(false);
      return;
    }

    // var sortData = (data.list as applicationUserModel []).sort((a, b) => a.emailInfo.localeCompare(b.name));
    setTotalData(data.paging.totalRows);
    setCustomers(data.list);
    setLoading(false);
  }

  useEffect(() => {
    getListCustomerData(page, size);
  }, [page, size]);

  const onChangePage = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  }

  return (
    <>
      <Card title="Customer" bordered={false}>
        <Card className="card-form">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder="Customer Name / Company Name"
                  name="Company"
                  label="Customer Name / Company Name"
                />
              </Col>
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder="Email"
                  name="email"
                  label="Email"
                />
              </Col>
              <Col span={6} />
            </Row>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <RadioEvent
                  name="status"
                  label="Customer Type"
                  options={options}
                />
              </Col>
              <Col span={15} />
            </Row>
            <Row justify="end">
              <Col span={24} className="text-right">
                <Space wrap>
                  <Button
                    type='primary'
                    text='Search'
                    className='px-5'
                    onClick={() => form.submit()}
                  />
                  <Button
                    type='default'
                    text='Clear'
                    className='px-5'
                    onClick={() => {
                      form.resetFields();
                      onChangePage(1,10);
                      getListCustomerData(1,10);
                      setPage(1);
                      setSize(10);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <Row>
            <Col span={24}>
              <Table className="mt-4" columns={columns} dataSource={customers} />
            </Col>
            <Col span={24} className='text-right pt-3'>
              <Pagination 
                current={page} 
                pageSize={size}
                defaultCurrent={1} 
                total={totalData} 
                onChange={onChangePage} />
            </Col>
          </Row>
        </Spin>
      </Card>
    </>
  );
};