import { Card, Row, Col, Form, Space, Pagination } from 'antd';
import Input from '../../components/Forms/Input';
import RadioEvent from '../../components/Forms/RadioEvent';
import Button from '../../components/General/Button';
import Table, { TableDataType, Status } from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { VscCircleFilled } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { AccountService } from '../../services/accountService';
import { HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { ArtistStatus } from '../../constant/statusConstants';
import { ISearchListArtist } from '../../models/request/artistRequestModel';

const options = [
  { label: 'All', value: '' },
  { label: ArtistStatus.Pending, value: ArtistStatus.Pending },
  { label: ArtistStatus.Approved, value: ArtistStatus.Approved },
  { label: ArtistStatus.Rejected, value: ArtistStatus.Rejected },
];

interface IArtist extends TableDataType {
  key: React.Key;
  userCode: string;
  artist: string;
  email: string;
  publishAssetQty: string;
  total: string;
  status: ArtistStatus;
  action: any;
}

const columns: ColumnsType<IArtist> = [
  {
    title: 'User Code',
    dataIndex: 'userCode',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text) => <p className='text-left'>{text}</p>,
    align: 'center',
  },
  {
    title: 'Artist Name',
    dataIndex: 'artist',
    render: (text) => <p className='text-left'>{text}</p>,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (text) => <p className='text-left'><VscCircleFilled className={`${Status(text)} me-2`} />{text}</p>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (text) => <p>{text}</p>,
    align: 'center',
  },
];

const Artist: React.FC = () => {
  const [data, setData] = useState<IArtist[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const accountService = new AccountService();

  const [form] = Form.useForm();

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = (value: any) => {
    const model: ISearchListArtist = {
      Email: value.email,
      Name: value.name,
      Status: value.status,
    }

    getListArtistAsync(1,10, model);
  };

  const getListArtistAsync = async (page: number = 1, size: number = 10, searchModel?: ISearchListArtist) => {
    const { data, status } = await accountService.getArtistsAsync(page, size, searchModel);

    if (status !== HttpStatusCode.Ok) {
      alert(`Can't get artist data`);

      return;
    }

    const listArtist = data.list;
    const dataArtist: IArtist[] = [];

    listArtist.forEach((a) => {
      if (a.artist) {
        dataArtist.push({
          artist: a.artist?.nameInfoEn?.firstname + ' ' + a.artist?.nameInfoEn?.lastname,
          action: <Link to={`/artist/detail/${a.userId}`}>Detail</Link>,
          email: a.artist.email,
          publishAssetQty: '',
          total: '',
          userCode: a.artist.code,
          key: a.artist.id,
          status: a.artist.status,
        });
      }
    });

    setTotalData(data.paging.totalRows);
    setData(dataArtist);
  }

  useEffect(() => {
    getListArtistAsync(page, size);
  }, [page, size]);

  const onChangePage = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  }

  return (
    <>
      <Card title="Artist" bordered={false}>
        <Card className='card-form'>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder='Email'
                  name='email'
                  label='Email'
                />
              </Col>
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder='Artist Name'
                  name='name'
                  label='Artist Name'
                />
              </Col>
              <Col span={6} />
            </Row>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <RadioEvent
                  name='status'
                  label='Status'
                  options={options}
                />
              </Col>
              <Col span={15} />
            </Row>
            <Row justify="end">
              <Col span={24} className='text-right'>
                <Space wrap>
                  <Button
                    type='primary'
                    text='Search'
                    className='px-5'
                    onClick={() => form.submit()}
                  />
                  <Button
                    text='Clear'
                    className='px-5'
                    onClick={() => {
                      form.resetFields();
                      setPage(1);
                      setSize(10);
                      getListArtistAsync(1,10);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row>
          <Col span={24}>
            <Table className="mt-4" columns={columns} dataSource={data} />
          </Col>
          <Col span={24} className='text-right pt-3'>
            <Pagination 
            current={page} 
            defaultCurrent={1} 
            pageSize={size}
            total={totalData} onChange={onChangePage} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Artist;