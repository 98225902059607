import { Card, Col, Form, Modal, Row, Space, Statistic, Tabs, message } from 'antd';
import Button from '../General/Button';
import ArtistDetail from './Artist';
import IndividualDetail from './Individual';
import CompanyDetail from './Company';
import { CustomerTab } from '../../constant/constants';
import { useCallback, useEffect, useState } from 'react';
import { AccountService } from '../../services/accountService';
import { useParams } from 'react-router-dom';
import { AddressInfoModel, ArtistCreatingModel, ArtistModel, UpdCompanyInfoModel, UpdIndividualInfoModel, ContactPersonModel } from '../../models/accountModel';
import { HttpStatus } from '../../constant/responseStatus';
import ICompanyResponse from '../../models/response/CompanyResponse';
import IIndividualResponse from '../../models/response/IndividualResponse';
import IAccountInfoResponse from '../../models/response/AccountInfoResponse';
import { ArtistStatus } from '../../constant/statusConstants';
import { MasterDataService } from '../../services/masterDataService';
import { MasterDataResponse } from '../../models/response/MasterDataResponse';
import { MasterDataType } from '../../constant/masterDataConstants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ArtistService } from '../../services/artistService';
import { RUserLicenseService } from '../../services/userLicensesService';
import ArtistTaxCenterDetail from './ArtistTaxCenter';
import _ from 'lodash';

const initialTabsWithOutArtist = [
  { name: CustomerTab.Individual, key: CustomerTab.Individual },
  { name: CustomerTab.Company, key: CustomerTab.Company },
];

export default function CustomerFormDetail(props: { defaultTab: string }) {
  const [tabsName, setTabsName] = useState(initialTabsWithOutArtist);
  const [tabValue, setTabValue] = useState<string>(props.defaultTab);
  const [individual, setIndividual] = useState<IIndividualResponse>();
  const [company, setCompany] = useState<ICompanyResponse>();
  const [artist, setArtist] = useState<ArtistModel>();
  const [styleOfWork, setStyleOfWork] = useState<MasterDataResponse[]>([]);
  const [platForm, setPlatForm] = useState<MasterDataResponse[]>([]);
  const [artistId, setArtistId] = useState<string>('');
  const [assetCount, setAssetCount] = useState(0);
  const [activeUser, setActiveUser] = useState<boolean>(true);
  const { userId } = useParams();
  const accountService = new AccountService();
  const [useFormIndividual] = Form.useForm();
  const [useFormCompany] = Form.useForm();
  const [useFormArtist] = Form.useForm();

  const { confirm, error } = Modal;

  const onSubmitIndividual = async (form: any) => {
    const updIndividual = {
        firstName: form.firstname,
        lastName: form.lastName,
        email: form.email,
        phone: form.phone,
        address: {
            address: form.address,
            postcode: useFormIndividual.getFieldValue('postcode'),
            provinceCode: useFormIndividual.getFieldValue('provinceCode'),
            districtCode: useFormIndividual.getFieldValue('districtCode'),
            subDistrictCode: useFormIndividual.getFieldValue('subDistrictCode'),
        } as AddressInfoModel,
    } as UpdIndividualInfoModel

    const response = await accountService.updateIndividualAsync(updIndividual, userId!);

    if (response.status !== HttpStatus.OK) {
      message.error('Save Customer failed.');

      return;
    }

    message.success('Save Customer success.');
    if(userId){
      getAccountInfo(userId);
    }
  };

  const onSubmitCompany = async (form: any) => {
    const updCompany = {
        companyName: form.companyName,
        taxId: form.taxId,
        branch: form.branch,
        companyWebsite: form.companyWebsite,
        address: {
            address: form.address,
            postcode: useFormCompany.getFieldValue('postcode'),
            provinceCode: useFormCompany.getFieldValue('provinceCode'),
            districtCode: useFormCompany.getFieldValue('districtCode'),
            subDistrictCode: useFormCompany.getFieldValue('subDistrictCode'),
        } as AddressInfoModel,
        contactPerson: {
            firstname: form.contactFirstName,
            lastName: form.contactLastName,
            email: form.contactEmail,
            phone: form.contactPhone,
            jobTitle: form.jobTitle,
            department: form.department,
            purchaseFor: form.purchasingFor,
        } as ContactPersonModel,
    } as UpdCompanyInfoModel

    const isVip = useFormCompany.getFieldValue('isVip');
    const quantity = useFormCompany.getFieldValue('quality');
    const creditTerm = useFormCompany.getFieldValue('paymentTerm');
    const response = await accountService.updateCompanyAsync(updCompany, userId!);

    if (response.status !== HttpStatus.OK) {
        message.error('Save Customer failed.');

      return;
    }

    const responseVip = await accountService.updateCompanyVipAsync(isVip, quantity, creditTerm, userId!);

    if (responseVip.status !== HttpStatus.OK) {
        message.error('Save Customer vip failed.');

      return;
    }

    message.success('Save Customer success.');
    if(userId){
      useFormCompany.setFieldValue('quality', '');
      getAccountInfo(userId);
    }
  };

  const onSubmitArtist = async (values: any) => {
    const artistUpdate = {
      nameInfoEN_Firstname: values.firstname,
      nameInfoEN_Lastname: values.lastname,
      nameInfoEN_Nickname: values.nickname,
      nameInfoTH_Firstname: values.firstnameTh,
      nameInfoTH_Lastname: values.lastnameTh,
      nameInfoTH_Nickname: values.nicknameTh,
      email: values.email,
      phone: values.phone,
      addressInfo_Address: values.address,
      addressInfo_Postcode: values.Postcode,
      addressInfo_ProvinceCode: values.ProvinceId,
      addressInfo_DistrictCode: values.DistrictId,
      addressInfo_SubDistrictCode: values.SubDistriceId,
      portfolio: values.portfolio,
      socialMediaInfo_Facebook: values.facebook,
      socialMediaInfo_Instagram: values.instagram,
      socialMediaInfo_Tiktok: values.tiktok,
      socialMediaInfo_Other: values.other,
      aboutYourSelf: values.aboutYourSelf,
      selfPromotion: values.selfPromotion,
      styleOfWork: JSON.stringify(values.styleOfWork),
      styleOfWork_Other: values.styleOfWorkOther,
      platform: JSON.stringify(values.platForm),
      platform_Mysite: values.platFormMySite,
      platform_Other: values.platFormOther,
      isAccept: true,
      Status: artist?.status ?? ArtistStatus.Pending,
      password: values.password,
    } as ArtistCreatingModel;

    const { status } = await accountService.updateArtistAsync(artistUpdate, artistId ?? '');

    if (status !== HttpStatus.OK) {
        message.error('Save artist failed.');

      return;
    }

    message.success('Save artist success.');
    if(userId){
      getAccountInfo(userId);
    }
  };

  const getAccountInfo = async (userId: string) => {
    const { data, status } = await accountService.getAccountInfoAsync<IAccountInfoResponse[]>(userId);

    if (status !== HttpStatus.OK) {
        message.error('Get Customer failed.');

      return;
    }

    setActiveUser(data[0].isActive);

    if (data[0].individualInfo) {
      const individual: IIndividualResponse = data[0].individualInfo;
      setIndividual(individual);

      useFormIndividual.setFieldsValue({
        firstname: individual.firstname,
        lastName: individual.lastName,
        email: individual.email,
        phone: individual.phone,
        address: individual.addressInfo.address,
        postcode: individual.addressInfo.postcode,
        provinceCode: individual.addressInfo.provinceCode,
        districtCode: individual.addressInfo.districtCode,
        subDistrictCode: individual.addressInfo.subDistrictCode
      });
    }

    if (data[0].companyInfo) {
      const companyInfo: ICompanyResponse = data[0].companyInfo;
      if(data[0].vipDownloads){
        companyInfo.vipDownloads = data[0].vipDownloads;
      }
      if(data[0].vipDownloadQty){
        companyInfo.vipDownloadQty = data[0].vipDownloadQty;
      }
      setCompany(companyInfo);

      useFormCompany.setFieldsValue({
        companyWebsite: companyInfo?.companyWebsite,
        branch: companyInfo?.branch,
        address: companyInfo?.addressInfo.address,
        companyName: companyInfo?.companyName,
        contactEmail: companyInfo?.contactPerson.email,
        contactFirstName: companyInfo?.contactPerson.firstname,
        contactLastName: companyInfo?.contactPerson.lastName,
        contactPhone: companyInfo?.contactPerson.phone,
        department: companyInfo?.contactPerson.department,
        districtCode: companyInfo?.addressInfo.districtCode,
        jobTitle: companyInfo?.contactPerson.jobTitle,
        postcode: companyInfo?.addressInfo.postcode,
        provinceCode: companyInfo?.addressInfo.provinceCode,
        purchasingFor: companyInfo?.contactPerson.purchaseFor,
        subDistrictCode: companyInfo?.addressInfo.subDistrictCode,
        taxId: companyInfo?.taxId,
        isVip: companyInfo?.isVip,
        paymentTerm: companyInfo?.paymentTerm,
      });
    }

    if (data[0].artist) {
      const artist: ArtistModel = data[0].artist;
      setArtistId(artist.id);
      setArtist(artist);
      getStyleOfWorkAsync();
      getPlatformAsync();

      if (!tabsName.some(obj => obj.name === CustomerTab.Artist)) {
        setTabsName([
          ...tabsName,
          { name: CustomerTab.Artist, key: CustomerTab.Artist },
          { name: CustomerTab.TaxCenter, key: CustomerTab.TaxCenter },
        ]);
      }

      useFormArtist.setFieldsValue({
        email: artist.email,
        phone: artist.phone,
        firstname: artist.nameInfoEn.firstname,
        lastname: artist.nameInfoEn.lastname,
        nickname: artist.nameInfoEn.nickname,
        firstnameTh: artist.nameInfoTh.firstname,
        lastnameTh: artist.nameInfoTh.lastname,
        nicknameTh: artist.nameInfoTh.nickname,
        address: artist.addressInfo.address,
        ProvinceId: artist.addressInfo.provinceCode,
        DistrictId: artist.addressInfo.districtCode,
        SubDistrictId: artist.addressInfo.subDistrictCode,
        Postcode: artist.addressInfo.postcode,
        portfolio: artist.portfolio,
        facebook: artist.socialMediaInfo.facebook,
        instagram: artist.socialMediaInfo.instagram,
        tiktok: artist.socialMediaInfo.tiktok,
        other: artist.socialMediaInfo.other,
        aboutYourSelf: artist.aboutYourSelf,
        selfPromotion: artist.selfPromotion,
        styleOfWorkOther: artist.styleOfWorkOther,
        platFormMySite: artist.platformMySite,
        platFormOther: artist.platformOther,
        status: artist.status
      });
    }
  };

  useEffect(() => {
    if (userId) {
        getAccountInfo(userId);
        getLicenseListAsync();
    }
  }, [userId]);

  const submitByTab = useCallback((tabValue: string) => {
    switch (tabValue) {
      case CustomerTab.Individual:
        useFormIndividual.submit();
        break;
      case CustomerTab.Company:
        useFormCompany.submit();
        break;
      case CustomerTab.Artist:
        useFormArtist.submit();
        break;
    }
  }, []);

  const deActiveAccount = (active: boolean) => {
    confirm({
      title: `Do you want to ${active ? 'deactive' : 'active'}?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new AccountService().activeUser(artist?.id!, active);

        if (status !== HttpStatus.OK) {
            message.error('Save Customer failed.');

          return;
        }

        message.success('Save Customer success.');

        getAccountInfo(userId!);
      },
    });
  }

  const onChangeTabs = (index: string) => {
    setTabValue(index);
  };

  const renderTabItem = (tabValue: string) => {
    switch (tabValue) {
      case CustomerTab.Individual:
        return <IndividualDetail individual={individual} useFormIndividual={useFormIndividual} onSubmitIndividual={onSubmitIndividual} />;
      case CustomerTab.Company:
        return <CompanyDetail companyInfo={company} useFormCompany={useFormCompany} onSubmitCompany={onSubmitCompany} />;
      case CustomerTab.Artist:
        return <ArtistDetail artist={artist} useFormArtist={useFormArtist} onSubmitArtist={onSubmitArtist} platForm={platForm} styleOfWork={styleOfWork} />;
      case CustomerTab.TaxCenter:
        return <ArtistTaxCenterDetail userId={userId!} />;
      default:
        return <></>;
    }
  };

  const getStyleOfWorkAsync = useCallback(async () => {
    const {
      data,
      status
    } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(MasterDataType.STYLE_OF_WORK);

    if (status !== HttpStatus.OK) {
      return;
    }

    setStyleOfWork(data);
  }, []);

  const getPlatformAsync = useCallback(async () => {
    const { data, status } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(MasterDataType.PLATFORM);

    if (status !== HttpStatus.OK) {
      return;
    }

    setPlatForm(data);
  }, []);

  const getLicenseListAsync = useCallback(async () => {
    const { data, status } = await new RUserLicenseService().getLicenseList(userId!);

    if (status !== HttpStatus.OK) {
        setAssetCount(0);
      return;
    }

    const GrpAsset = _(data)
            .groupBy((item) => {
                return item.assetPublishDetailModel.id;
            })
            .map(function (userAssetLicenses, assetId) {
                return { assetId: assetId, userAssetLicenses: userAssetLicenses };
            })
            .value();

    setAssetCount(GrpAsset.length);
  }, []);

  const approveArtistAsync = () => {
    confirm({
      title: `Do you want to approve?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new ArtistService().approve(artist?.id!);

        if (status !== HttpStatus.OK) {
            message.error('Save Artist failed.');

          return;
        }

        message.success('Save Artist success.');

        getAccountInfo(userId!);
      },
    });
  }

  const rejectArtistAsync = () => {
    confirm({
      title: `Do you want to reject?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const { status } = await new ArtistService().reject(artist?.id!);

        if (status !== HttpStatus.OK) {
            message.error('Save Artist failed.');

          return;
        }

        message.success('Save Artist success.');

        getAccountInfo(userId!);
      },
    });
  }

  return (
    <>
      <Card className="card-customize"
        title="Detail"
        extra=
          {
            tabValue !== CustomerTab.TaxCenter &&
            <Space align="center">
              <Button
                danger
                type="default"
                text={`${activeUser ? 'Deactive' : 'Active'}`}
                onClick={() => deActiveAccount(activeUser)}
              />
              <Button
                text="Save"
                onClick={() => submitByTab(tabValue)}
              />
              {
                tabValue === CustomerTab.Artist && artist?.status === ArtistStatus.Pending &&
                <Button
                  type="primary"
                  text="Approve"
                  onClick={() => approveArtistAsync()}
                />
              }
              {
                tabValue === CustomerTab.Artist
                && (
                  artist?.status === ArtistStatus.Pending) &&
                <Button
                  danger
                  type="primary"
                  text="Reject"
                  onClick={() => rejectArtistAsync()}
                />
              }
            </Space>
        }
      >
        <div className="p-4">
            <Row>
                <Col>
                    <Statistic title="My Asset" value={assetCount} />
                </Col>
            </Row>
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={tabValue}
                tabPosition={'top'}
                onChange={onChangeTabs}
                items={tabsName.map((tab, index) => {
                  return {
                    label: `${tab.name}`,
                    key: tab.key,
                    children: renderTabItem(tabValue)
                  };
                })} />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}