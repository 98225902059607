import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { OrderSearchModel } from '../models/OrderModel';

export class OrderService {
  private api = 'order';
  private adminOrderApi = 'admin/order';

  getList(page: number, size: number, request: OrderSearchModel): Promise<AxiosResponse> {
    const params = {
      'Paging.Page': page,
      'Paging.Size': size,
      OrderCode: request.orderCode,
      CustomerName: request.customerName,
      Status: request.status,
      PaymentMethod: request.paymentMethod,
      'OrderDate.Start': request.startDate,
      'OrderDate.End': request.endDate,
    };
    return Axios.get(`${this.api}`, { params });
  }

  getId(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.adminOrderApi}/${id}/detail`);
  }

  updateApprove(id: string): Promise<AxiosResponse> {
    return Axios.put(`${this.adminOrderApi}/${id}/approve`);
  }

  updateCancel(id: string): Promise<AxiosResponse> {
    return Axios.put(`${this.adminOrderApi}/${id}/cancel`);
  }

  updateSpecialDiscount(id: string, specialDiscount: number): Promise<AxiosResponse> {
    return Axios.put(`${this.adminOrderApi}/${id}/special-discount?specialDiscountTotal=${specialDiscount}`);
  }

  ExportCsv(request: OrderSearchModel): Promise<AxiosResponse> {
    const params = {
      OrderCode: request.orderCode,
      CustomerName: request.customerName,
      Status: request.status,
      PaymentMethod: request.paymentMethod,
      'StartDate': request.startDate,
      'EndDate': request.endDate,
    };
    return Axios.get(`${this.adminOrderApi}/export`,
    {
      params,
      responseType: 'blob'
    });
  }
}
