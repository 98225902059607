import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { 
  AssetDetailModel, 
  RejectAssetModel, 
  AssetListSearchModel, 
  AssetPublishModel } from '../models/assetModel';

export class AssetService {
  private api = 'asset';
  private apiAdmin = 'admin/asset';

  getAssetDetailAsync(id: string): Promise<AxiosResponse<AssetDetailModel>> {
    return Axios.get<AssetDetailModel>(`${this.apiAdmin}/editAsset/${id}`);
  }

  update(model: AssetDetailModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}`, JSON.stringify(model));
  }

  approve(model: AssetDetailModel): Promise<AxiosResponse> {
    return Axios.put(`${this.apiAdmin}/approve`, JSON.stringify(model));
  }

  reject(model: RejectAssetModel): Promise<AxiosResponse> {
    return Axios.put(`${this.apiAdmin}/reject`, JSON.stringify(model));
  }

  getList(page: number, size: number, model: AssetListSearchModel): Promise<AxiosResponse> {
    const params = {
      'Paging.Page': page,
      'Paging.Size': size,
      'AssetType': model.assetType,
      'AssetCode': model.assetCode,
      'AssetName': model.assetName,
      'Email': model.email,
      'ArtistName': model.artistName,
      'PublishDate.Start': model.startPublishDate,
      'PublishDate.End': model.endPublishDate,
      'AssetStatus': model.assetStatus,
    }
    return Axios.get(`${this.api}`, { params });
  }

  getPublish(id: string): Promise<AxiosResponse<AssetPublishModel>> {
    return Axios.get<AssetPublishModel>(`${this.api}/published/${id}`);
  }

  getAssetByReleaseId(size: number, id: string, search: string | undefined): Promise<AxiosResponse> {
    const params = {
      'Search': search,
      'Offset': size,
      'ReleaseId': id,
    }
    return Axios.get(`${this.api}/Release`, { params });
  }

  disable(id: string): Promise<AxiosResponse> {
    return Axios.put(`${this.apiAdmin}/disable/${id}`);
  }

  enable(id: string): Promise<AxiosResponse> {
    return Axios.put(`${this.apiAdmin}/enable/${id}`);
  }

  abandon(id: string): Promise<AxiosResponse> {
    return Axios.put(`${this.apiAdmin}/abandon/${id}`);
  }

  generateImageKeywords(imageUrl: string): Promise<AxiosResponse<String[]>> {
    return Axios.get<string[]>(`${this.apiAdmin}/generateKeywords?ImageUrl=${imageUrl}`);
  }

  getAssetByCode(code: string): Promise<AxiosResponse<AssetPublishModel>> {
    const params = {
        "assetCode" : code
    }
    return Axios.get<AssetPublishModel>(`${this.api}/assetCode`, { params });
  }
}