import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { RecommendAssetCreatingModel } from '../models/recommendAssetModel';

export class RecommendAssetService {
  private api = 'recommendAsset';

  getList(page: number, size: number, name: string): Promise<AxiosResponse> {
    const params = {
        'Paging.Page': page,
        'Paging.Size': size,
        'Name': name,
      }
    return Axios.get(`${this.api}`, { params });
  }

  createRecommendAsset(request: RecommendAssetCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.api}`, JSON.stringify(request));
  }

  getId(id: string): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/${id}`);
  }

  deleteRecommendAssetItem(id: string, itemId: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}/RecommendAssetItem/${itemId}`);
  }

  updateRecommendAsset(id: string, request: RecommendAssetCreatingModel): Promise<AxiosResponse> {
    return Axios.put(`${this.api}/${id}`, JSON.stringify(request));
  }

  deleteRecommendAsset(id: string): Promise<AxiosResponse> {
    return Axios.delete(`${this.api}/${id}`);
  }
}