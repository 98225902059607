import { Button } from 'antd';
import { ButtonType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';

interface Props {
  type?: ButtonType;
  text?: string;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  shape?: "circle" | "default" | "round" | undefined;
  size?: SizeType;
  Icon?: JSX.Element | undefined;
  htmlType?: "submit" | "button" | "reset" | undefined;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>;
}

function Buttons({ type, text, className, danger, onClick, disabled, shape, size = 'large', Icon, htmlType = 'submit', loading }: Props) {
  return (
    <Button
      type={type}
      size={size}
      className={className}
      danger={danger}
      onClick={onClick}
      disabled={disabled}
      shape={shape}
      icon={Icon}
      loading={loading}
      htmlType={htmlType}>
      {text}
    </Button>
  );
}

export default Buttons;