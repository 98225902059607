import { Menu, MenuProps, Typography } from 'antd';
import {
  AiOutlineBarChart,
  AiOutlineCamera,
  AiOutlineCheckCircle,
  AiOutlineCrown,
  AiOutlineDollar,
  AiOutlineFileProtect,
  AiOutlineLayout,
  AiOutlineLogout,
  AiOutlineMail,
  AiOutlineShopping,
  AiOutlineStar,
  AiOutlineTag,
  AiOutlineUnlock,
  AiOutlineUser,
  AiOutlineWarning,
  AiOutlineYoutube,
  AiOutlineInsertRowBelow,
} from 'react-icons/ai';
import NavLink from './NavLink';
import { RouteNames } from '../Route/routeName';
import { useSessionContext } from '../../context/session-context';
import { useEffect, useState } from 'react';
import { HistoryOutlined, MessageOutlined, WarningOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { RoleType } from '../../constant/typeConstants';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function MenuComponent(props: { selectedMenu: string }) {
  const { signOut, userProfile, getUserProfileAsync } = useSessionContext();
  const navigate = useNavigate();
  const [activePath, setActivePath] = useState([window.location.pathname]);
  const [menus, setMenus] = useState<MenuProps['items']>([]);

  useEffect(() => {
    if (userProfile && userProfile.userId && userProfile.roles.length > 0) {
      if (userProfile.roles.filter((r) => r == RoleType.Admin).length > 0) {
        setMenus(AdminMenus);
      } else if (userProfile.roles.filter((r) => r == RoleType.Reviewer).length > 0) {
        setMenus(ReviewerMenus);
      } else if (userProfile.roles.filter((r) => r == RoleType.Finance).length > 0) {
        setMenus(FinancialMenus);
      } else if (userProfile.roles.filter((r) => r == RoleType.Marketing).length > 0) {
        setMenus(MarketingMenus);
      } else {
        setMenus([]);
      }
    } else {
      getUserProfileAsync();
    }
  }, [userProfile]);

  useEffect(() => {
    const parthName = window.location.pathname;

    if (parthName) {
      setActivePath([parthName]);
    }
  }, [window.location.pathname]);

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      signOut();

      return;
    }

    if (activePath[0] !== null && e.key === activePath[0]) {
      window.location.reload();
    }
  };

  const AdminMenus: MenuProps['items'] = [
    getItem(
      null,
      'administrator',
      null,
      [
        getItem(
          <Link to={RouteNames.dashboard}>Dashboard</Link>,
          RouteNames.dashboard,
          <AiOutlineBarChart className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.assetRequests}>Asset Request</Link>,
          RouteNames.assetRequests,
          <AiOutlineCheckCircle className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.payoutRequest}>Payout Request</Link>,
          RouteNames.payoutRequest,
          <AiOutlineDollar className='fs-5' />
        ),
        getItem(<Link to={RouteNames.asset}>Asset</Link>, RouteNames.asset, <AiOutlineYoutube className='fs-5' />),
        getItem(
          <Link to={RouteNames.collection}>Collection</Link>,
          RouteNames.collection,
          <AiOutlineInsertRowBelow className='fs-5' />
        ),
        getItem(<Link to={RouteNames.orders}>Order</Link>, RouteNames.orders, <AiOutlineShopping className='fs-5' />),
        getItem(
          <Link to={RouteNames.downloadHistory}>Download History</Link>,
          RouteNames.downloadHistory,
          <HistoryOutlined className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.release}>Releases</Link>,
          RouteNames.release,
          <AiOutlineFileProtect className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.promotion}>Promotion</Link>,
          RouteNames.promotion,
          <AiOutlineStar className='fs-5' />
        ),
        getItem(<Link to={RouteNames.artist}>Artist</Link>, RouteNames.artist, <AiOutlineCamera className='fs-5' />),
        getItem(
          <Link to={RouteNames.customer}>Customer</Link>,
          RouteNames.customer,
          <AiOutlineUser className='fs-5' />
        ),
        getItem(<Link to={RouteNames.admin}>Admin</Link>, RouteNames.admin, <AiOutlineUser className='fs-5' />),
        getItem(
          <Link to={RouteNames.reportAsset}>Report Asset</Link>,
          RouteNames.reportAsset,
          <WarningOutlined className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.contactUs}>Contact Us</Link>,
          RouteNames.contactUs,
          <MessageOutlined className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Setting',
      'setting',
      null,
      [
        getItem(
          <Link to={RouteNames.banner}>Banner Setup</Link>,
          RouteNames.banner,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendAsset}>Recommend Asset</Link>,
          RouteNames.recommendAsset,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendCollection}>Recommend Collection</Link>,
          RouteNames.recommendCollection,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.newsContent}>News Contents</Link>,
          RouteNames.newsContent,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(<Link to={RouteNames.keywords}>Keyword</Link>, RouteNames.keywords, <AiOutlineTag className='fs-5' />),
        getItem(
          <Link to={RouteNames.eventlogs}>Event log</Link>,
          RouteNames.eventlogs,
          <AiOutlineWarning className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Account Setting',
      'accountSetting',
      null,
      [
        getItem(
          <Link to={RouteNames.changePassword}>Change Password</Link>,
          RouteNames.changePassword,
          <AiOutlineUnlock className='fs-5' />
        ),
        getItem('Logout', 'logout', <AiOutlineLogout className='fs-5' />),
      ],
      'group'
    ),
  ];

  const ReviewerMenus: MenuProps['items'] = [
    getItem(
      null,
      'administrator',
      null,
      [
        getItem(
          <Link to={RouteNames.dashboard}>Dashboard</Link>,
          RouteNames.dashboard,
          <AiOutlineBarChart className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.assetRequests}>Asset Request</Link>,
          RouteNames.assetRequests,
          <AiOutlineCheckCircle className='fs-5' />
        ),
        getItem(<Link to={RouteNames.asset}>Asset</Link>, RouteNames.asset, <AiOutlineYoutube className='fs-5' />),
        getItem(
          <Link to={RouteNames.downloadHistory}>Download History</Link>,
          RouteNames.downloadHistory,
          <HistoryOutlined className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.collection}>Collection</Link>,
          RouteNames.collection,
          <AiOutlineInsertRowBelow className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.release}>Releases</Link>,
          RouteNames.release,
          <AiOutlineFileProtect className='fs-5' />
        ),
        getItem(<Link to={RouteNames.artist}>Artist</Link>, RouteNames.artist, <AiOutlineCamera className='fs-5' />),
        getItem(
          <Link to={RouteNames.customer}>Customer</Link>,
          RouteNames.customer,
          <AiOutlineUser className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.reportAsset}>Report Asset</Link>,
          RouteNames.reportAsset,
          <WarningOutlined className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.contactUs}>Contact Us</Link>,
          RouteNames.contactUs,
          <MessageOutlined className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Setting',
      'setting',
      null,
      [
        getItem(
          <Link to={RouteNames.banner}>Banner Setup</Link>,
          RouteNames.banner,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendAsset}>Recommend Asset</Link>,
          RouteNames.recommendAsset,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendCollection}>Recommend Collection</Link>,
          RouteNames.recommendCollection,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.newsContent}>News Contents</Link>,
          RouteNames.newsContent,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(<Link to={RouteNames.keywords}>Keyword</Link>, RouteNames.keywords, <AiOutlineTag className='fs-5' />),
        getItem(
          <Link to={RouteNames.eventlogs}>Event log</Link>,
          RouteNames.eventlogs,
          <AiOutlineWarning className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Account Setting',
      'accountSetting',
      null,
      [
        getItem(
          <Link to={RouteNames.changePassword}>Change Password</Link>,
          RouteNames.changePassword,
          <AiOutlineUnlock className='fs-5' />
        ),
        getItem('Logout', 'logout', <AiOutlineLogout className='fs-5' />),
      ],
      'group'
    ),
  ];

  const FinancialMenus: MenuProps['items'] = [
    getItem(
      null,
      'administrator',
      null,
      [
        getItem(
          <Link to={RouteNames.payoutRequest}>Payout Request</Link>,
          RouteNames.payoutRequest,
          <AiOutlineDollar className='fs-5' />
        ),
        getItem(<Link to={RouteNames.orders}>Order</Link>, RouteNames.orders, <AiOutlineShopping className='fs-5' />),
        getItem(
          <Link to={RouteNames.downloadHistory}>Download History</Link>,
          RouteNames.downloadHistory,
          <HistoryOutlined className='fs-5' />
        ),
        getItem(<Link to={RouteNames.artist}>Artist</Link>, RouteNames.artist, <AiOutlineCamera className='fs-5' />),
        getItem(
          <Link to={RouteNames.customer}>Customer</Link>,
          RouteNames.customer,
          <AiOutlineUser className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.contactUs}>Contact Us</Link>,
          RouteNames.contactUs,
          <MessageOutlined className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Account Setting',
      'accountSetting',
      null,
      [
        getItem(
          <Link to={RouteNames.changePassword}>Change Password</Link>,
          RouteNames.changePassword,
          <AiOutlineUnlock className='fs-5' />
        ),
        getItem('Logout', 'logout', <AiOutlineLogout className='fs-5' />),
      ],
      'group'
    ),
  ];

  const MarketingMenus: MenuProps['items'] = [
    getItem(
      null,
      'administrator',
      null,
      [
        getItem(
          <Link to={RouteNames.dashboard}>Dashboard</Link>,
          RouteNames.dashboard,
          <AiOutlineBarChart className='fs-5' />
        ),
        getItem(<Link to={RouteNames.asset}>Asset</Link>, RouteNames.asset, <AiOutlineYoutube className='fs-5' />),
        getItem(
          <Link to={RouteNames.downloadHistory}>Download History</Link>,
          RouteNames.downloadHistory,
          <HistoryOutlined className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.collection}>Collection</Link>,
          RouteNames.collection,
          <AiOutlineInsertRowBelow className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.promotion}>Promotion</Link>,
          RouteNames.promotion,
          <AiOutlineStar className='fs-5' />
        ),
        getItem(<Link to={RouteNames.artist}>Artist</Link>, RouteNames.artist, <AiOutlineCamera className='fs-5' />),
        getItem(
          <Link to={RouteNames.customer}>Customer</Link>,
          RouteNames.customer,
          <AiOutlineUser className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.contactUs}>Contact Us</Link>,
          RouteNames.contactUs,
          <MessageOutlined className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Setting',
      'setting',
      null,
      [
        getItem(
          <Link to={RouteNames.banner}>Banner Setup</Link>,
          RouteNames.banner,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendAsset}>Recommend Asset</Link>,
          RouteNames.recommendAsset,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.recommendCollection}>Recommend Collection</Link>,
          RouteNames.recommendCollection,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(
          <Link to={RouteNames.newsContent}>News Contents</Link>,
          RouteNames.newsContent,
          <AiOutlineLayout className='fs-5' />
        ),
        getItem(<Link to={RouteNames.keywords}>Keyword</Link>, RouteNames.keywords, <AiOutlineTag className='fs-5' />),
        getItem(
          <Link to={RouteNames.eventlogs}>Event log</Link>,
          RouteNames.eventlogs,
          <AiOutlineWarning className='fs-5' />
        ),
      ],
      'group'
    ),
    getItem(
      'Account Setting',
      'accountSetting',
      null,
      [
        getItem(
          <Link to={RouteNames.changePassword}>Change Password</Link>,
          RouteNames.changePassword,
          <AiOutlineUnlock className='fs-5' />
        ),
        getItem('Logout', 'logout', <AiOutlineLogout className='fs-5' />),
      ],
      'group'
    ),
  ];

  return (
    <>
      <Menu
        mode='inline'
        selectedKeys={activePath}
        items={menus}
        onClick={onClick}
      />
    </>
  );
}
