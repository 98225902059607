export const enum Session {
  SESSION_ACCESS_TOKEN = 'adm-alive-access_token',
  SESSION_REFRESH_TOKEN = 'adm-alive-refresh_token',
  SESSION_USER = 'adm-alive-user',
  SESSION_HOST = 'adm-alive-host',
  EXPIRE_SESSION = 'adm-expireSession',
  ACCEPT_COOKIES = 'adm-accept_cookies',
}

export const enum SessionTimeOut {
  TWENTY_MINUTE = 1200000,
  ONE_WEEK = 604800000,
  FOURTEEN_DAY = 1209600000,
  ONE_YEAR = 31556952000,
}