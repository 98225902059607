import { Card, Row, Col, Form, Divider, Typography, Space, Modal, message, Button } from 'antd';
import Input from '../../components/Forms/Input';
import Select from '../../components/Forms/Select';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AccountService } from '../../services/accountService';
import { CheckCircleOutlined } from '@ant-design/icons';
import { IUpdateAdminRequestModel } from '../../models/request/AdminRequestModel';
import { HttpStatusCode } from 'axios';
import { IAdminProfileResponse } from '../../models/response/AccountInfoResponse';

const { Paragraph } = Typography;

const orientation = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Reviewer', value: 'Reviewer' },
  { label: 'Marketing', value: 'Marketing' },
];

const AdminDetail: React.FC = () => {
  const accountService = new AccountService();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [adminData, setAdminData] = useState<IAdminProfileResponse>({} as IAdminProfileResponse);

  async function getAdminById(id: string) {
    const { data } = await accountService.getAdminUserByIdAsync(id);

    form.setFieldsValue({
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      role: data.roles[0],
    });

    setAdminData(data);
  }

  useEffect(() => {
    if (id) {
      getAdminById(id);
    }
  }, [id]);

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = async () => {
    if (!id) {
      alert('Update admin fails');

      return;
    }

    const model: IUpdateAdminRequestModel = {
      adminId: id,
      firstname: form.getFieldValue('firstName'),
      lastname: form.getFieldValue('lastName'),
      role: form.getFieldValue('role'),
    };

    const { status } = await accountService.updateAdminUserAsync(model);

    if (status !== HttpStatusCode.Ok) {
      message.error('Save admin failed.');

      return;
    }

    message.success('Save admin success.');
    getAdminById(id);
  };

  const lockAdminAsync = async (id: string | undefined) => {
    if (!id) {
      message.error('Save admin failed.');

      return;
    }

    const { status } = await accountService.lockAdminUserAsync(id);

    if (status !== HttpStatusCode.Ok && status !== HttpStatusCode.NoContent) {
      message.error('Save admin failed.');

      return;
    }

    message.success('Save admin success.');
    getAdminById(id);
  };

  const unlockAdminAsync = async (id: string | undefined) => {
    if (!id) {
      message.error('Save admin failed.');

      return;
    }

    const { status } = await accountService.unlockAdminUserAsync(id);

    if (status !== HttpStatusCode.Ok && status !== HttpStatusCode.NoContent) {
      message.error('Save admin failed.');

      return;
    }

    message.success('Save admin success.');
    getAdminById(id);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Disable Admin',
      icon: <CheckCircleOutlined />,
      content: (
        <>
          <Paragraph className='mb-1 me-2'> Confirm disable admin.</Paragraph>
        </>
      ),
      okText: 'Confirm',
      okType: 'danger',
      onOk() {
        lockAdminAsync(id);
      },
    });
  };

  const showEnabledConfirm = () => {
    Modal.confirm({
      title: 'Enabled Admin',
      icon: <CheckCircleOutlined />,
      content: (
        <>
          <Paragraph className='mb-1 me-2'> Confirm enable admin.</Paragraph>
        </>
      ),
      okText: 'Confirm',
      okType: 'danger',
      onOk() {
        unlockAdminAsync(id);
      },
    });
  };

  return (
    <>
      <Form form={form} layout='vertical' autoComplete='off' validateMessages={validateMessages}>
        <Card
          title='Admin Detail'
          extra={
            <Space>
              {adminData && adminData.lockoutEnabled ? (
                <Button
                  type='default'
                  onClick={() => {
                    showEnabledConfirm();
                  }}
                >
                  Enable
                </Button>
              ) : (
                <Button
                  type='default'
                  onClick={() => {
                    showDeleteConfirm();
                  }}
                  danger
                >
                  Disable
                </Button>
              )}
              <Button
                type='primary'
                onClick={() => {
                  onFinish();
                }}
              >
                Save
              </Button>
            </Space>
          }
          bordered={false}
        >
          <div className='p-4'>
            <Divider orientation='left' plain>
              Profile
            </Divider>
            <Row justify='start' gutter={16}>
              <Col span={6}>
                <Input readOnly placeholder='Email' name='email' label='Email' />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Input
                  placeholder='Firstname'
                  name='firstName'
                  label='First Name'
                  rules={[{ required: true, message: 'Please input your firstname!' }]}
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder='Lastname'
                  name='lastName'
                  label='Last Name'
                  rules={[{ required: true, message: 'Please input your lastname!' }]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Select name='role' label='Role' options={orientation} />
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default AdminDetail;
