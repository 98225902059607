import { Card, Row, Col, Form, Space } from 'antd';
import Input from '../../components/Forms/Input';
import Button from '../../components/General/Button';
import { useNavigate } from "react-router-dom";

const Collection: React.FC = () => {
  const [form] = Form.useForm();

  const validateMessages = {
    required: '${label} is required!',
  };

  const onFinish = () => {
  };

  const navigate = useNavigate();
  const routeChange = () => {
    let path = `/assetRequests/detail`;
    navigate(path);
  }

  return (
    <>
      <Card title="Asset Request" bordered={false}>
        <Card className='card-form'>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
            validateMessages={validateMessages}>
            <Row justify="center">
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder='example'
                  name='collection'
                  label='Collection Name'
                />
              </Col>
              <Col span={3} />
              <Col span={6}>
                <Input
                  placeholder='example'
                  name='asset-code'
                  label='Asset'
                />
              </Col>
              <Col span={6} />
            </Row>
            <Row justify="end">
              <Col span={24} className='text-right'>
                <Space wrap>
                  <Button
                    type='primary'
                    text='Search'
                    className='px-5'
                    onClick={routeChange}
                  />
                  <Button
                    text='Clear'
                    className='px-5'
                  />
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>

      <Row justify="end" className='mt-3'>
        <Col span={24} className='text-right'>
          <Space wrap>
            <Button
              text='Export CSV'
              className='px-5'
            />
          </Space>
        </Col>
      </Row>
      <p>To Do Component Collection</p>
    </>
  );
};

export default Collection;