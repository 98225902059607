import { Card, Row, Col, Form, Space, Input, Button, Radio, Pagination } from 'antd';
import Table from '../../components/DataDisplay/Table';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { HttpStatus } from '../../constant/responseStatus';
import { ReleaseService } from '../../services/releaseService';
import { ReleaseListModel, ReleaseSearchModel } from '../../models/releaseModel';
import { useEffect, useState } from 'react';
import { RouteNames } from '../../components/Route/routeName';

const options = [
    { label: 'All', value: '' },
    { label: 'Model', value: 'Model' },
    { label: 'Property', value: 'Property' },
];

export default function Releases() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const [releases, setReleases] = useState<ReleaseListModel[]>([]);
    
const columns: ColumnsType<ReleaseListModel> = [
    {
      key: 'id',
      title: 'Release Name',
      dataIndex: 'name',
      width: '30%',
      align: 'center',
    },
    {
      key: 'type',
      title: 'Release Type',
      dataIndex: 'type',
      align: 'center',
    },
    {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
      },
    {
      key: 'artistName',
      title: 'Artist Name',
      dataIndex: 'artistName',
      align: 'center',
    },
    {
      key: 'download',
      title: 'Download',
      dataIndex: 'download',
      render: (value, record, index) => <Button type='link' onClick={() => saveDocument(record.documentUrl)}>Download</Button>,
      align: 'center',
      width: 30,
    },
    {
      key: 'id',
      title: 'Action',
      dataIndex: 'action',
      render: (value, record, index) => <Link to={`${RouteNames.releaseDetail}?id=${record.id}`}>Detail</Link>,
      align: 'center',
      width: 30,
    },
  ];

    useEffect(() => {
        onGetListReleases(page, size);
    }, []);

    const onGetListReleases = async (page: number, size: number) => {
        const request: ReleaseSearchModel = {
            releaesName: form.getFieldValue('name'),
            releaesType: form.getFieldValue('type'),
            email: form.getFieldValue('email'),
            artistName: form.getFieldValue('artistName'),
        };
        const { data, status } = await new ReleaseService().getListAsync(page, size, request);

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setReleases(data.list);
    };

    const onFinish = () => {
        onGetListReleases(page, size);
    };

    const onReset = () => {
        form.resetFields();
        onGetListReleases(1, 10);
        setPage(1);
        setSize(10);
    };

    const saveDocument = (url: string) => {
        const FileSaver = require('file-saver');
        FileSaver.saveAs(url, 'test.jpg');
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListReleases(page, size);
    };

    return (
        <>
            <Card title='Releases' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row>
                            <Col span={6} offset={5}>
                                <Form.Item
                                    name='type'
                                    className=' text-light w-100'
                                    label='Release Type'
                                    rules={[{ required: false }]}
                                    initialValue={''}
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={2}>
                                <Form.Item
                                    name='name'
                                    className=' text-light w-100'
                                    label='Release Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Release Name' size='large' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} offset={5}>
                                <Form.Item
                                    name='email'
                                    className=' text-light w-100'
                                    label='Email'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Email' size='large' />
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={2}>
                                <Form.Item
                                    name='artistName'
                                    className=' text-light w-100'
                                    label='Artist Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Artist Name' size='large' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Table className="mt-4" columns={columns} dataSource={releases} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
