import { List } from 'antd';
import { ReleaseItemModel } from '../../models/assetModel';

interface Props {
  listData: ReleaseItemModel[]
}

const handleDownload = async (documentUrl: string) => {
  const url = documentUrl;
  const filename = url.replace(/^.*[\\\/]/, '');

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
}

function ReleaseList({ listData }: Props) {
  return (
    <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      dataSource={listData}
      renderItem={(item) => (
        <List.Item
          actions={[
            <a key={`release-download-${item.id}`} onClick={() => handleDownload(item.documentUrl)}>download</a>
          ]}>
          {
            item.type === 'Model'
              ?
              <List.Item.Meta
                title={item.model?.name}
                description={`Gender: ${item.model?.gender}, Ethnic: ${item.model?.ethnic}, Date of birth: ${new Date(item.model?.dateOfBirth!).toLocaleString("th-TH", { dateStyle: 'medium' })}`}>
              </List.Item.Meta>
              :
              <List.Item.Meta
                title={item.name}>
              </List.Item.Meta>
          }
          <div>{item.type}</div>
        </List.Item>
      )}
    />
  );
};

export default ReleaseList;