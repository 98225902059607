import { Col, DatePicker, Divider, Form, FormInstance, Input, Radio, Row, } from 'antd';
import { useEffect, useState } from 'react';
import { ArtistTaxInfoProperty, NationalityType } from '../../../constant/artistTaxInfoConstants';
import AttachmentReadOnly from './AttachmentReadOnly';

interface IndividualTaxCenterProps {
  nationalityType: string,
  useFormTaxInfo: FormInstance<any>;
}

export default function IndividualTaxCenter({nationalityType, useFormTaxInfo}: IndividualTaxCenterProps) {
  const [isThai, setIsThai] = useState(false);
  const [useForm] = Form.useForm(useFormTaxInfo);

  useEffect(() => {
    if (nationalityType) {
      setIsThai(nationalityType === NationalityType.Thai);
    }
  }, [nationalityType]);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Divider orientation="left" plain>
            Part 1: Identification of beneficial owner
          </Divider>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Your Full Name"
            name="fullName"
            rules={[{required: true, message: 'Please enter Full Name.'}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          {
            isThai
            ? (
              <Form.Item
                label="ID Card Number (13 Digit)"
                name="idCardNumber"
                rules={[{required: true}]}>
                  <Input
                    size='large'
                    className='w-100'
                  />
              </Form.Item>
            )
            : (
              <Form.Item
                label="Country of citizenship"
                name="countryOfCitizenship">
                  <Input
                    size='large'
                    className='w-100'
                  />
              </Form.Item>
            )
          }
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain>
            {isThai ? `Address` : `Permanent residence address`}
          </Divider>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address Line 1"
            name="address1"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address Line 2"
            name="address2"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="City"
            name="city"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Postal"
            name="postcode"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        {
          isThai
          ?
          (
            <>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{required: true}]}>
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[{required: true}]}>
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Upload ID Card
                </Divider>
              </Col>
              <Col span={24}>
                <Form.Item
                    name={ArtistTaxInfoProperty.idCardAttachmentUrl}
                    className='mt-4 text-light '
                    label='ID Card'>
                    <AttachmentReadOnly
                      attachment={{
                        url: useForm.getFieldValue(ArtistTaxInfoProperty.idCardAttachmentUrl),
                        fileName: useForm.getFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileName),
                        fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileNameOriginal)}}/>
                  </Form.Item>
              </Col>
            </>
          )
          : 
          (
            <>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Is this address a P.O. Box or in-care-of address ?"
                  name="poBoxType"
                  rules={[{required: true}]}>
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Mailing address
                </Divider>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Address 1"
                  name="mailingAddress1">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Address 2"
                  name="mailingAddress2">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="City"
                  name="mailingCity">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Postal"
                  name="mailingPostcode">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="U.S. Taxpayer identification number, if required"
                  name="usTaxPayerType"
                  rules={[{required: true}]}>
                    <Radio.Group>
                      <Radio value={'SSN'}>SSN</Radio>
                      <Radio value={'ITIN'}>ITIN</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="SSN or ITIN Number"
                  name="usTaxPayerNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Foreign Tax Identification number"
                  name="taxIdNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Reference Number(s), if applicable"
                  name="referenceNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Date of birth"
                  name="dateOfBirth">
                    <DatePicker
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Part 2: Claim of Tax Treaty Benefits
                </Divider>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="I certify that the beneficial owner is a resident of ....."
                  name="claimOfTax">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Part 3: Certification
                </Divider>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={ArtistTaxInfoProperty.certificationAttachmentUrl}
                  className='mt-4 text-light '
                  label='Certification'>
                  <AttachmentReadOnly
                    attachment={{
                      url: useForm.getFieldValue(ArtistTaxInfoProperty.certificationAttachmentUrl),
                      fileName: useForm.getFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileName),
                      fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileNameOriginal)}}/>
                </Form.Item>
              </Col>
            </>
          )
        }
      </Row>
    </>
  );
}
