import { Card, Row, Col, Form, Space, Button, Table, Pagination, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { RouteNames } from '../../components/Route/routeName';
import { NewsContentModel } from '../../models/newsContentModel';
import { NewsContentService } from '../../services/newsContentService';

export default function NewsContents() {
    const [form] = Form.useForm();
    const [newsContents, setNewsContents] = useState<NewsContentModel[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const navigate = useNavigate();

    const onFinish = () => {
        onGetListNewsContents(page, size);
    };

    useEffect(() => {
        onGetListNewsContents(page, size);
    }, []);

    const onReset = () => {
        form.resetFields();
        onGetListNewsContents(1, 10);
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListNewsContents(page, size);
    };

    const onGetListNewsContents = async (page: number, size: number) => {
        const { data, status } = await new NewsContentService().getList(page, size, form.getFieldValue('name'));

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setNewsContents(data.list);
    };

    const columns: ColumnsType<NewsContentModel> = [
        {
            title: 'coverUrl',
            dataIndex: 'coverUrl',
            width: '10%',
            render: (value, record, index) => (
                <div style={{ position: 'relative', height: 50, width: 50 }}>
                    <img
                        src={record.coverUrl}
                        style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                    />
                </div>
            ),
            align: 'center'
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            width: '30%',
            ellipsis: true,
            align: 'center'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '50%',
            ellipsis: true,
            align: 'center'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (value, record, index) => <Link to={`${RouteNames.newsContentDetail}?id=${record.id}`}>Detail</Link>,
        },
    ];

    return (
        <>
            <Card title='News Content' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row justify='center'>
                            <Col span={8}>
                                <Form.Item
                                    name='name'
                                    className=' text-light w-100'
                                    label='Subject'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Subject' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row className='mt-3'>
                    <Col>
                        <Button type='primary' onClick={() => navigate(RouteNames.newsContentDetail)}>
                            Add Content
                        </Button>
                    </Col>
                </Row>
                <Table className='mt-4' columns={columns} dataSource={newsContents} pagination={false} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
