import {
  Card,
  Typography,
  Space,
  Row,
  Col,
  Form,
  Divider,
  Modal,
  Upload,
  Badge,
  InputNumber,
  Button,
  message,
} from 'antd';
import { CheckOutlined, DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PayoutRequestService } from '../../services/payoutRequestService';
import { HttpStatus } from '../../constant/responseStatus';
import { PayoutRequestInfoModel } from '../../models/payoutRequestModel';
import { PayoutInfoStatus, PayoutStatus } from '../../constant/statusConstants';
import Bank from '../../constant/banks.json';
import { RouteNames } from '../../components/Route/routeName';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from '../../constant/uploadFileConstants';
import { StringConstants } from '../../constant/stringConstants';
import { useSessionContext } from '../../context/session-context';
import { S3Constants } from '../../constant/s3Constants';
import AWS from 'aws-sdk';

const { confirm } = Modal;
const { Paragraph, Text } = Typography;

export default function PayoutDetail() {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [payoutRequestId, setPayoutRequestId] = useState('');
  const [whTax, setWhTax] = useState(0);
  const [omiseFee] = useState(30);
  const [, setApproveAmount] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [payout, setPayout] = useState<PayoutRequestInfoModel>({} as PayoutRequestInfoModel);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadFilePath, setUploadFilePath] = useState<string>(StringConstants.EMPTY);
  const [uploadFileName, setUploadFileName] = useState<string>(StringConstants.EMPTY);
  const [uploadAttachFilePath, setUploadAttachFilePath] = useState<string>(StringConstants.EMPTY);
  const [uploadAttachFileName, setUploadAttachFileName] = useState<string>(StringConstants.EMPTY);
  const [fileAttachList, setFileAttachList] = useState<any[]>([]);


  const { userProfile } = useSessionContext();
  const [messageApi, contextHolder] = message.useMessage();

  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  useEffect(() => {
    const id = searchParams.get('id');

    if (id) {
      setPayoutRequestId(id);
      onGetPayoutRequest(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (payout.payoutRequest) {
      const withold = (payout.payoutRequest.requestAmount * 3) / 100;
      setWhTax(withold);
      setApproveAmount(payout.payoutRequest.requestAmount);
      setNetAmount(payout.payoutRequest.requestAmount - withold);
    }
  }, [payout]);

  const onGetPayoutRequest = async (id: string) => {
    const { data, status } = await new PayoutRequestService().getId(id);

    if (status !== HttpStatus.OK) {
      return;
    }

    setPayout(data);
    form.setFieldValue('approveAmount', data.payoutRequest.requestAmount);

    if (data.payoutRequest.approveAmount) {
      form.setFieldValue('approveAmount', data.payoutRequest.approveAmount);
    }
  };

  const onApprove = async (approveAmount: number) => {
    const { status } = await new PayoutRequestService().onApprove(payoutRequestId, approveAmount);

    if (status !== HttpStatus.OK) {
      message.error('Save payout request failed.');
      return;
    }

    message.success('Save payout request success.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onCompleteManual = async (approveAmount: number) => {
    const { status } = await new PayoutRequestService().onCompletedManual(
      payoutRequestId,
      approveAmount,
      uploadFilePath
    );

    if (status !== HttpStatus.OK) {
      message.error('Save payout request failed.');
      return;
    }

    message.success('Save payout request success.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onSaveTaxFile = async () => {
    const { status } = await new PayoutRequestService().onSaveTaxFile(payoutRequestId, uploadFilePath);

    if (status !== HttpStatus.OK) {
      message.error('Save payout request failed.');
      return;
    }

    message.success('Save payout request suucess.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onDeleteTaxFile = async () => {
    const { status } = await new PayoutRequestService().onDeleteTaxFile(payoutRequestId);

    if (status !== HttpStatus.OK) {
      message.error('Delete payout request failed.');
      return;
    }

    message.success('Delete payout request suucess.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onSaveAttachmentUrlFile = async () => {
    const { status } = await new PayoutRequestService().onSaveAttachmentUrlFile(payoutRequestId, uploadAttachFilePath);

    if (status !== HttpStatus.OK) {
      message.error('Save payout request failed.');
      return;
    }

    message.success('Save payout request suucess.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onDeleteAttachmentUrlFile = async () => {
    const { status } = await new PayoutRequestService().onDeleteAttachmentUrlFile(payoutRequestId);

    if (status !== HttpStatus.OK) {
      message.error('Delete payout request failed.');
      return;
    }

    message.success('Delete payout request suucess.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const onReject = async () => {
    const { status } = await new PayoutRequestService().onRejected(payoutRequestId);

    if (status !== HttpStatus.OK) {
      message.error('Save payout request failed.');
      return;
    }

    message.success('Save payout request suucess.');
    window.location.href = `${RouteNames.payoutDetail}?id=${payout.payoutRequest.id}`;
  };

  const CompleteMaunal = () => {
    const appAmt = form.getFieldValue('approveAmount');
    if (appAmt > 0) {
      confirm({
        title: `Do you want to Complete Manual ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (appAmt > 0 && appAmt <= payout.totalUnpaid) {
            onCompleteManual(appAmt);
          }
        },
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please attachment file for approve manual.',
      });
    }
  };

  const SaveTaxFile = () => {
    if (uploadFilePath) {
      confirm({
        title: `Do you want to save WHT file ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (uploadFilePath && uploadFilePath.length > 0) {
            onSaveTaxFile();
          }
        },
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please attachment WHT file.',
      });
    }
  };

  const DeleteTaxFile = () => {
    if (payout.payoutRequest.taxAttachmentUrl) {
      confirm({
        title: `Do you want to delete WHT file ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (payout.payoutRequest.taxAttachmentUrl) {
            onDeleteTaxFile();
          }
        },
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please add attachment WHT file.',
      });
    }
  };

  const SaveAttachmentUrlFile = () => {
    if (uploadAttachFilePath) {
      confirm({
        title: `Do you want to save approve file ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (uploadAttachFilePath && uploadAttachFilePath.length > 0) {
            onSaveAttachmentUrlFile();
          }
        },
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please attachment approve file.',
      });
    }
  };

  const DeleteAttachmentUrlFile = () => {
    if (payout.payoutRequest.attachmentUrl) {
      confirm({
        title: `Do you want to delete approve file ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (payout.payoutRequest.attachmentUrl) {
            onDeleteAttachmentUrlFile();
          }
        },
      });
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please add attachment approve file.',
      });
    }
  };

  const Reject = () => {
    confirm({
      title: `Do you want to reject ?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        onReject();
      },
    });
  };

  const onFinish = () => {
    const appAmt = form.getFieldValue('approveAmount');
    if (appAmt > 0) {
      confirm({
        title: `Do you want to Approve ${appAmt} ?`,
        icon: <ExclamationCircleFilled />,
        onOk() {
          if (appAmt > 0 && appAmt <= payout.totalBalance) {
            onApprove(appAmt);
          }
        },
      });
    }
  };

  const uploadProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: true,
    },
    beforeUpload: (file: any) => {
      setFileList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadFileName);
      setFileList([]);
      setUploadFilePath('');
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3(originalFile, onError, onSuccess, onProgress);
    },
  };


  const addFileToS3 = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setUploadFilePath(filePath);
    setUploadFileName(fileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const uploadAttachUrlProps: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    fileList: fileAttachList,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: true,
    },
    beforeUpload: (file: any) => {
      setFileAttachList([file]);
    },
    async onRemove() {
      deleteFileFromS3(uploadAttachFileName);
      setUploadAttachFilePath('');
      setFileAttachList([]);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;

      let errorMessage = await validateUploadFile(originalFile, onError);

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);

        return;
      }

      await addFileToS3AttachUrl(originalFile, onError, onSuccess, onProgress);
    },
  };


  const addFileToS3AttachUrl = async (originalFile: any, onError: any, onSuccess: any, onProgress: any) => {
    const fileName = getFileName(originalFile.name);
    const filePath = getS3FileUrl(fileName);
    setUploadAttachFilePath(filePath);
    setUploadAttachFileName(fileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type,
    };

    s3Bucket
      .putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(
        async () => {
          onSuccess!(fileName);
        },
        (err) => {
          onError!(err);
          throw err;
        }
      );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;

      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      'Release' +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete='off'>
        {payout && payout.payoutRequest && (
          <Card
            className='card-customize'
            title={
              <Space align='center'>
                <Typography.Title
                  level={5}
                  className='mb-0'>
                  Payout Request Detail
                </Typography.Title>
              </Space>
            }
            extra={
              <Space align='center'>
                {payout.payoutRequest.status === PayoutStatus.Pending && (
                  <>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => Reject()}
                      danger>
                      Reject
                    </Button>
                    <Button
                      type='default'
                      size='large'
                      onClick={() => CompleteMaunal()}>
                      Manual Complete
                    </Button>
                    <Button
                      type='primary'
                      htmlType='submit'
                      size='large'>
                      Approve to Omise
                    </Button>
                  </>
                )}
                {payout.payoutRequest.status === PayoutStatus.Paid && !payout.payoutRequest.attachmentUrl && (
                  <>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => SaveAttachmentUrlFile()}>
                      Save Approve File
                    </Button>
                  </>
                )}
              {payout.payoutRequest.status === PayoutStatus.Paid && payout.payoutRequest.attachmentUrl && (
                  <>
                    <Button
                      type='ghost'
                      size='large'
                      onClick={() => DeleteAttachmentUrlFile()}>
                      Delete Approve File
                    </Button>
                  </>
                )}
                {payout.payoutRequest.status === PayoutStatus.Paid && !payout.payoutRequest.taxAttachmentUrl && (
                  <>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => SaveTaxFile()}>
                      Save WHT File
                    </Button>
                  </>
                )}
              {payout.payoutRequest.status === PayoutStatus.Paid && payout.payoutRequest.taxAttachmentUrl && (
                  <>
                    <Button
                      type='ghost'
                      size='large'
                      onClick={() => DeleteTaxFile()}>
                      Delete WHT File
                    </Button>
                  </>
                )}
                <Paragraph className='mb-0 me-2'>Status :</Paragraph>
                {payout.payoutRequest &&
                  (payout.payoutRequest.status === PayoutStatus.Pending ? (
                    <Badge
                      status='default'
                      text={payout.payoutRequest.status}
                    />
                  ) : payout.payoutRequest.status === PayoutStatus.Processing ? (
                    <Badge
                      status='processing'
                      text={payout.payoutRequest.status}
                    />
                  ) : payout.payoutRequest.status === PayoutStatus.Paid ? (
                    <Badge
                      status='success'
                      text={payout.payoutRequest.status}
                    />
                  ) : (
                    <Badge
                      status='error'
                      text={payout.payoutRequest.status}
                    />
                  ))}
              </Space>
            }>
            <div className='p-4'>
              <Divider
                orientation='left'
                plain>
                Artist Information
              </Divider>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Artist Name</Col>
                <Col span={20}>: {payout.name + ' ' + payout.lastName}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>ID Card</Col>
                <Col span={20}>
                {
                  payout.idCardUrl ? (
                    <>
                    : <a href={payout.idCardUrl} target='_blank'>สำเนาบัตรประชาชน</a>
                    </>
                  ):(
                    <>
                    : <span className='text-danger'>ยังไม่ได้อัปโหลด</span>
                    </>
                  )
                }
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Book Bank</Col>
                <Col span={20}>
                {
                  payout.bookBankUrl ? (
                    <>
                    : <a href={payout.bookBankUrl} target='_blank'>สำเนาบัญชีธนาคาร</a>
                    </>
                  ):(
                    <>
                    : <span className='text-danger'>ยังไม่ได้อัปโหลด</span>
                    </>
                  )
                }
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Total Balance</Col>
                <Col span={20}>
                  : THB{' '}
                  {payout.totalBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4 mb-4'>
                <Col span={4}>Total Unpaid</Col>
                <Col span={20}>
                  : THB{' '}
                  {payout.totalUnpaid.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Col>
              </Row>

              <Divider
                orientation='left'
                plain>
                Payout Information
              </Divider>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Payout Method</Col>
                <Col span={20}>: Bank Transfer</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Account No.</Col>
                <Col span={20}>: {payout.payoutInfo.bankAccountNumber}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Account Name</Col>
                <Col span={20}>: {payout.payoutInfo.bankAccountName}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Bank</Col>
                <Col span={20}>: {Bank.filter((x) => x.value === payout.payoutInfo.bankBrand)[0].name}</Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4 mb-4'>
                <Col span={4}>Payout Status</Col>
                <Col span={20}>
                  :{' '}
                  {payout.payoutInfo.status &&
                    (payout.payoutInfo.status === PayoutInfoStatus.Pending ? (
                      <Badge
                        color='#d9d9d9'
                        count={payout.payoutInfo.status}
                      />
                    ) : payout.payoutInfo.status === PayoutInfoStatus.Verified ? (
                      <Badge
                        color='#52c41a'
                        count={payout.payoutInfo.status}
                      />
                    ) : (
                      <Badge
                        color='#f5222d'
                        count={payout.payoutInfo.status}
                      />
                    ))}
                </Col>
              </Row>

              <Divider
                orientation='left'
                plain>
                Payout
              </Divider>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Request Amount</Col>
                <Col span={20}>
                  :{' '}
                  <Text strong>
                    THB{' '}
                    {payout.payoutRequest.requestAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Text>
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Approve Amount</Col>
                <Col span={6}>
                  {payout.payoutRequest.status === PayoutStatus.Pending ? (
                    <Form.Item
                      name='approveAmount'
                      className=' text-light w-100'
                      rules={[{ required: true, message: 'Please input appove amount!' }]}>
                      <InputNumber
                        size='large'
                        min={0}
                        max={payout.payoutRequest.requestAmount}
                        className='w-100'
                      />
                    </Form.Item>
                  )  : payout.payoutRequest.status === PayoutStatus.Cancelled ?
                  <>
                      :{' '}
                      <Text mark>
                        THB{' '}
                        {payout.payoutRequest.requestAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </Text>{' '}
                    </>:(
                    <>
                      :{' '}
                      <Text mark>
                        THB{' '}
                        {payout.payoutRequest.approveAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </Text>{' '}
                      <CheckOutlined />
                    </>
                  )}
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>Withholding Tax (3%)</Col>
                <Col span={20}>
                  : THB{' '}
                  {payout.payoutRequest.status === PayoutStatus.Pending ||
                  payout.payoutRequest.status === PayoutStatus.Cancelled ? (
                    <>
                      {whTax.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </>
                  ) : (
                    payout.payoutRequest.withholdingTaxAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  )}
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>
                  <Text type='danger'>Omise Fee</Text>
                </Col>
                <Col span={20}>
                  :{' '}
                  <Text type='danger'>
                    THB{' '}
                    {omiseFee.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Text>
                </Col>
              </Row>
              <Row
                gutter={16}
                className='mt-4'>
                <Col span={4}>
                  <Text strong>Net Total</Text>
                </Col>
                <Col span={20}>
                  :{' '}
                  <Text strong>
                    THB{' '}
                    {payout.payoutRequest.status === PayoutStatus.Pending ||
                    payout.payoutRequest.status === PayoutStatus.Cancelled ? (
                      <>
                        {(netAmount - omiseFee).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </>
                    ) : (
                      (payout.payoutRequest.netAmount - omiseFee).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    )}
                  </Text>
                </Col>
              </Row>
              <Divider
                orientation='left'
                plain>
                Attachment File (for Approve Manual)
              </Divider>
              <Row>
                <Col>
                  {!payout.payoutRequest.attachmentUrl?(
                    <Upload {...uploadAttachUrlProps}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  ) : (
                    <>
                      {payout.payoutRequest.attachmentUrl ? (
                        <Button
                          type='link'
                          icon={<DownloadOutlined />}
                          onClick={() => window.open(payout.payoutRequest.attachmentUrl, '_blank', 'noreferrer')}>
                          Download File for Approve Manual
                        </Button>
                      ) : (
                        <>-</>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              {payout.payoutRequest.status === PayoutStatus.Paid && (
                <>
                  <Divider
                    orientation='left'
                    plain>
                    WHT Attachment File
                  </Divider>
                  <Row>
                    <Col>
                      {payout.payoutRequest.status === PayoutStatus.Paid && !payout.payoutRequest.taxAttachmentUrl ? (
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                      ) : (
                        <Button
                          type='link'
                          icon={<DownloadOutlined />}
                          onClick={() => window.open(payout.payoutRequest.taxAttachmentUrl, '_blank', 'noreferrer')}>
                          Download WHT File
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Card>
        )}
      </Form>
    </>
  );
}
