import { Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import { ArtistTaxInfoProperty } from '../../../constant/artistTaxInfoConstants';
import AttachmentReadOnly from './AttachmentReadOnly';

interface CompanyTaxCenterProps {
  useFormTaxInfo: FormInstance<any>;
}

export default function CompanyTaxCenter({useFormTaxInfo}: CompanyTaxCenterProps) {
  const [useForm] = Form.useForm(useFormTaxInfo);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Divider orientation="left" plain>
            Part 1: Identification of beneficial owner
          </Divider>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Name of Juristic Person (THAI)"
            name="juristicPersonNameTh"
            rules={[{required: true}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Name of Juristic Person (ENG)"
            name="juristicPersonNameEn"
            rules={[{required: true}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="ID Card Number (13 Digit)"
            name="taxIdNumber"
            rules={[{required: true}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain>
            Juristic Person Address (THAI)
          </Divider>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address Line 1"
            name="companyAddress1Th">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address Line 2"
            name="companyAddress2Th">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="City"
            name="cityTh">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Postal"
            name="postcodeTh">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain>
            Juristic Person Address (ENG)
          </Divider>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address 1"
            name="companyAddress1En">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Address 2"
            name="companyAddress2En">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="City"
            name="cityEn">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Postal"
            name="postcodeEn">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Company Contact Email"
            name="companyContactEmail">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label="Company Contact Number"
            name="companyContactPhone">
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider orientation="left" plain>
            Upload 5 Tax Files
          </Divider>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl}
            className='mt-4 text-light '
            label='Upload Certified Copy of VAT Registration Certificate (Por Por 20 or 01 or 09) ( pdf or jpg)'>
            <AttachmentReadOnly
              attachment={{
                url: useForm.getFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl),
                fileName: useForm.getFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileName),
                fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileNameOriginal)}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl}
            className='mt-4 text-light '
            label='Upload Certified Copy of the Certificate of Registration Issued by Ministry of Commerce not more than 1 year before the application submission date ( pdf or jpg)'>
            <AttachmentReadOnly
              attachment={{
                url: useForm.getFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl),
                fileName: useForm.getFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileName),
                fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileNameOriginal)}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compCommercialRegAttachmentUrl}
            className='mt-4 text-light '
            label='Upload Certified Copy of the Commercial Registration or a Certificate of Registered Partnerships ( pdf or jpg)'>
            <AttachmentReadOnly
              attachment={{
                url: useForm.getFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentUrl),
                fileName: useForm.getFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileName),
                fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileNameOriginal)}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl}
            className='mt-4 text-light '
            label='Upload Certified Copy the list of shareholders ( pdf or jpg)'>
            <AttachmentReadOnly
              attachment={{
                url: useForm.getFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl),
                fileName: useForm.getFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileName),
                fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileNameOriginal)}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl}
            className='mt-4 text-light '
            label='Upload Certified Copy of ID Card of Company’s Authorized Person(s) ( pdf or jpg)'>
            <AttachmentReadOnly
              attachment={{
                url: useForm.getFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl),
                fileName: useForm.getFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileName),
                fileNameOriginal: useForm.getFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileNameOriginal)}}/>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
