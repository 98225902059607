import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { EarningChartModel } from '../models/dashboardModel';

export class dashboardService {
  private api = 'Admin/dashboard';

  getCountData(): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/total/data`);
  }

  getMostOrderSell(): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/most/order/sell`);
  }

  getEarningChart(year: number): Promise<AxiosResponse<EarningChartModel[]>> {
    return Axios.get<EarningChartModel[]>(`${this.api}/earning/chart?year=${year}`);
  }

  getMostChart(): Promise<AxiosResponse> {
    return Axios.get(`${this.api}/most/chart`);
  }
}