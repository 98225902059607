export class ArtistType {
  static readonly Individual: string = 'Individual';
  static readonly Company: string = 'Company';
}

export class NationalityType {
  static readonly Thai: string = 'Thai';
  static readonly Foreigner: string = 'Foreigner';
}

export class ArtistTaxInfoFileType {
  static readonly idCardAttachment: string = 'idCard';
  static readonly certification: string = 'certification';
  static readonly compVatRegisterCertificate: string = 'compVatRegisterCertificate';
  static readonly compCerRegIssue: string = 'compCerRegIssue';
  static readonly compCommercialReg: string = 'compCommercialReg';
  static readonly compListOfShareholders: string = 'compListOfShareholders';
  static readonly compIdCardAuthorizedPerson: string = 'compIdCardAuthorizedPerson';
}

export class ArtistTaxInfoProperty {
  static readonly id: string = 'id';
  static readonly artistId: string = 'artistId';
  static readonly address1: string = 'address1';
  static readonly address2: string = 'address2';
  static readonly companyAddress1En: string = 'companyAddress1En';
  static readonly companyAddress2En: string = 'companyAddress2En';
  static readonly companyAddress1Th: string = 'companyAddress1Th';
  static readonly companyAddress2Th: string = 'companyAddress2Th';
  static readonly artistType: string = 'artistType';
  static readonly nationalityType: string = 'xnationalityTypexxx';
  static readonly fullName: string = 'fullName';
  static readonly idCardNumber: string = 'idCardNumber';
  static readonly phone: string = 'phone';
  static readonly email: string = 'email';
  static readonly postcode: string = 'postcode';
  static readonly postcodeEn: string = 'postcodeEn';
  static readonly postcodeTh: string = 'postcodeTh';
  static readonly city: string = 'city';
  static readonly cityEn: string = 'cityEn';
  static readonly cityTh: string = 'cityTh';
  static readonly countryOfCitizenship: string = 'countryOfCitizenship';
  static readonly poBoxType: string = 'poBoxType';
  static readonly usTaxPayerType: string = 'usTaxPayerType';
  static readonly usTaxPayerNumber: string = 'usTaxPayerNumber';
  static readonly taxIdNumber: string = 'taxIdNumber';
  static readonly foreignerTaxIdNumber: string = 'foreignerTaxIdNumber';
  static readonly referenceNumber: string = 'referenceNumber';
  static readonly dateOfBirth: string = 'dateOfBirth';
  static readonly claimOfTax: string = 'claimOfTax';
  static readonly mailingAddress1: string = 'mailingAddress1';
  static readonly mailingAddress2: string = 'mailingAddress2';
  static readonly mailingCity: string = 'mailingCity';
  static readonly mailingPostcode: string = 'mailingPostcode';
  static readonly juristicPersonNameTh: string = 'juristicPersonNameTh';
  static readonly juristicPersonNameEn: string = 'juristicPersonNameEn';
  static readonly companyContactEmail: string = 'companyContactEmail';
  static readonly companyContactPhone: string = 'companyContactPhone';

  static readonly idCardAttachmentUrl: string = 'idCardAttachmentUrl';
  static readonly idCardAttachmentFileName: string = 'idCardAttachmentFileName';
  static readonly idCardAttachmentFileNameOriginal: string = 'idCardAttachmentFileNameOriginal';
  static readonly idCardAttachmentSize: string = 'idCardAttachmentSize';

  static readonly certificationAttachmentUrl: string = 'certificationAttachmentUrl';
  static readonly certificationAttachmentFileName: string = 'certificationAttachmentFileName';
  static readonly certificationAttachmentFileNameOriginal: string = 'certificationAttachmentFileNameOriginal';
  static readonly certificationAttachmentSize: string = 'certificationAttachmentSize';

  static readonly compVatRegisterCertificateAttachmentUrl: string = 'compVatRegisterCertificateAttachmentUrl';
  static readonly compVatRegisterCertificateAttachmentFileName: string = 'compVatRegisterCertificateAttachmentFileName';
  static readonly compVatRegisterCertificateAttachmentFileNameOriginal: string = 'compVatRegisterCertificateAttachmentFileNameOriginal';
  static readonly compVatRegisterCertificateAttachmentFileSize: string = 'compVatRegisterCertificateAttachmentFileSize';

  static readonly compCerRegIssueAttachmentUrl: string = 'compCerRegIssueAttachmentUrl';
  static readonly compCerRegIssueAttachmentFileName: string = 'compCerRegIssueAttachmentFileName';
  static readonly compCerRegIssueAttachmentFileNameOriginal: string = 'compCerRegIssueAttachmentFileNameOriginal';
  static readonly compCerRegIssueAttachmentSize: string = 'compCerRegIssueAttachmentSize';

  static readonly compCommercialRegAttachmentUrl: string = 'compCommercialRegAttachmentUrl';
  static readonly compCommercialRegAttachmentFileName: string = 'compCommercialRegAttachmentFileName';
  static readonly compCommercialRegAttachmentFileNameOriginal: string = 'compCommercialRegAttachmentFileNameOriginal';
  static readonly compCommercialRegAttachmentSize: string = 'compCommercialRegAttachmentSize';

  static readonly compListOfShareholdersAttachmentUrl: string = 'compListOfShareholdersAttachmentUrl';
  static readonly compListOfShareholdersAttachmentFileName: string = 'compListOfShareholdersAttachmentFileName';
  static readonly compListOfShareholdersAttachmentFileNameOriginal: string = 'compListOfShareholdersAttachmentFileNameOriginal';
  static readonly compListOfShareholdersAttachmentSize: string = 'compListOfShareholdersAttachmentSize';

  static readonly compIdCardAuthorizedPersonAttachmentUrl: string = 'compIdCardAuthorizedPersonAttachmentUrl';
  static readonly compIdCardAuthorizedPersonAttachmentFileName: string = 'compIdCardAuthorizedPersonAttachmentFileName';
  static readonly compIdCardAuthorizedPersonAttachmentFileNameOriginal: string = 'compIdCardAuthorizedPersonAttachmentFileNameOriginal';
  static readonly compIdCardAuthorizedPersonAttachmentSize: string = 'compIdCardAuthorizedPersonAttachmentSize';
}