import { Card, Row, Col, Form, Space, Badge, DatePicker, Radio, Button, Pagination } from 'antd';
import Input from '../../components/Forms/Input';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { PayoutRequestService } from '../../services/payoutRequestService';
import { PayoutRequestListModel, PayoutRequestSearchModel } from '../../models/payoutRequestModel';
import Table, { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../components/Route/routeName';
import { PayoutStatus } from '../../constant/statusConstants';
import moment from 'moment';
import { DateFormat } from '../../constant/constants';

const { RangePicker } = DatePicker;

const options = [
    { label: 'All', value: '' },
    { label: PayoutStatus.Pending, value: PayoutStatus.Pending },
    { label: PayoutStatus.Processing, value: PayoutStatus.Processing },
    { label: PayoutStatus.Paid, value: PayoutStatus.Paid },
    { label: PayoutStatus.Cancelled, value: PayoutStatus.Cancelled },
];

export default function PayoutRequest() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);
    const [payoutRequests, setPayoutRequests] = useState<PayoutRequestListModel[]>([]);

    const columns: ColumnsType<PayoutRequestListModel> = [
        {
            title: 'Request No.',
            dataIndex: 'requestno',
            align: 'center',
            render: (value, record, index) => <>{record.payoutRequest.requestNo}</>,
        },
        {
            title: 'Request Date',
            dataIndex: 'requestDate',
            align: 'center',
            render: (value, record, index) => (
                <>
                    {record.payoutRequest.createdInfo &&
                        moment(record.payoutRequest.createdInfo.at).format(DateFormat.DateTime)}
                </>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: 'center',
            render: (value, record, index) => <>{record.email}</>,
        },
        {
            title: 'Artist Name',
            dataIndex: 'firstname',
            align: 'center',
            render: (value, record, index) => <>{record.name + ' ' + record.lastName}</>,
        },
        {
            title: 'Request Amount',
            dataIndex: 'requestAmount',
            align: 'center',
            render: (value, record, index) => (
                <p className='text-right'>
                    {record.payoutRequest.requestAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}
                </p>
            ),
        },
        {
            title: 'Approve Amount',
            dataIndex: 'approveAmount',
            align: 'center',
            render: (value, record, index) => (
                <p className='text-right'>
                    {record.payoutRequest.approveAmount &&
                        record.payoutRequest.approveAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                        })}
                </p>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (value, record, index) =>
                record.payoutRequest.status === PayoutStatus.Pending ? (
                    <Badge status='default' text={record.payoutRequest.status} />
                ) : record.payoutRequest.status === PayoutStatus.Processing ? (
                    <Badge status='processing' text={record.payoutRequest.status} />
                ) : record.payoutRequest.status === PayoutStatus.Paid ? (
                    <Badge status='success' text={record.payoutRequest.status} />
                ) : (
                    <Badge status='error' text={record.payoutRequest.status} />
                ),
        },
        {
            title: 'WHT Attachment',
            dataIndex: 'tax',
            align: 'center',
            render: (value, record, index) => (
                <>
                    {
                        record.payoutRequest.taxAttachmentUrl && (
                            <Link to={record.payoutRequest.taxAttachmentUrl} target='_blank'>Download</Link>
                        )
                    }
                </>
            ),
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            align: 'center',
            render: (value, record, index) => (
                <>
                <Link to={`${RouteNames.payoutDetail}?id=${record.payoutRequest.id}`}>Detail</Link>
                </>
            ),
        },
    ];

    useEffect(() => {
        onGetPayoutRequests(page, size);
    }, []);

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetPayoutRequests(page, size);
    };

    const onGetPayoutRequests = async (page: number, size: number) => {
        const rDate = form.getFieldValue('requestDate');
        const request: PayoutRequestSearchModel = {
            requestNo: form.getFieldValue('requestNo'),
            artistCode: form.getFieldValue('artistCode'),
            email: form.getFieldValue('email'),
            artistName: form.getFieldValue('artistName'),
            status: form.getFieldValue('status'),
            requestStartDate: rDate ? new Date(rDate[0]) : undefined,
            requestEndDate: rDate ? new Date(rDate[1]) : undefined,
        };
        const { data, status } = await new PayoutRequestService().getListAsync(page, size, request);

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalRow(data.paging.totalRows);
        setPayoutRequests(data.list);
    };

    const onFinish = () => {
        onGetPayoutRequests(page, size);
    };

    const onExportCsv = async () => {
        const rDate = form.getFieldValue('requestDate');
        const request: PayoutRequestSearchModel = {
            requestNo: form.getFieldValue('requestNo'),
            artistCode: form.getFieldValue('artistCode'),
            email: form.getFieldValue('email'),
            artistName: form.getFieldValue('artistName'),
            status: form.getFieldValue('status'),
            requestStartDate: rDate ? new Date(rDate[0]) : undefined,
            requestEndDate: rDate ? new Date(rDate[1]) : undefined,
        };
        const { data, status } = await new PayoutRequestService().ExportCsv(request);

          if (status !== HttpStatus.OK) {
              return;
          }

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(data);
          downloadLink.download = "Payout_Request_Report.csv";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
      };

      const reset = async () => {
        form.setFieldValue('requestDate', undefined);
        form.resetFields(['requestNo', 'artistName', 'email'])
        form.setFieldValue('status', '');
        setPage(1);
        setSize(10);
        await onGetPayoutRequests(1, 10);
    };

    return (
        <>
            <Card title='Payout Request' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row gutter={16}>
                            <Col span={6} offset={4}>
                                <Form.Item
                                    name='requestNo'
                                    className=' text-light w-100'
                                    label='Request No.'
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='Request No.' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6} offset={4}>
                                <Form.Item
                                    name='email'
                                    className=' text-light w-100'
                                    label='Email'
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='Email'/>
                                </Form.Item>
                            </Col>
                            <Col span={6} offset={1}>
                                <Form.Item
                                    name='artistName'
                                    className=' text-light w-100'
                                    label='Artist Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        size='large'
                                        placeholder='Artist Name'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6} offset={4}>
                                <Form.Item
                                    name='requestDate'
                                    className=' text-light w-100'
                                    label='Request Date'
                                    rules={[{ required: false }]}
                                >
                                    <RangePicker size='large' />
                                </Form.Item>
                            </Col>
                            <Col span={7} offset={1}>
                                <Form.Item
                                    name='status'
                                    className=' text-light w-100'
                                    label='Status'
                                    rules={[{ required: false }]}
                                    initialValue={PayoutStatus.EmpTy}
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={reset}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row className='my-2' justify='end'>
                  <Col span={24} className='text-right'>
                    <Button size='large' type='default' className='px-5' onClick={onExportCsv}>
                        Export CSV
                    </Button>
                  </Col>
                </Row>
                <Table className='mt-4' columns={columns} dataSource={payoutRequests} pagination={false} scroll={{x: true}} />
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
