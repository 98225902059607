import {
    Card, Row, Col, Form, Space, Badge, Button, Radio, Pagination,
} from 'antd';
import Input from '../../components/Forms/Input';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { AssetListModel, AssetListSearchModel } from '../../models/assetModel';
import { useState, useEffect } from 'react';
import { AssetService } from '../../services/assetService';
import { HttpStatus } from '../../constant/responseStatus';
import { AssetStatus, AssetType } from '../../constant/assetConstants';
import { Constants } from '../../constant/constants';
import { RouteNames } from '../../components/Route/routeName';
import InputRangePicker from '../../components/Forms/RangePicker';
import Table from '../../components/DataDisplay/Table';

const assetTypeOptions = [
    { label: 'All', value: '' },
    { label: AssetType.IMAGE, value: AssetType.IMAGE },
    { label: AssetType.VIDEO, value: AssetType.VIDEO },
];

const assetStatusOptions = [
    { label: 'All', value: '' },
    { label: AssetStatus.PENDING, value: AssetStatus.PENDING },
    { label: AssetStatus.COMPLETED, value: AssetStatus.COMPLETED },
    { label: AssetStatus.REJECTED, value: AssetStatus.REJECTED },
    { label: AssetStatus.ABANDON, value: AssetStatus.ABANDON },
];

const columns: ColumnsType<AssetListModel> = [
    {
        key: 'thumbnail',
        title: '',
        dataIndex: 'code',
        align: 'center',
        width: 60,
        render: (value, record, index) =>
            record.assetType === AssetType.IMAGE ? (
                <div style={{ position: 'relative', height: 50, width: 50 }}>
                    <img
                        src={record.thumbnailImageUrl}
                        style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                    />
                </div>
            ) : (
                <div style={{ position: 'relative', height: 50, width: 50 }}>
                    <img
                        src={record.thumbnailVideoCoverUrl}
                        style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                    />
                </div>
            ),
    },
    {
        key: 'code',
        title: 'Asset Code',
        dataIndex: 'code',
        align: 'center',
    },
    {
        key: 'name',
        title: 'Asset Name',
        dataIndex: 'name',
        align: 'center',
        render: (text) => <p className='text-left'>{text}</p>,
    },
    {
        key: 'type',
        title: 'Asset Type',
        dataIndex: 'assetType',
        align: 'center',
    },
    {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        align: 'center',
        render: (value, record, index) => (
            <p className='text-left'>
                {record.email}
            </p>
        ),
    },
    {
        key: 'artistName',
        title: 'Artist',
        dataIndex: 'artistName',
        align: 'center',
        render: (value, record, index) => (
            <p className='text-left'>
                {record.artistFirstname} {record.artistLastname}
            </p>
        ),
    },
    {
        key: 'publishDate',
        title: 'Publish Date',
        dataIndex: 'publishDate',
        align: 'center',
        render: (text) => 
            <p className='text-center'>
                {
                    text === null
                    ? Constants.stringHyphen
                    : new Date(text).toLocaleString("th-TH")
                }
            </p>,
    },
    {
        key: 'assetStatus',
        title: 'Status',
        dataIndex: 'assetStatus',
        align: 'center',
        render: (value, record, index) =>
            record.assetStatus === AssetStatus.DRAFT ? (
                <Badge status='default' text={record.assetStatus} />
            ) : record.assetStatus === AssetStatus.PENDING ? (
                <Badge status='processing' text={record.assetStatus} />
            ) : record.assetStatus === AssetStatus.COMPLETED ? (
                <Badge status='success' text={record.assetStatus} />
            ) : (
                <Badge status='error' text={record.assetStatus} />
            ),
    },
    {
        key: 'action',
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
        render: (text) => <Link key={text} to={text}>Detail</Link>,
    },
];

export default function Assets() {
    const [form] = Form.useForm();
    const [assets, setAssets] = useState<AssetListModel[]>([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRow, setTotalRow] = useState(0);

    const onFinish = () => {
        onGetListAssets(page, size);
    };

    useEffect(() => {
        onGetListAssets(page, size);
    }, []);

    const onReset = () => {
        onGetListAssets(1, 10);
        form.resetFields();
        setPage(1);
        setSize(10);
    };

    const onChangePage = (page: number, size: number) => {
        setPage(page);
        setSize(size);
        onGetListAssets(page, size);
    };

    const onGetListAssets = async (page: number, size: number) => {
        const pDate = form.getFieldValue('publishDate');

        const request: AssetListSearchModel = {
            assetCode: form.getFieldValue('assetCode'),
            assetName: form.getFieldValue('assetName'),
            email: form.getFieldValue('email'),
            artistName: form.getFieldValue('artistName'),
            assetType: form.getFieldValue('assetType') !== '' ? form.getFieldValue('assetType') : undefined,
            assetStatus: form.getFieldValue('assetStatus') ?? '',
            startPublishDate: pDate && pDate[0] ? new Date(pDate[0]) : undefined,
            endPublishDate: pDate && pDate[1] ? new Date(pDate[1]) : undefined,
        };

        request.startPublishDate?.setHours(0, 0, 0);
        request.endPublishDate?.setHours(23, 59, 59);

        const { data, status } = await new AssetService().getList(page, size, request);

        if (status !== HttpStatus.OK) {
            setTotalRow(0);
            setAssets([]);

            return;
        }

        setTotalRow(data.paging.totalRows);

        const dataTable = data.list.map((asset: AssetListModel) => ({
            ...asset,
            action: `${RouteNames.assetDetail}?id=${asset.id}`,
            key: asset.id,
        }));

        setAssets(dataTable);
    };

    return (
        <>
            <Card title='Asset' bordered={false}>
                <Card className='card-form'>
                    <Form form={form} onFinish={onFinish} layout='vertical' autoComplete='off'>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <Form.Item
                                    name='assetCode'
                                    className=' text-light w-100'
                                    label='Asset Code'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Asset Code' />
                                </Form.Item>
                            </Col>
                            <Col span={3} />
                            <Col span={6}>
                            <Form.Item
                                    name='assetName'
                                    className=' text-light w-100'
                                    label='Asset Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Asset Name' />
                                </Form.Item>
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <Form.Item
                                    name='email'
                                    className=' text-light w-100'
                                    label='Email'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Email'/>
                                </Form.Item>
                            </Col>
                            <Col span={3} />
                            <Col span={6}>
                                <Form.Item
                                    name='artistName'
                                    className=' text-light w-100'
                                    label='Artist Name'
                                    rules={[{ required: false }]}
                                >
                                    <Input placeholder='Artist Name' />
                                </Form.Item>
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={6}>
                                <Form.Item
                                    className=' text-light w-100'
                                    label='Publish Date'
                                    rules={[{ required: false }]}
                                >
                                    <InputRangePicker Iname='publishDate' />
                                </Form.Item>
                            </Col>
                            <Col span={3} />
                            <Col span={6}>
                                <Form.Item
                                    name='assetType'
                                    className=' text-light w-100'
                                    label='Type'
                                    rules={[{ required: false }]}
                                    initialValue={''}
                                >
                                    <Radio.Group
                                        options={assetTypeOptions}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row justify='center'>
                            <Col span={3} />
                            <Col span={21}>
                                <Form.Item
                                    name='assetStatus'
                                    className=' text-light w-100'
                                    label='Status'
                                    rules={[{ required: false }]}
                                    initialValue={''}
                                >
                                    <Radio.Group
                                        options={assetStatusOptions}
                                        optionType='button'
                                        buttonStyle='solid'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={24} className='text-right'>
                                <Space>
                                    <Button type='primary' className='px-5' htmlType='submit'>
                                        Search
                                    </Button>
                                    <Button type='default' className='px-5' onClick={() => onReset()}>
                                        Clear
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Col span={24}>
                    <Table className='mt-4' columns={columns} dataSource={assets} />
                </Col>
                <Col span={24} className='text-right pt-3'>
                    <Pagination
                        defaultCurrent={1}
                        current={page}
                        total={totalRow}
                        pageSize={size}
                        onChange={onChangePage}
                    />
                </Col>
            </Card>
        </>
    );
}
